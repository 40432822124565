import {
  MouseEvent as MouseEventReact, useEffect,
} from 'react';
import {
  TableCell,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { ICellData, IColumnData } from '../../interfaces/INavigableTableObjects';
import betweenNumbers from '../../helpers/betweenNumbers';

interface Props {
  typeOfCell: string,
  dataCell: ICellData,
  row: number,
  mouseDown: boolean | null;
  columns: IColumnData[];
  firstCell: number[];
  lastCell: number[];
  typeOfCellToUpdate: string;
  newValue: string[];
  updateLastCell: (last: number[]) => void;
  updateFirstCell: (first: number[]) => void;
  changeCellValue: (cellType: string, value: string) => void;
  setValue: (newValue: unknown, row: number, cell: number) => void;
  keyPressEvent: (e: KeyboardEvent) => [number | null, number | null];
}
function CellTableComponent(props: Props) {
  const {
    typeOfCell, row, columns, mouseDown, newValue, dataCell,
    firstCell, lastCell, typeOfCellToUpdate, setValue, keyPressEvent,
    updateLastCell, updateFirstCell, changeCellValue,
  } = props;
  const { isDisable } = dataCell;
  const col = dataCell.index;
  const { value } = dataCell;
  const isHighlighted = betweenNumbers(row, firstCell[0], lastCell[0])
    && betweenNumbers(col, firstCell[1], lastCell[1]) && !isDisable;
  const newValuesLength = newValue.length;
  // Functions to edit cell
  function onChangeEditText(valueUpdated: string | undefined) {
    if (columns[col].editable) {
      const textAreaField = document.getElementById(
        `input-${row}-${col}`,
      ) as HTMLInputElement;
      if (textAreaField !== null) {
        textAreaField.value = valueUpdated as string;
      }
      setValue(valueUpdated, Number(row), Number(col));
    }
  }
  function onChangeEditNumber(valueUpdated: string | undefined) {
    if (columns[col].editable) {
      if (valueUpdated === '') {
        setValue('', Number(row), Number(col));
      } else {
        setValue(Number(valueUpdated?.replaceAll('.', '')), Number(row), Number(col));
      }
    }
  }
  function onChangeEditDate(valueUpdated: string | undefined) {
    if (columns[col].editable) {
      let valueToShow = valueUpdated;
      const format = /^(\d{0,2}([-/])?\d{0,2}([-/])?\d{0,4})?$/;
      if (format.test(valueToShow as string)) {
        if (valueToShow?.length === 10) {
          let arrayDate = valueToShow?.split('-');
          if (arrayDate.length === 1) {
            arrayDate = valueToShow?.split('/');
          }
          const date = new Date(Number(arrayDate[2]), Number(arrayDate[1]) - 1, Number(arrayDate[0]));
          const today = new Date();
          if (today >= date) {
            valueToShow = '';
          }
        }
        setValue(valueToShow, Number(row), Number(col));
      }
    }
  }
  // function to select many cells when mouse right pressed
  const SelectMousePressed = () => {
    if (mouseDown && !isDisable) {
      updateLastCell([row, col]);
    }
  };
  // function to focus in selected input
  function focusOnInput(inputRow: number, inputCol: number) {
    if (columns[inputCol].editable) {
      // find input
      const inputField = document.querySelector(
        `input[name=input-${inputRow}-${inputCol}]`,
      ) as HTMLInputElement;
      const textAreaField = document.querySelector(
        `textarea[name=input-${inputRow}-${inputCol}]`,
      ) as HTMLInputElement;
      // focus on input
      if (inputField !== null) {
        inputField.focus();
      }
      if (textAreaField !== null) {
        textAreaField.focus();
      }
    }
  }
  // function to select cell when mouse clicked + keyboard down
  function ClickSelectCell(event: MouseEventReact<HTMLTableCellElement, MouseEvent>) {
    if (event.shiftKey) {
      updateLastCell([row, col]);
    } else {
      // highlight one cell
      focusOnInput(row, col);
      updateFirstCell([row, col]);
      updateLastCell([row, col]);
    }
  }
  useEffect(() => {
    // Nav with arrows
    const changeSelectedCells = (e: KeyboardEvent) => {
      // Catch only on highlighted cell
      if (isHighlighted && `${row}-${col}` === `${lastCell[0]}-${lastCell[1]}`) {
        const [rowToHighlight, colToHighlight] = keyPressEvent(e);
        if (rowToHighlight !== null && colToHighlight !== null) {
          if (!e.shiftKey) {
            focusOnInput(rowToHighlight, colToHighlight);
          }
        }
      }
    };
    document.addEventListener('keydown', changeSelectedCells);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', changeSelectedCells);
    };
  }, [lastCell]);
  useEffect(() => {
    if (isHighlighted && newValuesLength === 1) {
      // change value of one cell
      switch (columns[col].type) {
        case 'number':
          onChangeEditNumber(newValue[0]);
          break;
        case 'date':
          onChangeEditDate(newValue[0]);
          break;
        case 'text':
          onChangeEditText(newValue[0]);
          break;
        default:
          break;
      }
    } else if (newValue.length > 0 && columns[col].type === typeOfCellToUpdate
      && firstCell[0] <= row && firstCell[1] === col && firstCell[0] <= lastCell[0]
      && newValuesLength > 1) {
      // copy-paste many selecting one cell
      switch (typeOfCellToUpdate) {
        case 'number':
          onChangeEditNumber(newValue.shift());
          break;
        case 'date':
          onChangeEditDate(newValue.shift());
          break;
        case 'text':
          onChangeEditText(newValue.shift());
          break;
        default:
          break;
      }
    } else if (newValue.length > 0 && columns[col].type === typeOfCellToUpdate
      && lastCell[0] <= row && firstCell[1] === col && lastCell[0] <= firstCell[0]
      && newValuesLength > 1) {
      // copy-paste many selecting one cell
      switch (typeOfCellToUpdate) {
        case 'number':
          onChangeEditNumber(newValue.shift());
          break;
        case 'date':
          onChangeEditDate(newValue.shift());
          break;
        case 'text':
          onChangeEditText(newValue.shift());
          break;
        default:
          break;
      }
    }
  }, [typeOfCellToUpdate, newValue]);
  switch (typeOfCell) {
    case 'text':
      return (
        <TableCell
          sx={{
            fontFamily: 'Lato',
            width: '70%',
            padding: 0,
          }}
          component="th"
          scope="row"
          onMouseDown={(e) => ClickSelectCell(e)}
          onMouseMove={() => SelectMousePressed()}
        >
          <textarea
            name={`input-${row}-${col}`}
            id={`input-${row}-${col}`}
            style={isHighlighted ? { backgroundColor: 'rgba(224, 224, 224, 0.5)' } : {}}
            className={`CellTextArea ${isHighlighted ? 'TableTextBackgroundColor' : ''}`}
            onChange={(e) => changeCellValue('text', e.target.value)}
            autoComplete="off"
            readOnly={!columns[col].editable}
          />
        </TableCell>
      );
    case 'number':
      return (
        <TableCell
          sx={{
            fontFamily: 'Lato',
            height: '0px',
            padding: '0px',
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          }}
          onMouseDown={(e) => ClickSelectCell(e)}
          onMouseMove={() => SelectMousePressed()}
        >
          <NumericFormat
            name={`input-${row}-${col}`}
            className={`InputProductText ${isHighlighted ? 'TableNumberBackgroundColor' : ''}`}
            value={(value as number)}
            allowNegative={false}
            decimalSeparator=","
            thousandSeparator="."
            autoComplete="off"
            readOnly={!columns[col].editable}
            onValueChange={(values) => changeCellValue('number', values.value)}
          />
        </TableCell>
      );
    case 'date':
      return (
        <TableCell
          sx={{ fontFamily: 'Lato', height: '0px', padding: '0px' }}
          onMouseDown={(e) => ClickSelectCell(e)}
          onMouseMove={() => SelectMousePressed()}
        >
          <input
            pattern="\d{2}-\d{2}-\d{4}"
            formNoValidate={false}
            className={`TableInputDatepicker
            ${isDisable ? 'NotAllowed' : ''} ${isHighlighted ? 'TableDateBackgroundColor' : ''}`}
            value={!isDisable ? value as string : ''}
            maxLength={10}
            placeholder={!isDisable ? 'dd-mm-aaaa' : ''}
            disabled={isDisable}
            onChange={(e) => changeCellValue('date', e.target.value)}
            name={`input-${row}-${col}`}
            autoComplete="off"
          />
        </TableCell>
      );
    default:
      return null;
  }
}

export default CellTableComponent;
