export const getDeliveryRates = (limit: number | null, offset: number) => `
query getDeliveryRates($where: DeliveryRates_bool_exp!) {
  DeliveryRates(limit: ${limit}, offset: ${offset}, where: $where) {
      id
      price
      activateAt
      daysDelay
      title
      subtitle
      LocalId
      CommuneId
      CourierId
      shippingType
      PointOfSaleId
      checkStock
      active
      scheduled
      Commune {
        id
        name
      }
      Courier {
        id
        name
      }
      Local {
        id
        name
      }
    }
    DeliveryRates_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
