/* eslint-disable no-underscore-dangle */
export const getOrdersVariables = ({
  filter,
  sort,
}: {
  filter?: { [key: string]: string | number | string[] | number[] | boolean | { [key: string]: string | string[] } };
  sort: { [key: string]: string }[];
}) => {
  const {
    id, ClientId, code, PointOfSaleId, OrderTypeId, startDate, endDate,
  } = filter || {};
  const variables: { where: { _and: object[]; _or?: object[]; }; sorting: { [key: string]: string }[]; } = {
    where: {
      _and: [
        {
          id: id ? {
            _eq: id,
          } : {},
        },
        {
          ClientId: ClientId ? {
            _eq: ClientId,
          } : {},
        },
        {
          PointOfSaleId: PointOfSaleId ? {
            _eq: PointOfSaleId,
          } : {},
        },
        {
          code: code ? {
            _ilike: `%${code}%`,
          } : {},
        },
        {
          OrderTypeId: OrderTypeId ? {
            _eq: OrderTypeId,
          } : {},
        },
        {
          createdAt: startDate ? {
            _gte: startDate,
            _lte: endDate,
          } : {},
        },
      ],
    },
    sorting: sort,
  };
  return variables;
};
