import {
  Authenticator,
  View,
  translations,
} from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify';
import Header from '../components/Login/Header';
import LoginFooter from '../components/Login/LoginFooter';
import LoginHeader from '../components/Login/LoginHeader';
import ResetPasswordHeader from '../components/Login/ResetPasswordHeader';

I18n.putVocabularies(translations);
I18n.setLanguage('es');
I18n.putVocabularies({
  es: {
    'Enter your Username': 'Usuario',
    'Enter your Password': 'Contraseña',
  },
});

const components = {
  Header,
  SignIn: {
    Header: LoginHeader,
    Footer: LoginFooter,
  },
  ResetPassword: {
    Header: ResetPasswordHeader,
  },
};

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      isRequired: true,
    },
    password: {
      labelHidden: false,
      isRequired: true,
    },
  },
};

export default function Login() {
  return (
    <View
      justifyContent="center"
      minWidth="100%"
      marginTop="15vh"
    >
      <Authenticator
        formFields={formFields}
        components={components}
      />
    </View>
  );
}
