import { useContext, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useTranslate } from '../../hooks';
import SelectInput from '../../components/HomeComponents/SelectInputName';
import ILocal from '../../interfaces/ILocal';
import { GlobalContext } from '../../store';
import joinLocalsFilters from '../../helpers/joinLocalsFilters';
import { SelectCheckboxInput } from '../../components/HomeComponents/SelectCheckboxInput';

export default function Lots() {
  const translate = useTranslate('Page.Lots');
  const { user } = useContext(GlobalContext);
  const { shutdown } = useIntercom();
  const [selectedLocals, setSelectedLocals] = useState<ILocal[]>([]);
  const [selectedSku, setSelectedSku] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [allLocals, setAllLocals] = useState<ILocal[]>([]);
  const [products, setProducts] = useState<{ name: string }[]>([]);
  const {
    api,
  } = useContext(GlobalContext);
  const iframeStyle = {
    border: 'none',
    borderStyle: 'none',
    width: '80vw',
    height: '94vh',
  };
  useEffect(() => {
    shutdown();
  }, []);
  useEffect(() => {
    const clientFilter = user.connectableId.toString();
    const localFilterTitle = 'Local';
    const joinedString = joinLocalsFilters(selectedLocals.map((el) => el.id), localFilterTitle);
    setUrl('https://mb.getnomad.cl/public/question/97c9ff22-180f-41cc-81d7-c7890f7df7bb?Cliente='
    + `${clientFilter}&${joinedString}`
    + `&SKU=${encodeURIComponent(selectedSku)}`
    + '#hide_parameters=Cliente,Local,SKU');
  }, [selectedLocals, selectedSku]);
  useEffect(() => {
    (async () => {
      const { locals } = await api.getLocals();
      setAllLocals(locals);
      const response = await api.getClientProducts({});
      setProducts(response.products.map((p) => ({ name: p.rawSku } as { name: string })));
    })();
  }, []);
  return (
    <div className="PageContainer ReportFilterDisplay">
      <div
        className="ReportFilterContainer"
      >
        <div
          className="FilterContainer"
        >
          <SelectCheckboxInput
            data={allLocals}
            inputTitle={translate('locals')}
            keyValue="id"
            checkedValues={selectedLocals}
            displayValue="name"
            setChecked={setSelectedLocals}
          />
          <SelectInput
            styleWidth="180px"
            title={translate('selectSkuTitle')}
            array={products}
            selectedOption={selectedSku}
            SetSelectedOption={setSelectedSku}
            lightStyle
          />
        </div>
      </div>
      <iframe
        title={translate('title')}
        src={url}
        style={iframeStyle}
      />
    </div>
  );
}
