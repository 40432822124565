import { useEffect, useState } from 'react';
import { NotionRenderer } from 'react-notion';

import 'react-notion/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css';

export default function Home() {
  const [notionData, setData] = useState({});

  useEffect(() => {
    fetch(
      'https://notion-api.splitbee.io/v1/page/c89259a9aa8b43578d458597b920f10a',
    )
      .then((res) => res.json())
      .then((data) => setData(data as object))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="PageContainer">
      <div className="NotionRenderContainer">
        <div className="NotionRender">
          <NotionRenderer blockMap={notionData} />
        </div>
      </div>
    </div>
  );
}
