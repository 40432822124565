export const getClients = (limit: number | null, offset: number | null) => `
  query getClients(
    $where: Clients_bool_exp!,
    $sorting: [Clients_order_by!]
  ) {
    Clients(limit: ${limit}, offset: ${offset}, where: $where, order_by: $sorting) {
      id
      name
      email
      pickupWithdrawalWindow
      pickupNotificationWindow
      makesDocument
      usesWhatsapp
    }
    Clients_aggregate(where: $where) {
      aggregate {
        total: count
      }
    }
  }
`;
