import axios, { AxiosError, AxiosInstance } from 'axios';
import IEnqueueTaskBody from '../interfaces/IEnqueueTaskBody';
import IGetTasksStatus from '../interfaces/IGetTasksStatus';
import { IPaddleTransaction } from '../interfaces/IPaddleTransaction';

export class ApiFunctions {
  axios: AxiosInstance;

  constructor(token: string) {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_NOMAD_FUNCTIONS_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async postEnqueueTask(
    body: IEnqueueTaskBody,
  ): Promise<string | null> {
    try {
      const response = await this.axios.post('/enqueue-task', body);
      return response.data.data.task.id;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Error enviando solicitud:${error.response?.data}`);
      }
      return null;
    }
  }

  async getTasksStatus(
    taskId: string,
  ): Promise<IGetTasksStatus> {
    const response = await this.axios.get('/tasks', {
      params: {
        taskId,
      },
    });
    return response.data;
  }

  async fetchTransaction(transactionId: string): Promise<IPaddleTransaction> {
    try {
      const response = await this.axios.post('/get-paddle-transaction', {
        transactionId,
      });
      const { data }: { data: IPaddleTransaction } = response.data;
      const todayTotalData: IPaddleTransaction['details']['totals'] = data.details.totals;
      Object.keys(todayTotalData).forEach((key: string) => {
        const value: string = todayTotalData[key as keyof typeof todayTotalData];
        if (value !== '0' && value !== null) {
          todayTotalData[key as keyof typeof todayTotalData] = `${value.slice(0, -2)}.${value.slice(-2)}`;
        }
      });
      const itemsData = data.details.line_items;
      itemsData.forEach((item) => {
        const value = item.totals.subtotal;
        item.totals.subtotal = value !== '0' && value !== null ? `${value.slice(0, -2)}.${value.slice(-2)}` : '0';
      });
      const currencyCodeData = data.currency_code;
      return {
        ...data,
        details: {
          ...data.details,
          totals: todayTotalData,
          line_items: itemsData,
        },
        currency_code: currencyCodeData,
      };
    } catch (error) {
      throw new Error('Error fetching transaction');
    }
  }
}
