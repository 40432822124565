import { useState } from 'react';
import capitalizeFirstLetter from '../../helpers/capitalizeFirstLetter';
import { useTranslate } from '../../hooks';

interface Props<T extends object> {
  data: T[];
  inputTitle: string;
  keyValue: keyof T;
  checkedValues: T[];
  displayValue: keyof T;
  setChecked: (newSelection: T[]) => void;
}

export function SelectCheckboxInput<T extends object>(props: Props<T>) {
  const {
    data,
    inputTitle,
    keyValue,
    checkedValues,
    displayValue,
    setChecked,
  } = props;
  const translate = useTranslate('Component.SelectCheckboxInput');
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (option: T) => {
    const elementIsChecked = checkedValues.find((el) => el === option);
    let newCheckedValues = [];
    if (elementIsChecked) {
      newCheckedValues = checkedValues.filter((el) => el !== option);
    } else {
      newCheckedValues = [...checkedValues, option];
    }
    setIsOpen(false);
    setChecked(newCheckedValues);
  };

  return (
    <div className={`customSelect ${isOpen ? '' : 'closed'}`}>
      <div
        className="customSelectSelectedValues"
        onClick={() => setIsOpen(!isOpen)}
      >
        {checkedValues.length === 0 && (
          <span className="customSelectPlaceholder">{inputTitle}</span>
        )}
        {(checkedValues.length === 1) && (
          <>
            {capitalizeFirstLetter(checkedValues[0][displayValue] as string)}
            <div
              className="clearAllButton"
              onClick={() => setChecked([])}
            >
              ✕
            </div>
          </>
        )}
        {checkedValues.length > 1 && (
          <>
            {translate('totalLocals', {
              total: checkedValues.length,
              inputTitle,
            })}
            <div
              className="clearAllButton"
              onClick={() => setChecked([])}
            >
              ✕
            </div>
          </>
        )}
      </div>
      {isOpen && (
        <div className="customSelectOptions">
          {data.map((option) => (
            <div
              key={option[keyValue] as string}
              className="customSelectOption"
              onClick={() => handleChange(option)}
            >
              <input
                type="checkbox"
                className="Checkbox"
                key={option[keyValue] as string}
                checked={checkedValues.some((el) => el === option)}
                onChange={() => handleChange(option)}
              />
              {capitalizeFirstLetter(option[displayValue] as string)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
