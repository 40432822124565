import { useContext } from 'react';
import { GlobalContext } from '../store';
import { useTranslate } from '../hooks';

import '../css/Unauthorized.css';

interface Props {
  showSignout: boolean;
}

export default function Unauthorized(props: Props) {
  const { context } = useContext(GlobalContext);
  const { logOut } = context;
  const { showSignout } = props;

  const translate = useTranslate('Page.Unauthorized');

  return (
    <div className="UnauthorizedPage">
      <h2>
        {translate('title')}
      </h2>
      <p>
        {translate('subtitle1')}
      </p>
      <p>
        {translate('subtitle2')}
      </p>
      {showSignout && (
        <div
          className="Button"
          onClick={() => logOut()}
        >
          {translate('signOut')}
        </div>
      )}
    </div>
  );
}
