import { StateInterface, ActionInterface } from '../interfaces';
import IDeliveryRate from '../interfaces/IDeliveryRate';
import IProduct from '../interfaces/IProduct';

export const clientDefaultState: StateInterface = {
  filteredItemsToEdit: [],
  loading: 0,
};

export const reducer = (state: StateInterface, action: ActionInterface): StateInterface => {
  switch (action.type) {
    case 'START_LOADING':
      return {
        ...state,
        loading: state.loading + 1,
      };
    case 'FINISH_LOADING':
      return {
        ...state,
        loading: (state.loading - 1 <= 0) ? 0 : state.loading - 1,
      };
    case 'LOG_OUT':
      return clientDefaultState;
    case 'SET_FILTERED_ITEMS_TO_EDIT':
      return {
        ...state,
        filteredItemsToEdit: action.data?.filteredItemsToEdit as IProduct[] | IDeliveryRate[],
      };
    default:
      return state;
  }
};
