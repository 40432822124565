import Tooltip from '@mui/material/Tooltip';

interface Props {
  text: string
  color: string
}
function ToolTip(props: Props) {
  const { text, color } = props;

  return (
    <Tooltip title={text}>
      <button
        type="button"
        className={`MoreInfo ${color === 'light' ? 'MoreInfoColorWhite' : 'MoreInfoColorOrange'}`}
      >
        ?
      </button>
    </Tooltip>
  );
}

export default ToolTip;
