import NotionPage from '../components/NotionPageComponent';
import { useTranslate } from '../hooks';

export default function BillingAndPayments() {
  const translate = useTranslate('Page.BillingAndPayments');

  const data = [
    {
      id: '134c5029d7004f30a7ce414374955ac0',
      title: translate('bankAccount'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/Banco-de-Chile.png',
    },
    {
      id: '98a1847e2cea4d1db5a1ad3280d96e0b',
      title: translate('billingData'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/facturas.jpg',
    }];
  return (
    <NotionPage pageData={data} />
  );
}
