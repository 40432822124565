import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import textInputToNumber from '../../helpers/textInputToNumber';
import IProduct from '../../interfaces/IProduct';
import { useTranslate } from '../../hooks';

interface ProductsTableProps {
  rows: IProduct[];
  onChangeRequest: (rowsPerPage: number, page: number) => void;
  initialPageSze: number;
  initialPage: number;
  totalRowCount: number;
  showModal: (value: boolean) => void;
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  loadingTable: boolean;
}

export default function ProductTable({
  rows,
  onChangeRequest,
  initialPageSze,
  initialPage,
  totalRowCount,
  showModal,
  selected,
  setSelected,
  loadingTable,
}: ProductsTableProps) {
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialPageSze);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const translate = useTranslate('Component.ProductTable');

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(!selectAll);
    if (event.target.checked && selected.length === 0) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    } if (event.target.checked && selected.length > 0) {
      let newSelected = [...selected];
      newSelected = newSelected.concat(rows.map((n) => n.id));
      setSelected(newSelected);
    } if (!event.target.checked && selected.length > 0) {
      setSelected([]);
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setSelectAll(false);
    setPage(newPage);
    onChangeRequest(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSelected([]);
    onChangeRequest(newRowsPerPage, 0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  useEffect(() => {
    if (selected.length > 0) {
      showModal(true);
    } else {
      showModal(false);
    }
  }, [selected, page]);

  return (
    <Paper sx={{ width: '90%' }}>
      <TableContainer sx={{ minHeight: 620, maxHeight: 620 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px', textAlign: 'center' }}>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('sku')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('name')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('barcode')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('isPack')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('isWeighted')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('familySku')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('price')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('safetyStock')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('storageType')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('ageVerification')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('lotTracking')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('expirationAlert')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('size')}
              </TableCell>
            </TableRow>
          </TableHead>
          {rows.length === 0 ? (
            <TableBody>
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
              >
                <TableCell
                  sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                  colSpan={7}
                >
                  {translate('noProducts')}
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {loadingTable ? (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell
                    sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                    colSpan={8}
                  >
                    <LoadingOutlined />
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((product: IProduct) => {
                  const isItemSelected = isSelected(product.id);
                  return (
                    <TableRow
                      sx={{ cursor: 'pointer' }}
                      hover
                      tabIndex={-1}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      key={product.id}
                      selected={isItemSelected}
                    >
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px', textAlign: 'center' }}>
                        <input
                          type="checkbox"
                          onClick={(event) => handleClick(event, product.id)}
                          color="primary"
                          checked={isItemSelected}
                        />
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>{product.rawSku}</TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>{product.name}</TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>{product.barcode}</TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {product.isPack ? translate('yes') : translate('no')}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {product.isWeighted ? translate('yes') : translate('no')}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>{product.familySku}</TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {textInputToNumber((product.pvp || 0).toString(), false)}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {textInputToNumber((product.safetyStock || 0).toString(), false)}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>{product.storageType}</TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {product.ageVerification ? translate('yes') : translate('no')}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {product.needsLotTracking ? translate('yes') : translate('no')}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {textInputToNumber((product.expirationAlert || 0).toString(), false)}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>{product.Size?.name}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100, 500, { value: -1, label: translate('all') }]}
        component="div"
        count={totalRowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={translate('perPage')}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
