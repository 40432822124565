import React, { useEffect, useState } from 'react';
import {
  Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText,
  ListItem, ListItemText,
} from '@mui/material';
import { IRowData } from '../../interfaces/INavigableTableObjects';
import IDeliveryRate from '../../interfaces/IDeliveryRate';
import IProduct from '../../interfaces/IProduct';
import { useTranslate } from '../../hooks';

interface Props {
  data: IRowData[] | IDeliveryRate[] | IProduct[];
  type: string;
  description: string;
  setConfirmData: React.Dispatch<React.SetStateAction<string>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ConfirmDialog(props: Props) {
  const {
    data,
    setConfirmData,
    setOpen,
    open,
    type,
    description,
  } = props;
  const [disabledConfirmBtn, setDisabledConfirmBtn] = useState(false);

  const translate = useTranslate('Component.ConfirmDialog');

  const handleClose = () => {
    setConfirmData('cancel');
    setOpen(false);
  };

  const handleConfirmSendForm = () => {
    setConfirmData('true');
    setDisabledConfirmBtn(true);
  };

  useEffect(() => {
    if (!open) {
      setDisabledConfirmBtn(false);
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate('title')}
        </DialogTitle>
        <DialogContent className="ConfirmProductDialogContainer">
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
          {type === 'rowdata'
            && (data as IRowData[]).map((product: IRowData) => (
              <ListItem
                key={`${product.row[0].value}${product.row[2].value}`}
                component="div"
                disablePadding
              >
                <ListItemText
                  primary={translate('reception', {
                    quantity: product.row[1].value,
                    sku: product.row[0].value,
                  })}
                />
              </ListItem>
            ))}
          {type === 'rates'
            && (data as IDeliveryRate[]).map((rate: IDeliveryRate) => (
              <ListItem
                key={rate.id}
                component="div"
                disablePadding
              >
                <ListItemText
                  primary={translate('rate', {
                    price: rate.price,
                    activateAt: rate.activateAt,
                    shippingType: rate.shippingType,
                    commune: rate.Commune?.name,
                    local: rate.Local?.name,
                  })}
                />
              </ListItem>
            ))}
          {type === 'products'
            && (data as IProduct[]).map((product: IProduct) => (
              <ListItem
                key={product.id}
                component="div"
                disablePadding
              >
                <ListItemText
                  primary={translate('product', { sku: product.rawSku, name: product.name })}
                />
              </ListItem>
            ))}
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={handleClose}
            autoFocus
          >
            {translate('cancel')}
          </Button>
          <Button
            color="inherit"
            disabled={disabledConfirmBtn}
            onClick={handleConfirmSendForm}
            autoFocus
          >
            {translate('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
