import { useState, useContext, useEffect } from 'react';
import { Divider } from '@mui/material';
import RightArrow from '@ant-design/icons/RightOutlined';
import { useTranslate } from '../../../hooks';
import { GlobalContext } from '../../../store';
import LoadingPage from '../../../components/LoadingPageComponent';
import Unauthorized from '../../Unauthorized';
import AlertDialog from '../../../components/HomeComponents/AlertDialog';
import Products from './Products';
import Data from './Data';
import { IRowData } from '../../../interfaces/INavigableTableObjects';
import { IOrder } from '../../../interfaces/IOrder';
import Address from './Address';
import Rates from './Rates';

function RenderPage({
  activePage, goNextPage, orderDetails, setOrderDetails, goPreviousPage, order, setOrder,
  setOpenAlertDialog, setModalAlertTitle, setModalAlertText, rateId, setRateId,
}: {
  activePage: string;
  goNextPage: () => void;
  orderDetails: IRowData[];
  setOrderDetails: React.Dispatch<React.SetStateAction<IRowData[]>>;
  goPreviousPage: () => void;
  order: Partial<IOrder>;
  setOrder: React.Dispatch<React.SetStateAction<Partial<IOrder>>>;
  setOpenAlertDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setModalAlertTitle: React.Dispatch<React.SetStateAction<string>>;
  setModalAlertText: React.Dispatch<React.SetStateAction<string>>;
  rateId: number | null;
  setRateId: React.Dispatch<React.SetStateAction<number | null>>;
}) {
  switch (activePage) {
    case 'data':
      return (
        <Data
          goNextPage={goNextPage}
          order={order}
          setOrder={setOrder}
        />
      );
    case 'products':
      return (
        <Products
          goNextPage={goNextPage}
          goPreviousPage={goPreviousPage}
          order={order}
          orderDetails={orderDetails}
          setOrderDetails={setOrderDetails}
        />
      );
    case 'address':
      return (
        <Address
          goNextPage={goNextPage}
          goPreviousPage={goPreviousPage}
          order={order}
          setOrder={setOrder}
        />
      );
    case 'rates':
      return (
        <Rates
          goPreviousPage={goPreviousPage}
          order={order}
          orderDetails={orderDetails}
          setOrder={setOrder}
          setRateId={setRateId}
          rateId={rateId}
          setOpenAlertDialog={setOpenAlertDialog}
          setModalAlertTitle={setModalAlertTitle}
          setModalAlertText={setModalAlertText}
        />
      );
    default:
      return null;
  }
}

export default function WithdrawalCreate() {
  const translate = useTranslate('Page.Withdrawals');
  const { api, user, client } = useContext(GlobalContext);
  const [activePage, setActivePage] = useState<'address' | 'rates' | 'products' | 'data'>('data');
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [posId, setPosId] = useState<number | null>(null);
  const [orderDetails, setOrderDetails] = useState<IRowData[]>([]);
  const [ordersThisMonth, setOrdersThisMonth] = useState<number>(0);
  const [rateId, setRateId] = useState<number | null>(0);
  const now = new Date();
  const [order, setOrder] = useState<Partial<IOrder>>({
    ClientId: user.connectableId,
    PointOfSaleId: null,
    OrderDetails: [],
    customerName: '',
    customerPhone: '',
    date: (new Date()).toISOString(),
    note: '',
    shippingType: '',
    shippingCost: 0,
    code: 'WO-',
    platformCode: 'WO-',
    pat: now.toISOString(),
    shippingAddress: { address1: '', address2: '', commune: '' },
  });

  function goNextPage() {
    switch (activePage) {
      case 'data':
        setActivePage('products');
        break;
      case 'products':
        setActivePage('address');
        break;
      case 'address':
        setActivePage('rates');
        break;
      default:
        break;
    }
  }

  function goPreviousPage() {
    switch (activePage) {
      case 'products':
        setActivePage('data');
        break;
      case 'address':
        setActivePage('products');
        break;
      case 'rates':
        setActivePage('address');
        break;
      default:
        break;
    }
  }

  function goToPage(page: string) {
    switch (page) {
      case 'products':
        setActivePage('products');
        break;
      case 'data':
        if (activePage === 'products') {
          setActivePage('data');
        }
        break;
      case 'address':
        if (activePage === 'products') {
          setActivePage('address');
        }
        break;
      case 'rates':
        if (activePage === 'address') {
          setActivePage('rates');
        }
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const posIntegration = await api.getClientPOSIntegration(user.connectableId, 'clients-platform');
      const orderTypes = await api.getOrderTypes({ filter: { name: ['WO'] } });
      const getOrders = await api.getOrders({
        filter: {
          startDate: new Date(now.getFullYear(), now.getMonth(), 1).toISOString(),
          endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString(),
          ClientId: user.connectableId,
          OrderTypeId: orderTypes[0].id,
        },
      });
      setOrdersThisMonth(getOrders.count);
      order.OrderTypeId = orderTypes[0].id;
      if (posIntegration) {
        order.PointOfSaleId = posIntegration.id;
        setPosId(posIntegration.id);
        setOrder(order);
      } else {
        setLoading(false);
        setOpenAlertDialog(true);
        setModalAlertTitle(translate('modalErrorTitle'));
        setModalAlertText(`${translate('modalIntegrationErrorDescription')}`);
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return (
      <LoadingPage full />
    );
  }

  if (posId === null && !loading) {
    return (
      <div className="PageContainer">
        <Unauthorized
          showSignout={false}
        />
      </div>
    );
  }

  return (
    <div className="OrderPageContainer">
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openAlertDialog}
        setOpen={setOpenAlertDialog}
      />
      <div className="PageTitleContainer">
        <h4>{translate('mainTitle')}</h4>
        <Divider />
      </div>
      {client.withdrawalLimit > ordersThisMonth ? (
        <>
          <div className="StepsContainer">
            <p
              onClick={() => goToPage('data')}
              className={`Cursor ${activePage === 'data' ? 'ActiveStep' : ''}`}
            >
              {translate('data')}
            </p>
            <RightArrow className="RightArrow" />
            <p
              onClick={() => goToPage('products')}
              className={`Cursor ${activePage === 'products' ? 'ActiveStep' : ''}`}
            >
              {translate('product')}
            </p>
            <RightArrow className="RightArrow" />
            <p
              onClick={() => goToPage('address')}
              className={`Cursor ${activePage === 'address' ? 'ActiveStep' : ''}`}
            >
              {translate('address')}
            </p>
            <RightArrow className="RightArrow" />
            <p
              onClick={() => goToPage('rates')}
              className={`Cursor ${activePage === 'rates' ? 'ActiveStep' : ''}`}
            >
              {translate('rate')}
            </p>
          </div>
          <RenderPage
            activePage={activePage}
            goNextPage={goNextPage}
            orderDetails={orderDetails}
            setOrderDetails={setOrderDetails}
            goPreviousPage={goPreviousPage}
            order={order}
            setOrder={setOrder}
            setOpenAlertDialog={setOpenAlertDialog}
            setModalAlertTitle={setModalAlertTitle}
            setModalAlertText={setModalAlertText}
            setRateId={setRateId}
            rateId={rateId}
          />
        </>
      ) : (
        <div className="PageSubtitleContainer">
          <h4>{translate('maxLimitReached')}</h4>
        </div>
      )}
    </div>
  );
}
