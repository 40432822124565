import { Heading, useTheme, Flex } from '@aws-amplify/ui-react';
import { useTranslate } from '../../hooks';

function LoginHeader() {
  const { tokens } = useTheme();
  const translate = useTranslate('Amplify');

  return (
    <Flex
      justifyContent="center"
      padding={`0 0 ${tokens.space.medium}`}
    >
      <Heading
        level={4}
        padding={`${tokens.space.xl} ${tokens.space.xl} 0`}
        letterSpacing="-1px"
      >
        {translate('welcome')}
      </Heading>
    </Flex>

  );
}

export default LoginHeader;
