import { Outlet } from 'react-router-dom';
import SideNavBar from '../navigation/SideNavBar';

export default function ProtectedLayout() {
  return (
    <>
      <SideNavBar />
      <Outlet />
    </>
  );
}
