import { useEffect, useState, useContext } from 'react';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import OrderDetail from '../../components/OrderComponents/OrderDetails';
import { IRowData } from '../../interfaces/INavigableTableObjects';
import { IOrder } from '../../interfaces/IOrder';
import { GlobalContext } from '../../store';
import textInputToNumber from '../../helpers/textInputToNumber';
import capitalizeFirstLetter from '../../helpers/capitalizeFirstLetter';
import uploadDocumentsToS3 from '../../helpers/uploadDocuments';
import { useTranslate } from '../../hooks';

interface Props {
  goPreviousPage: () => void;
  goNextPage: () => void;
  order: IOrder;
  orderDetails: IRowData[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAlertDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setModalAlertTitle: React.Dispatch<React.SetStateAction<string>>;
  setModalAlertText: React.Dispatch<React.SetStateAction<string>>;
  setOrderDetails: React.Dispatch<React.SetStateAction<IRowData[]>>;
  setRateId: React.Dispatch<React.SetStateAction<number>>;
  rateId: number;
  uploadedInvoice: HTMLInputElement | null;
}

export default function Summary(props: Props) {
  const {
    order, orderDetails, goPreviousPage, setLoading, setOpenAlertDialog, setOrderDetails,
    setModalAlertTitle, setModalAlertText, rateId, uploadedInvoice, goNextPage, setRateId,
  } = props;
  const translate = useTranslate('Page.CreateOrder');
  const { user, api } = useContext(GlobalContext);
  const [totalProducts, setTotalProducts] = useState<number>(0);
  const addedProducts = orderDetails.filter((product) => (product.row[1].value as number) > 0);

  async function handleSubmit() {
    setLoading(true);
    if (order.pat?.length === 0) {
      order.pat = null;
    }
    const rate = await api.getDeliveryRates({ filter: { id: rateId } });
    order.shippingType = rate.deliveryRates[0].shippingType;
    order.LocalId = rate.deliveryRates[0].LocalId;
    order.CourierId = rate.deliveryRates[0].CourierId;
    order.DeliveryRateId = rate.deliveryRates[0].id;
    const prefix = 'NOMAD-';
    order.code = prefix;
    order.platformCode = prefix;
    const invoiceUrl = `Finanzas/Creación Pedidos/Factura-${new Date().getTime()}`;
    if (uploadedInvoice !== null) {
      order.ticketUrl = `https://clients-platform.s3.amazonaws.com/${
        invoiceUrl}-${user.username}.pdf`;
    }
    addedProducts.forEach((dataRow: IRowData) => {
      order.OrderDetails?.push({
        ProductId: dataRow.index,
        amount: dataRow.row[1].value as number,
        price: dataRow.row[2].value as number,
        discount: 0,
      });
    });
    try {
      // Post order
      await api.createOrder(order);
      // reset values
      order.customerName = '';
      order.customerEmail = '';
      order.customerPhone = '';
      order.note = '';
      order.pat = '';
      order.shippingAddress = { address1: '', address2: '', commune: '' };
      order.shippingType = '';
      order.ClientId = user.connectableId;
      order.shippingCost = 0;
      order.OrderDetails = [];
      order.date = (new Date()).toISOString();
      order.code = '';
      order.platformCode = '';
      setRateId(0);
      setOrderDetails([]);
      goNextPage();
      setLoading(false);
      setOpenAlertDialog(true);
      setModalAlertTitle(translate('SummaryStep.modalTitle'));
      setModalAlertText(translate('SummaryStep.modalDescription'));
    } catch (error) {
      order.OrderDetails = [];
      let message = translate('SummaryStep.errorMessage');
      if (error instanceof Error) {
        message = error.message;
      }
      setLoading(false);
      setOpenAlertDialog(true);
      setModalAlertTitle(translate('SummaryStep.modalErrorTitle'));
      setModalAlertText(`${translate('SummaryStep.modalErrorDescription')} ${message},`
      + ` ${translate('SummaryStep.modalErrorDescriptionContact')}`);
    }
    try {
      // Upload document
      if (uploadedInvoice !== null) {
        uploadDocumentsToS3(user.username, invoiceUrl, uploadedInvoice);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    let sum = 0;
    addedProducts.forEach((dataRow: IRowData) => {
      sum += ((dataRow.row[1].value as number) * (dataRow.row[2].value as number));
    });
    setTotalProducts(sum);
  }, []);

  return (
    <>
      <div className="PageSubtitleContainer">
        {translate('SummaryStep.subtitle')}
      </div>
      <div className="InfoTableContainer">
        <div className="Column SummaryLeft">
          <div className="ItemsContainer">
            <div className="SummaryTitle">
              {translate('SummaryStep.generalInfo')}
            </div>
            { order.ChargeRate && order.OrderType && (
              <div className="Item">
                <div className="AllWidth">
                  <div className="Label">
                    {translate('SummaryStep.businessModel')}
                  </div>
                  <div className="Value">
                    {`${order.OrderType.name} - ${capitalizeFirstLetter(order.ChargeRate.type)}`}
                  </div>
                </div>
              </div>
            )}
            <div className="Item">
              <div className="AllWidth">
                <div className="Label">
                  {translate('SummaryStep.name')}
                </div>
                <div className="Value">
                  {capitalizeFirstLetter(order.customerName)}
                </div>
              </div>
            </div>
            <div className="Item">
              <div className="AllWidth">
                <div className="Label">
                  {translate('SummaryStep.phone')}
                </div>
                <div className="Value">
                  {order.customerPhone}
                </div>
              </div>
            </div>
            <div className="Item">
              <div className="AllWidth">
                <div className="Label">
                  {translate('SummaryStep.email')}
                </div>
                <div className="Value">
                  {order.customerEmail}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Column SummaryRight">
          <div className="ItemsContainer">
            <div className="SummaryTitle">
              {translate('SummaryStep.deliveryInfo')}
            </div>
            <div className="Item">
              <div className="AllWidth">
                <div className="Label">
                  {translate('SummaryStep.rate')}
                </div>
                <div className="Value">
                  {capitalizeFirstLetter(order.shippingType)}
                </div>
              </div>
            </div>
            <div className="Item">
              <div className="AllWidth">
                <div className="Label">
                  {translate('SummaryStep.commune')}
                </div>
                <div className="Value">
                  {capitalizeFirstLetter(order.shippingAddress.commune as string)}
                </div>
              </div>
            </div>
            <div className="Item">
              <div className="AllWidth">
                <div className="Label">
                  {translate('SummaryStep.address')}
                </div>
                <div className="Value">
                  {capitalizeFirstLetter(order.shippingAddress.address1 as string)}
                  {' '}
                  {capitalizeFirstLetter(order.shippingAddress.address2 as string)}
                </div>
              </div>
            </div>
            { order.CourierId && (
              <div className="Item">
                <div className="AllWidth">
                  <div className="Label">
                    {translate('SummaryStep.requiresNomadFleet')}
                  </div>
                  <div className="Value">
                    {translate(`SummaryStep.${!!order.CourierId}`)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="InfoTableContainer">
        <div className="Column SummaryLeft">
          <div className="ItemsContainer AllWidth">
            <table>
              <thead>
                <tr>
                  <th>
                    {translate('Columns.products')}
                  </th>
                  <th>
                    {translate('Columns.quantity')}
                  </th>
                  <th>
                    {translate('Columns.price')}
                  </th>
                  <th className="Orange">
                    {translate('Columns.subtotal')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {(addedProducts.map((dataRow: IRowData) => (
                  <OrderDetail
                    key={dataRow.index}
                    orderDetail={dataRow}
                  />
                )))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="InfoTableContainer">
        <div className="Column SummaryLeft">
          <div className="ItemsContainer Middle">
            <div className="SummaryTitle">
              {translate('SummaryStep.SummaryTable.summary')}
            </div>
            <div className="Item">
              <div className="Label">
                {translate('SummaryStep.SummaryTable.products')}
              </div>
              <div className="Value">
                {`$${textInputToNumber(totalProducts.toString(), true)}`}
              </div>
            </div>
            <div className="Item">
              <div className="Label">
                {translate('SummaryStep.SummaryTable.delivery')}
              </div>
              <div className="Value">
                {`$${textInputToNumber(order.shippingCost.toString(), false)}`}
              </div>
            </div>
            <div className="Item">
              <div className="Label Total">
                {translate('SummaryStep.SummaryTable.total')}
              </div>
              <div className="Value Total">
                {`$${textInputToNumber((totalProducts + order.shippingCost).toString(), true)}`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="BottomRow">
        <div
          className="GoBackOrderPage"
          onClick={goPreviousPage}
        >
          <LeftOutlined className="RightArrow" />
          <div>
            {translate('SummaryStep.goBackButton')}
          </div>
        </div>
        <div
          className="Button Primary"
          onClick={handleSubmit}
        >
          {translate('SummaryStep.createOrderButton')}
        </div>
      </div>
    </>
  );
}
