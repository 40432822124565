import { useEffect, useMemo } from 'react';
import { ReactComponent as SelectIcon } from '../../assets/icons/Select.svg';
import capitalizeFirstLetter from '../../helpers/capitalizeFirstLetter';

interface Props<T extends object> {
  data: T[];
  keyValue: keyof T;
  selectValue: keyof T;
  displayValue: keyof T;
  selected: T | null;
  initialValue?: string;
  setSelected: (newSelection: T) => void;
  disable?: boolean;
}

export function SelectInput<T extends object>(props: Props<T>) {
  const {
    data,
    keyValue,
    selectValue,
    displayValue,
    selected,
    initialValue,
    disable = false,
    setSelected,
  } = props;
  // Add default value as empty data
  const dataToShow = useMemo(() => {
    if (initialValue) {
      return [{ [selectValue]: '', [displayValue]: initialValue } as T, ...data];
    }
    return data;
  }, [initialValue, selectValue, displayValue, data]);

  useEffect(() => {
    if (data.length > 0 && !selected && !initialValue) {
      setSelected(data[0]);
    }
  }, [data]);

  return (
    <div className="SelectWrapper">
      <SelectIcon className="SelectIcon" />
      <select
        disabled={disable}
        onChange={(e) => {
          const newSelection = dataToShow.find((selectOption) => String(selectOption[selectValue]) === e.target.value);
          if (!newSelection) return;
          setSelected(newSelection);
        }}
        value={(selected) ? selected[selectValue] as string : ''}
      >
        {dataToShow.map((selectOption) => (
          <option
            key={selectOption[keyValue] as string}
            value={selectOption[selectValue] as string}
          >
            {capitalizeFirstLetter(selectOption[displayValue] as string)}
          </option>
        ))}
      </select>
    </div>
  );
}
