import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import textInputToNumber from '../../helpers/textInputToNumber';
import IDeliveryRate from '../../interfaces/IDeliveryRate';
import capitalizeFirstLetter from '../../helpers/capitalizeFirstLetter';
import { useTranslate } from '../../hooks';

interface DeliveryRatesTableProps {
  rows: IDeliveryRate[];
  onChangeRequest: (rowsPerPage: number, page: number) => void;
  initialPageSze: number;
  initialPage: number;
  totalRowCount: number;
  showModal: (value: boolean) => void;
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  loadingTable: boolean;
  resetPage: boolean;
  setResetPage: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DeliveryRateTable({
  rows,
  onChangeRequest,
  initialPageSze,
  initialPage,
  totalRowCount,
  showModal,
  selected,
  setSelected,
  loadingTable,
  resetPage,
  setResetPage,
}: DeliveryRatesTableProps) {
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialPageSze);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const translate = useTranslate('Component.DeliveryRateTable');

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(!selectAll);
    if (event.target.checked && selected.length === 0) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected as number[]);
      return;
    } if (event.target.checked && selected.length > 0) {
      let newSelected = [...selected];
      newSelected = newSelected.concat((rows.map((n) => n.id) as number[]));
      setSelected(newSelected);
    } if (!event.target.checked && selected.length > 0) {
      setSelected([]);
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setSelectAll(false);
    setPage(newPage);
    onChangeRequest(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setSelected([]);
    onChangeRequest(newRowsPerPage, 0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  useEffect(() => {
    if (selected.length > 0) {
      showModal(true);
    } else {
      showModal(false);
    }
  }, [selected, page]);

  useEffect(() => {
    if (resetPage) {
      setPage(initialPage);
      setResetPage(false);
    }
  }, [resetPage]);

  return (
    <Paper sx={{ width: '90%' }}>
      <TableContainer sx={{ minHeight: 620, maxHeight: 620 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px', textAlign: 'center' }}>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('price')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('activateAt')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('shippingType')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('commune')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('local')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('title')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('subtitle')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('courier')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('posId')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('checkStock')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('active')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('scheduled')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('daysDelay')}
              </TableCell>
            </TableRow>
          </TableHead>
          {rows.length === 0 ? (
            <TableBody>
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
              >
                <TableCell
                  sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                  colSpan={10}
                >
                  {translate('noRates')}
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {loadingTable ? (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell
                    sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                    colSpan={10}
                  >
                    <LoadingOutlined />
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((rate: IDeliveryRate) => {
                  const isItemSelected = isSelected(rate.id as number);
                  return (
                    <TableRow
                      key={rate.id}
                      sx={{ cursor: 'pointer' }}
                      hover
                      tabIndex={-1}
                    >
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px', textAlign: 'center' }}>
                        <input
                          type="checkbox"
                          onClick={(event) => handleClick(event, (rate.id as number))}
                          color="primary"
                          checked={isItemSelected}
                          readOnly
                        />
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {rate.price !== null
                          ? `$ ${textInputToNumber(rate.price.toString(), false)}` : translate('transferred')}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        $
                        {textInputToNumber(rate.activateAt.toString(), false)}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {capitalizeFirstLetter(rate.shippingType)}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {rate.Commune ? capitalizeFirstLetter(rate.Commune.name) : ''}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {rate.Local ? capitalizeFirstLetter(rate.Local.name) : ''}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {rate.title}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {rate.subtitle}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {rate.Courier ? capitalizeFirstLetter(rate.Courier.name) : ''}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {rate.PointOfSaleId}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {rate.checkStock ? translate('yes') : translate('no')}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {rate.active ? translate('yes') : translate('no')}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {rate.scheduled ? translate('yes') : translate('no')}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {rate.daysDelay}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100, 500, { value: -1, label: 'Todas' }]}
        component="div"
        count={totalRowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={translate('perPage')}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
