import { useTranslate } from '../../hooks';
import { IShipmentTracking } from '../../interfaces/ITracking';

interface Props {
  shipment: IShipmentTracking;
}

function TrackingDeliveryProofData(props: Props) {
  const { shipment } = props;
  const translate = useTranslate('Tracking.deliveryProof');

  return (
    <div className="TrackingBodyData">
      {shipment.status === 'delivered' && shipment.receiverInfo && (
        <>
          <span className="TrackingBodyDataTitle">{translate('title')}</span>
          <br />
          {shipment.receiverInfo.name && (
            <>
              {translate('name')}
              :&nbsp;
              {shipment.receiverInfo.name}
              <br />
            </>
          )}
          {(shipment.receiverInfo.rut || shipment.receiverInfo.identifier) && (
            <>
              {translate('id')}
              :&nbsp;
              {shipment.receiverInfo.rut || shipment.receiverInfo.identifier}
              <br />
            </>
          )}
          {shipment.receiverInfo.image && (
            <>
              {translate('photo')}
              :&nbsp;
              <a
                className="TrackingPhotoLink"
                href={shipment.receiverInfo.image}
                target="_blank"
                rel="noreferrer"
              >
                {translate('download')}
              </a>
              <br />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default TrackingDeliveryProofData;
