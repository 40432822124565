import { Suspense } from 'react';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import { IntercomProvider } from 'react-use-intercom';
import LoadingPage from './components/LoadingPageComponent';
import AuthWrapper from './store/AuthWrapper';

function App() {
  return (
    <Suspense fallback={<LoadingPage full />}>
      <AmplifyProvider>
        <Authenticator.Provider>
          <IntercomProvider
            appId={process.env.REACT_APP_INTERCOM_APP_ID as string}
            apiBase={`https://${process.env.REACT_APP_INTERCOM_APP_ID}.intercom-messenger.com`}
            autoBoot
          >
            <AuthWrapper />
          </IntercomProvider>
        </Authenticator.Provider>
      </AmplifyProvider>
    </Suspense>
  );
}

export default App;
