import NotionPage from '../components/NotionPageComponent';
import { useTranslate } from '../hooks';

export default function BillingAndPayments() {
  const translate = useTranslate('Page.BillingManual');

  const data = [
    {
      id: 'dbccaea8353448fc95840434887818fd',
      title: translate('products'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/Merchandise.jpg',
    },
    {
      id: '85aa9eff7b494c91ae7d218cb297442a',
      title: translate('reports'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/Reports.jpg',
    },
    {
      id: '5112668c2b294b14a33594e0c2e65628',
      title: translate('appSales'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/AppSales.jpg',
    },
    {
      id: '168a12e5ac74474da1828c6d2869fbd5',
      title: translate('webSales'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/WebSales.jpg',
    },
    {
      id: '71eb16d662d543309c76e33a05a4761f',
      title: translate('storage'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/Warehouse.jpg',
    }];
  return (
    <NotionPage pageData={data} />
  );
}
