import { useTranslate } from '../../hooks';
import { IOrderTracking } from '../../interfaces/ITracking';

interface Props {
  order: IOrderTracking;
}

function TrackingDeliveryInfoData(props: Props) {
  const { order } = props;
  const translate = useTranslate('Tracking.deliveryInfo');

  return (
    <div className="TrackingBodyData">
      <span className="TrackingBodyDataTitle">{translate('title')}</span>
      <br />
      {order.customerName && (
        <>
          {translate('name')}
          :&nbsp;
          {order.customerName}
          <br />
        </>
      )}
      {order.customerPhone && (
        <>
          {translate('contact')}
          :&nbsp;
          {order.customerPhone}
          <br />
        </>
      )}
      {order.shippingAddress.address2 && (
        <>
          {translate('additionalAddress')}
          :&nbsp;
          {order.shippingAddress.address2}
          <br />
        </>
      )}
      {order.note && (
        <>
          {translate('note')}
          :&nbsp;
          {order.note}
          <br />
        </>
      )}
    </div>
  );
}

export default TrackingDeliveryInfoData;
