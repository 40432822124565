import {
  useState, useRef, DragEvent, SyntheticEvent, useEffect,
} from 'react';
import { useTranslate } from '../../hooks';

interface Props {
  changeHandler: (file: File) => void;
  createItemsSubmitted: boolean;
  setCreateItemsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DropFile(props: Props) {
  const { changeHandler, setCreateItemsSubmitted, createItemsSubmitted } = props;
  const [documentName, setDocumentName] = useState<string>('');
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const translate = useTranslate('Component.DropFile');

  // handle drag events
  function onChangeDocument(fileName: string) {
    const i = fileName.lastIndexOf('\\');
    const newFileName = fileName.substring(i + 1);
    setDocumentName(newFileName);
  }
  function handleDrag(e: DragEvent<HTMLFormElement> | DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }
  function handleChange() {
    const uploadedDoc = document.getElementById('DropInputFileUpload') as HTMLInputElement;
    const file = uploadedDoc.files;
    if (file!) {
      if (file[0]) {
        onChangeDocument(file[0].name);
        changeHandler(file[0]);
      }
    }
  }
  // triggers when file is dropped
  function handleDrop(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onChangeDocument(e.dataTransfer.files[0].name);
      changeHandler(e.dataTransfer.files[0]);
    }
  }
  // triggers the input when the button is clicked
  const onButtonClick = (e: SyntheticEvent) => {
    e.preventDefault();
    inputRef.current!.click();
    handleChange();
  };
  useEffect(() => {
    if (createItemsSubmitted) {
      setDocumentName('');
      const uploadedDoc = document.getElementById('DropInputFileUpload') as HTMLInputElement;
      uploadedDoc.value = '';
      setCreateItemsSubmitted(false);
    }
  }, [createItemsSubmitted]);
  return (
    <form
      id="DropFormFileUpload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="DropInputFileUpload"
        onChange={handleChange}
        accept=".csv"
      />
      <label
        id="DropLabelFileUpload"
        htmlFor="DropInputFileUpload"
        className={dragActive ? 'DropDragActive' : ''}
      >
        <button
          className="DropUploadButton"
          onClick={onButtonClick}
          type="button"
        >
          {translate('importCSV')}
        </button>
        <div className="InputFormDocumentUploadText">{documentName}</div>
      </label>
      { dragActive && (
      <div
        id="DropDragFileElement"
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      />
      ) }
    </form>
  );
}
