import AWS from 'aws-sdk';

function uploadDocumentsToS3(clientName: string, url: string, fileElement: HTMLInputElement) {
  const files = fileElement.files as FileList;

  // Initializing S3 Interface
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  for (let i = 0; i < files?.length; i += 1) {
    let filename;
    if (files.length === 1) {
      filename = `${url}-${clientName}.pdf`;
    } else {
      filename = `${url}-${i + 1}-${clientName}.pdf`;
    }
    const params = {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME as string,
      Key: filename,
      Body: files[i],
    };
    // Uploading files to the bucket
    try {
      s3.upload(params, (err: Error) => {
        if (err) {
          throw new Error(`Error uploading json file on S3 Bucket${err}`);
        } else {
          console.log('File uploaded successfully to S3 Bucket');
        }
      });
    } catch (error) {
      console.log('Error uploading json file on S3 Bucket', error);
    }
  }
}

export default uploadDocumentsToS3;
