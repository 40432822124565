export const getCountriesVariables = ({
  filter,
}: {
  filter?: { [key: string]: string | number | number[] | boolean | string[] | { [key: string]: string | string[] } }
}) => {
  const { ClientId, PointOfSaleId } = filter || {};
  return {
    where: {
      _and: [
        {
          POS: {
            ClientId: ClientId ? {
              _eq: ClientId,
            } : {},
            id: PointOfSaleId ? {
              _eq: PointOfSaleId,
            } : {},
          },
        },
      ],
    },
  };
};
