import { Dispatch } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useLocalStorage } from '../hooks/useLocalStorage';

// Interfaces
import { ActionInterface, ContextInterface } from '../interfaces';
import IDeliveryRate from '../interfaces/IDeliveryRate';
import IProduct from '../interfaces/IProduct';
import { clientDefaultState } from './reducer';

export default function GetGlobalContext(dispatch: Dispatch<ActionInterface>): ContextInterface {
  // Local storage
  const [localState, setLocalState] = useLocalStorage('state', clientDefaultState);
  const { shutdown } = useIntercom();
  const { signOut } = useAuthenticator((context) => [context.user]);

  const globalContext: ContextInterface = {
    logOut: () => {
      dispatch({ type: 'START_LOADING' });
      dispatch({ type: 'LOG_OUT' });
      setLocalState({
        ...clientDefaultState,
      });
      shutdown();
      signOut();
      dispatch({ type: 'FINISH_LOADING' });
    },
    setFilteredItemsToEdit: (filteredItemsToEdit: IProduct[] | IDeliveryRate[]) => {
      dispatch({ type: 'SET_FILTERED_ITEMS_TO_EDIT', data: { filteredItemsToEdit } });
      setLocalState({
        ...localState,
        filteredItemsToEdit,
      });
    },
  };

  return globalContext;
}
