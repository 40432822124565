export const getProducts = (limit: number | null, offset: number) => `
query getProducts($where: Products_bool_exp!) {
  Products(limit: ${limit}, offset: ${offset}, where: {
    _and: [
      { activated: { _eq: true } },
      $where,
    ]
  }) {
      id
      barcode
      sku
      rawSku
      name
      isPack
      isWeighted
      pvp
      safetyStock
      storageType
      ageVerification
      needsLotTracking
      expirationAlert
      Size {
        id
        name
      }
    }
    Products_aggregate(where: {
      _and: [
        { activated: { _eq: true } },
        $where,
      ]
    }) {
      aggregate {
        count
      }
    }
  }
`;
