import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper,
} from '@mui/material';
import { ICellData, IColumnData, IRowData } from '../../interfaces/INavigableTableObjects';
import CellTableComponent from './CellTableComponent';
import Loading from '../LoadingComponent';
import keyPressEvent from '../../helpers/keyPressEvent';
import { useTranslate } from '../../hooks';

interface Props {
  dataToShow: IRowData[];
  setDataToShow: React.Dispatch<React.SetStateAction<IRowData[]>>;
  columns: IColumnData[];
  loading: boolean;
}
function NavigableTable(props: Props) {
  const {
    dataToShow, columns, loading, setDataToShow,
  } = props;
  const translate = useTranslate('NavigableTable');
  const [mouseDown, setMouseDown] = useState<boolean | null>(null);
  const [firstCell, setFirstCell] = useState<number[]>([]);
  const [lastCell, setLastCell] = useState<number[]>([]);
  const [typeOfCellToUpdate, setTypeOfCellToUpdate] = useState<string>('');
  const [newValue, setNewValue] = useState<string[]>([]);
  const [anyCellHighlighted, setAnyCellHighlighted] = useState<boolean>(false);
  let auxValueToShowTableData = '';

  function copy() {
    const minX = Math.min(firstCell[1], lastCell[1]);
    const minY = Math.min(firstCell[0], lastCell[0]);
    const maxX = Math.max(firstCell[1], lastCell[1]);
    const maxY = Math.max(firstCell[0], lastCell[0]);
    let copyValue = '';
    for (let y = minY; y <= maxY; y += 1) {
      for (let x = minX; x <= maxX; x += 1) {
        const currentCell = dataToShow[y].row[x];
        copyValue += `${currentCell.value}\t`;
      }
      copyValue += '\n';
    }
    return copyValue;
  }
  // Function to edit cell value
  function setValue(value: unknown, row: number, cell: number) {
    const newDataToShow = [
      ...dataToShow,
    ];
    newDataToShow[row].row[cell].value = value;

    newDataToShow.forEach((rowProduct: IRowData) => {
      if (rowProduct.row[2].isDisable) return;
      const blankCellData = [
        { index: 0, value: rowProduct.row[0].value, isDisable: false },
        { index: 1, value: 0, isDisable: false },
        { index: 2, value: rowProduct.row[2].isDisable ? null : '01-01-2030', isDisable: rowProduct.row[2].isDisable },
      ];
      const blankRowData: IRowData = { index: rowProduct.index, row: blankCellData };
      const productIndex = newDataToShow.findIndex(({ row: [rowSku, rowQuantity, rowExpirationDate] }) => {
        return rowSku.value === blankRowData.row[0].value
          && ((rowExpirationDate.value === blankRowData.row[2].value
            || rowQuantity.value === blankRowData.row[1].value)
            || (rowExpirationDate.value === '' || rowQuantity.value === ''));
      });

      if (productIndex === -1) {
        const lastProductIndex = newDataToShow.map(({ row: [rowSku] }) => rowSku.value)
          .lastIndexOf(blankRowData.row[0].value);
        newDataToShow.splice(lastProductIndex + 1, 0, blankRowData);
      }
    });
    setDataToShow(newDataToShow);
    setAnyCellHighlighted(true);
  }
  // Function to edit cell value
  function changeCellValue(cellType: string, value: string) {
    setTypeOfCellToUpdate(cellType);
    let arrayNewValues: string[] = [];
    if (value.search(/\n/) && cellType === 'text') {
      arrayNewValues = value.split(/\n/);
      setNewValue(arrayNewValues);
    } else if (/\s/.test(value) && (cellType === 'date' || cellType === 'number')) {
      arrayNewValues = value.split(' ');
      setNewValue(arrayNewValues);
    } else {
      setNewValue([value]);
    }
  }

  // Function to update last cell pressed/hovered
  function updateLastCell(last: number[]) {
    if (lastCell.length > 0) {
      setAnyCellHighlighted(true);
    }
    setLastCell(last);
  }
  // Function to update first cell pressed/hovered
  function updateFirstCell(first: number[]) {
    if (firstCell.length > 0) {
      setAnyCellHighlighted(true);
    }
    setFirstCell(first);
  }
  // change state if mouse is pressed
  useEffect(() => {
    function onClickDown() {
      setMouseDown(true);
    }
    // Bind the event listener
    document.addEventListener('mousedown', onClickDown);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', onClickDown);
    };
  }, [mouseDown]);
  useEffect(() => {
    function onClickUp() {
      setMouseDown(false);
    }
    // Bind the event listener
    document.addEventListener('mouseup', onClickUp);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mouseup', onClickUp);
    };
  }, [mouseDown]);
  useEffect(() => {
    // Nav with arrows
    const changeSelectedCells = (e: KeyboardEvent) => {
      // Catch only on highlighted cell
      if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
        const textToCopy = copy();
        navigator.clipboard.writeText(textToCopy);
      }
    };
    document.addEventListener('keydown', changeSelectedCells);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', changeSelectedCells);
    };
  }, [firstCell, lastCell]);
  useEffect(() => {
    // Check if we clicked outside of some element
    function handleClickOutside(event: MouseEvent) {
      if (!(event.target as HTMLInputElement).name?.includes('input') && anyCellHighlighted) {
        // Deleting focus
        const inputField = document.querySelector(
          `input[name=input-${firstCell[0]}-${firstCell[1]}]`,
        ) as HTMLInputElement;
        if (inputField !== null) {
          inputField.blur();
        }
        updateFirstCell([]);
        updateLastCell([]);
        setAnyCellHighlighted(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anyCellHighlighted]);

  return (
    <TableContainer component={Paper}>
      <Table
        size="small"
        sx={{ minWidth: 650, padding: 0, position: 'relative' }}
        aria-label="simple table"
      >
        <TableHead className="AddedProductsHeader">
          <TableRow>
            {columns.map((col: IColumnData) => {
              return (
                <TableCell
                  sx={{
                    color: 'white',
                    fontFamily: 'Lato',
                    fontSize: '15px',
                    borderLeft: '1px solid rgba(224, 224, 224, 1)',
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  {col.title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {dataToShow.length === 0 && !loading ? (
          <TableBody>
            <TableRow
              key={0}
            >
              <TableCell
                sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                component="th"
                scope="row"
              >
                {translate('noProducts')}
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {!loading ? (
              dataToShow.map((dataRow: IRowData, idx: number) => {
                const isFirstRow = auxValueToShowTableData !== dataRow.row[0]?.value;
                if (isFirstRow) {
                  auxValueToShowTableData = dataRow.row[0]?.value as string;
                }
                return (
                  <>
                    {isFirstRow && (
                      <TableRow>
                        <TableCell>{dataRow.row[0]?.value as string}</TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      {dataRow.row.map((dataCell: ICellData, columnNumber: number) => {
                        return (
                          <CellTableComponent
                            typeOfCell={columns[columnNumber].type}
                            dataCell={dataCell}
                            row={idx}
                            setValue={setValue}
                            mouseDown={mouseDown}
                            columns={columns}
                            updateLastCell={updateLastCell}
                            updateFirstCell={updateFirstCell}
                            firstCell={firstCell}
                            lastCell={lastCell}
                            changeCellValue={changeCellValue}
                            typeOfCellToUpdate={typeOfCellToUpdate}
                            newValue={newValue}
                            keyPressEvent={(e: KeyboardEvent) => (
                              keyPressEvent(
                                e,
                                idx,
                                dataCell.index,
                                dataToShow,
                                columns,
                                updateLastCell,
                                updateFirstCell,
                              )
                            )}
                          />
                        );
                      })}
                    </TableRow>
                  </>
                );
              })
            ) : (

              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
              >
                <TableCell
                  sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                  colSpan={4}
                >
                  <Loading />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export default NavigableTable;
