import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import IPaymentOrder from '../../interfaces/IPaymentOrder';
// import downloadFileFromS3 from '../../helpers/downloadFileFromS3';
import { useTranslate } from '../../hooks';

interface PaymentOrdersTableProps {
  rows: IPaymentOrder[];
  onChangeRequest: (rowsPerPage: number, page: number) => void;
  initialPageSze: number;
  page: number;
  count: number;
  showModal: () => void;
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  loadingTable: boolean;
}

export default function PaymentOrdersTable({
  rows,
  onChangeRequest,
  initialPageSze,
  page,
  count,
  showModal,
  selected,
  setSelected,
  loadingTable,
}: PaymentOrdersTableProps) {
  const [rowsPerPage, setRowsPerPage] = useState(initialPageSze);
  const translate = useTranslate('Component.PaymentOrdersTable');

  const handleChangePage = (event: unknown, newPage: number) => {
    setSelected('');
    onChangeRequest(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setSelected('');
    onChangeRequest(newRowsPerPage, 0);
  };

  const handleSelected = (paymentOrder: IPaymentOrder) => {
    if (paymentOrder.id === selected) {
      setSelected('');
    } else if (paymentOrder.status === 'pending') {
      setSelected(paymentOrder.id);
    }
  };

  const downloadFiles = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, paymentOrder: IPaymentOrder) => {
    event?.stopPropagation();
    const downloadPromises = paymentOrder.documents.map(async (documentName) => {
      const response = await fetch(documentName);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${documentName}`;
      link.click();
      link.remove();
      window.URL.revokeObjectURL(link.href);
    });
    await Promise.all(downloadPromises);
  };

  const formatDate = (dateString: string) => {
    // return the string of the month in the date
    const date = new Date(dateString);
    // get the locales language from the browser local storage
    const month = date.toLocaleString(localStorage.getItem('i18nextLng') || 'en', { month: 'long' });
    return `${month} ${date.getFullYear()}`;
  };

  useEffect(() => {
    showModal();
  }, [selected, page]);

  return (
    <Paper sx={{ width: '90%' }}>
      <TableContainer sx={{ minHeight: 620, maxHeight: 620 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('emissionDate')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('status')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('totalPrice')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('expirationDate')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('documents')}
              </TableCell>
            </TableRow>
          </TableHead>
          {rows.length === 0 ? (
            <TableBody>
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
              >
                <TableCell
                  sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                  colSpan={7}
                >
                  {translate('noPaymentOrders')}
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {loadingTable ? (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell
                    sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                    colSpan={8}
                  >
                    <LoadingOutlined />
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((paymentOrder: IPaymentOrder) => {
                  const isItemSelected = selected === paymentOrder.id;
                  return (
                    <TableRow
                      sx={{ cursor: 'pointer' }}
                      hover
                      tabIndex={-1}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      key={paymentOrder.id}
                      selected={isItemSelected}
                      onClick={() => handleSelected(paymentOrder)}
                    >
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {formatDate(paymentOrder.emissionDate)}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>{paymentOrder.status}</TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {paymentOrder.totalPrice.toLocaleString(localStorage.getItem('i18nextLng') || 'en')}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {formatDate(paymentOrder.expirationDate)}
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                        {paymentOrder.documents && paymentOrder.documents.length > 0 && (
                          <div
                            className="ItemsFiltersBtn Colored"
                            onClick={(event) => downloadFiles(event, paymentOrder)}
                          >
                            {translate('download')}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100, 500, { value: -1, label: translate('all') }]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={translate('perPage')}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
