import {
  GoogleMap, MarkerF, useJsApiLoader,
} from '@react-google-maps/api';
import LoadingPage from '../LoadingPageComponent';

interface Props {
  destiny: {
    lat: number;
    lng: number;
  };
  origin?: {
    lat: number;
    lng: number;
  };
  current?: {
    lat: number;
    lng: number;
  },
  zoom?: number;
}

function TrackingMap(props: Props) {
  const {
    destiny, origin, current, zoom,
  } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  });

  const onLoad = (map: google.maps.Map) => {
    const bounds = new google.maps.LatLngBounds();
    [destiny, origin, current].forEach((marker) => {
      if (marker) {
        const { lat, lng } = marker;
        bounds.extend({ lat, lng });
      }
    });
    map.fitBounds(bounds);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName="MapContainer"
      onLoad={onLoad}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
      }}
      zoom={zoom}
    >
      <MarkerF
        position={destiny}
        icon={{
          url: 'https://clients-platform-assets.s3.amazonaws.com/img/destiny-marker.png',
          scaledSize: new google.maps.Size(30, 40),
        }}
      />
      {origin
        && (
        <MarkerF
          position={origin}
          icon={{
            url: 'https://clients-platform-assets.s3.amazonaws.com/img/shop-marker.png',
            scaledSize: new google.maps.Size(37, 47),
          }}
        />
        )}
      {current
        && (
        <MarkerF
          position={current}
          icon={{
            url: 'https://clients-platform-assets.s3.amazonaws.com/img/blue-current.png',
            scaledSize: new google.maps.Size(20, 20),
          }}
        />
        )}
    </GoogleMap>
  ) : (<LoadingPage />);
}

export default TrackingMap;
