import {
  useRef,
  forwardRef,
  useImperativeHandle,
  Ref,
  useState,
  useEffect,
} from 'react';
import { IModalRef } from '../../interfaces';

interface ModalProps {
  children: JSX.Element[] | JSX.Element;
  onClose?: () => void;
  afterClose?: () => void;
  preventClosing?: boolean;
}

const Modal = forwardRef((props: ModalProps, externalRef: Ref<IModalRef>) => {
  const {
    children,
    onClose,
    afterClose,
    preventClosing,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dialogRef = useRef<HTMLDialogElement>(null);

  // Helper functions
  function show() {
    if (dialogRef.current && !isOpen) {
      dialogRef.current.showModal();
      setIsOpen(true);
    }
  }

  function close(force = false) {
    if (preventClosing && !force) return;
    if (onClose) {
      onClose();
    }
    if (dialogRef.current && isOpen) {
      setIsOpen(false);
    }
  }

  // Set the reference methods
  useImperativeHandle(externalRef, () => ({
    show() {
      show();
    },
    close(force = false) {
      close(force);
    },
  }));

  // Add or remove closing class
  useEffect(() => {
    if (dialogRef.current === null) {
      return;
    }
    if (isOpen) {
      dialogRef.current.classList.remove('Closing');
    } else {
      dialogRef.current.classList.add('Closing');
    }
  }, [isOpen]);

  return (
    <dialog
      className="Modal"
      ref={dialogRef}
      // Check if the user clicks on the backdrop
      onClick={(event) => {
        if (dialogRef.current === null) {
          return;
        }
        const rect = dialogRef.current.getBoundingClientRect();
        const isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height
          && rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
        if (!isInDialog) {
          close();
        }
      }}
      // When the user closes by any means,
      // an animation is going to be triggered,
      //  at the end, the modal is closed
      onAnimationEnd={() => {
        if (!isOpen && dialogRef.current !== null) {
          dialogRef.current.close();
          if (afterClose) {
            afterClose();
          }
        }
      }}
    >
      {(!preventClosing) && (
        <div
          className="Close"
          onClick={() => close()}
        >
          x
        </div>
      )}
      {children}
    </dialog>
  );
});

Modal.displayName = 'Modal';
export { Modal };
