import {
  useState, useEffect, useContext, SyntheticEvent,
} from 'react';
import { v4 as uuid } from 'uuid';
import { Divider } from '@mui/material';
import { GlobalContext } from '../../store';
import DropFile from '../../components/HomeComponents/DropFile';
import AlertDialog from '../../components/HomeComponents/AlertDialog';
import 'react-datepicker/dist/react-datepicker.css';
import NavigableTable from '../../NavigableTable/NavigableTable';
import LoadingPage from '../../components/LoadingPageComponent';
import GoBackButton from '../../components/HomeComponents/GoBackButton';
import { ICellData, IColumnData, IRowData } from '../../interfaces/INavigableTableObjects';
import downloadFileFromS3 from '../../helpers/downloadFileFromS3';
import IDeliveryRate from '../../interfaces/IDeliveryRate';
import uploadCsvData from '../../helpers/uploadCsvData';
import IGetTasksStatus from '../../interfaces/IGetTasksStatus';
import { useTranslate } from '../../hooks';
import uploadFileToS3 from '../../helpers/uploadFileToS3';

export default function DeliveryRatesCreate() {
  const { api, apiFunctions, user } = useContext(GlobalContext);
  const [tableColumns, setTableColumns] = useState<string[]>([]);
  const [rowsValues, setRowsValues] = useState<unknown[]>([]);
  const [dataToShow, setDataToShow] = useState<IRowData[]>([]);
  const [columns, setColumns] = useState<IColumnData[]>([]);
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [createItemsSubmitted, setCreateItemsSubmitted] = useState<boolean>(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const shippingTypes = ['pickup', 'courier', 'express', 'same-day'];
  const translate = useTranslate('Page.CreateDeliveryRates');

  const columnsShouldHave = [
    translate('CSVColumns.price'),
    translate('CSVColumns.activateAt'),
    translate('CSVColumns.title'),
    translate('CSVColumns.subtitle'),
    translate('CSVColumns.localId'),
    translate('CSVColumns.communeId'),
    translate('CSVColumns.shippingType'),
    translate('CSVColumns.courierId'),
    translate('CSVColumns.posId'),
    translate('CSVColumns.checkStock'),
    translate('CSVColumns.active'),
    translate('CSVColumns.scheduled'),
    translate('CSVColumns.daysDelay'),
  ];
  let taskId: string | null = null;
  let retryTime = 20;
  let delayTime = 3000;
  function cleanUploadedDocument() {
    setDataToShow([]);
    setCreateItemsSubmitted(true);
  }

  // Returns how many times is a DR in deliveryRates
  function verifyDeliveryRate(
    deliveryRates: IDeliveryRate[],
    checkedActivateAt: number,
    checkedDaysDelay: number,
    checkedLocalId: number,
    checkedCommuneId: number,
    checkedShippingType: string,
    checkedCourierId: number | null,
    checkedPointOfSaleId: number,
    checkedCheckStock: boolean,
    checkedScheduled: boolean,
  ): number {
    let count = 0;
    deliveryRates.forEach((rate: IDeliveryRate) => {
      if (
        rate.activateAt === checkedActivateAt
        && rate.daysDelay === checkedDaysDelay
        && rate.CourierId === checkedCourierId
        && rate.LocalId === checkedLocalId
        && rate.CommuneId === checkedCommuneId
        && rate.shippingType === checkedShippingType
        && rate.PointOfSaleId === checkedPointOfSaleId
        && rate.checkStock === checkedCheckStock
        && rate.scheduled === checkedScheduled
      ) {
        count += 1;
      }
    });
    return count;
  }
  async function getTaskResponse() {
    if (retryTime > 0) {
      // The client's tasks are obtained from dynamoDb
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      // If the answer was failed, the item creation was wrong
      if (res?.status === 'FAILED') {
        const responseMessage = res.executions.message;
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res?.status === 'SUCCESS') {
        // If the response was successful, the item creation was well done
        cleanUploadedDocument();
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.successTitle'));
        setModalAlertText(translate('Modal.successText'));
      } else if (res?.status === 'ERROR') {
        // If the answer was error, it did not find the creation of items in the queue
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorTitle6'));
        setModalAlertText(translate('Modal.errorText6'));
      } else {
        // This case is for when the status is PENDING, the timeout will re-execute the
        // function until one of the previous cases is triggered
        // After the first execution, the delayTime is changed to 15 sec to give a
        // a longer response time for item creation
        setTimeout(getTaskResponse, delayTime);
        delayTime = 15000;
      }
      retryTime -= 1;
    } else {
      setLoading(false);
      setOpenModalAlert(true);
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      if (res?.status === 'FAILED' || res?.status === 'ERROR') {
        const responseMessage = res.executions?.message || 'Unexpected error';
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res?.status === 'SUCCESS') {
        cleanUploadedDocument();
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.successTitle'));
        setModalAlertText(translate('Modal.successText'));
      } else {
        setModalAlertTitle(translate('Modal.noticeTitle'));
        setModalAlertText(translate('Modal.noticeText'));
      }
    }
  }

  async function submitCsv(e: SyntheticEvent) {
    e.preventDefault();
    setLoading(true);
    setDisabledSubmitBtn(true);
    const bodyData: IDeliveryRate[] = [];
    const itemsMissingInfo: string[] = [];
    const deliveryRatesExists: string[] = [];
    const deliveryRatesToAdd: IDeliveryRate[] = [];
    const response = await api.getDeliveryRates({});
    const { deliveryRates } = response;
    let correctShippingType = true;
    dataToShow.forEach((item: IRowData) => {
      // Validations
      let CourierId: number | null;
      if (item.row[8].value === '' || item.row[8].value === 0) {
        CourierId = null;
      } else {
        CourierId = Number(item.row[8].value);
      }
      if (item.row[2].value === '') {
        itemsMissingInfo.push(item.row[0].value as string);
      }
      if (!shippingTypes.includes(item.row[7].value as string)) {
        correctShippingType = false;
      }
      // Set boolean values
      const checkStock = (item.row[10].value as string).toLowerCase() === translate('yes');
      const active = (item.row[11].value as string).toLowerCase() === translate('yes');
      const scheduled = (item.row[12].value as string).toLowerCase() === translate('yes');
      // Check if does exist in db
      const rateCount = verifyDeliveryRate(
        deliveryRates,
        Number(item.row[2].value),
        Number(item.row[13].value),
        Number(item.row[5].value),
        Number(item.row[6].value),
        item.row[7].value as string,
        CourierId,
        Number(item.row[9].value),
        checkStock,
        scheduled,
      );
      deliveryRatesToAdd.push({
        id: Number(item.row[0].value),
        price: Number(item.row[1].value),
        activateAt: Number(item.row[2].value),
        daysDelay: Number(item.row[13].value),
        CourierId,
        LocalId: Number(item.row[5].value),
        CommuneId: Number(item.row[6].value),
        shippingType: item.row[7].value as string,
        PointOfSaleId: Number(item.row[9].value),
        checkStock,
        active,
        scheduled,
      });
      if (rateCount >= 1) {
        deliveryRatesExists.push(item.row[0].value as string);
      }
      const itemsInfo = {
        price: item.row[1].value === '' ? null : Number(item.row[1].value),
        activateAt: Number(item.row[2].value),
        daysDelay: Number(item.row[13].value),
        title: item.row[3].value as string || null,
        subtitle: item.row[4].value as string || null,
        CourierId,
        LocalId: Number(item.row[5].value),
        CommuneId: Number(item.row[6].value),
        shippingType: item.row[7].value as string,
        PointOfSaleId: Number(item.row[9].value),
        checkStock,
        active,
        scheduled,
      };
      bodyData.push(itemsInfo);
    });
    const repeatedDeliveries: number[] = [];
    deliveryRatesToAdd.forEach((rate) => {
      // Check if there are duplicated
      const currentRateCount = verifyDeliveryRate(
        deliveryRatesToAdd,
        rate.activateAt,
        rate.daysDelay,
        rate.LocalId,
        rate.CommuneId,
        rate.shippingType,
        rate.CourierId,
        rate.PointOfSaleId,
        rate.checkStock as boolean,
        rate.scheduled as boolean,
      );
      if (currentRateCount > 1) {
        repeatedDeliveries.push((rate.id as number));
      }
    });
    // Check if all required info is given
    if (repeatedDeliveries.length > 0) {
      setLoading(false);
      const message = repeatedDeliveries.join(', ');
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle1'));
      setModalAlertText(translate('Modal.errorText1', { message }));
    } else if (!correctShippingType) {
      setLoading(false);
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle2'));
      setModalAlertText(translate('Modal.errorText2', { shippingTypes: shippingTypes.join(', ') }));
    } else if (itemsMissingInfo.length > 0) {
      setLoading(false);
      const missingData = itemsMissingInfo.join(', ');
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle3'));
      setModalAlertText(translate('Modal.errorText3', { message: missingData }));
    } else if (deliveryRatesExists.length > 0) {
      setLoading(false);
      const alreadyExists = deliveryRatesExists.join(', ');
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle4'));
      setModalAlertText(translate('Modal.errorText4', { message: alreadyExists }));
    } else {
      // Upload data to s3
      const id = uuid();
      const fileToUpload = JSON.stringify(bodyData);
      const res = uploadFileToS3(
        fileToUpload,
        `massive/input/${id}`,
      );
      if (res.includes('Error')) {
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorUploadingCsvTitle'));
        setModalAlertText(translate('Modal.errorUploadingCsvText', { error: res }));
      } else {
        const body = {
          queue: 'models/deliveryRates',
          event: 'create',
          clientId: user.connectableId,
          pathName: 'api/delivery-rates',
          processedItemsIds: [],
          cursor: 0,
          lambdasAlreadyProcessed: 0,
          fileName: id,
        };
        try {
          taskId = await apiFunctions.postEnqueueTask(body);
          if (taskId === null) {
            setLoading(false);
            setOpenModalAlert(true);
            setModalAlertTitle(translate('Modal.errorTitle5'));
            setModalAlertText(translate('Modal.errorText5'));
          } else {
            setLoading(false);
            getTaskResponse();
            setOpenModalAlert(true);
            setModalAlertTitle(translate('Modal.sentTitle'));
            setModalAlertText(translate('Modal.sentText'));
          }
        } catch (error) {
          setLoading(false);
          setOpenModalAlert(true);
          setModalAlertTitle(translate('Modal.errorTitle5'));
          setModalAlertText(translate('Modal.errorText5'));
          setDisabledSubmitBtn(false);
        }
      }
    }
    setDisabledSubmitBtn(false);
  }
  useEffect(() => {
    const defineColumns: IColumnData[] = [];
    defineColumns.push({ title: translate('Columns.number'), type: 'number', editable: false });
    tableColumns.forEach((columnName: string) => {
      if (columnName === translate('Columns.title') || columnName === translate('Columns.subtitle')
      || columnName === translate('Columns.shippingType')) {
        defineColumns.push({ title: columnName, type: 'text', editable: true });
      } else if (columnName === translate('Columns.checkStock') || columnName === translate('Columns.active')
      || columnName === translate('Columns.scheduled')) {
        defineColumns.push({ title: columnName, type: 'checkbox', editable: true });
      } else {
        defineColumns.push({ title: columnName, type: 'number', editable: true });
      }
    });
    setColumns(defineColumns);
    const list: IRowData[] = [];
    rowsValues.forEach((data: unknown, idx: number) => {
      const cellData: ICellData[] = [];
      cellData.push({ index: 0, value: idx + 1, isDisable: false });
      (data as string[]).forEach((value: string, index: number) => {
        if (index + 1 === 10 || index + 1 === 11 || index + 1 === 12) {
          if ((value).toLowerCase().includes(translate('yes')) || (value).toLowerCase().includes(translate('yesAlt'))) {
            cellData.push({ index: index + 1, value: translate('yes'), isDisable: false });
          } else {
            cellData.push({ index: index + 1, value: translate('no'), isDisable: false });
          }
        } else {
          cellData.push({ index: index + 1, value, isDisable: false });
        }
      });
      const rowData: IRowData = { index: idx, row: cellData };
      list.push(rowData);
    });
    setDataToShow(list);
  }, [tableColumns, rowsValues]);

  return (
    <div className="PageContainer">
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      {loading ? (
        <LoadingPage />
      ) : (
        <div className="FormMainContainer">
          <GoBackButton
            goBackPath="/home/alldeliveryrates"
          />
          <form
            className="Form"
            onSubmit={submitCsv}
          >
            <div className="FormTitle">
              {translate('title')}
            </div>
            <Divider />
            <div className="FormContainer">
              <div className="FormLabel">
                {translate('loadCSV')}
              </div>
              <div className="FileUploadItemsContainer">
                {/* File Uploader */}
                <DropFile
                  changeHandler={
                    (file: File) => uploadCsvData(
                      file,
                      columnsShouldHave,
                      setOpenModalAlert,
                      setModalAlertTitle,
                      setModalAlertText,
                      setTableColumns,
                      setRowsValues,
                      translate,
                    )
                  }
                  createItemsSubmitted={createItemsSubmitted}
                  setCreateItemsSubmitted={setCreateItemsSubmitted}
                />
                <div className="UploadItemsText">
                  {translate('download1')}
                  <div
                    onClick={() => downloadFileFromS3(
                      'Planillas/Ejemplo planilla creación tarifas.csv',
                      'text/csv;charset=utf-8',
                    )}
                    className="UploadItemsTemplate"
                  >
                    {translate('download2')}
                  </div>
                  {translate('download3')}
                </div>
              </div>
            </div>
            <Divider />
            {dataToShow.length > 0 ? (
              <>
                <div className="FormContainerItems">
                  <NavigableTable
                    dataToShow={dataToShow}
                    setDataToShow={setDataToShow}
                    columns={columns}
                    loading={false}
                  />
                </div>
                <div className="FormFooter">
                  <button
                    className="GoBackButton"
                    type="submit"
                    disabled={disabledSubmitBtn}
                  >
                    {translate('createRates')}
                  </button>
                </div>
              </>
            ) : (null)}
          </form>
        </div>
      )}
    </div>
  );
}
