import axios, { AxiosError, AxiosInstance } from 'axios';
import {
  APIResourceOptions, IPointOfSaleProduct, IUser,
} from '../interfaces';
import IProduct from '../interfaces/IProduct';
import IReception, { IReceptionHistory } from '../interfaces/IReception';
import ILocal from '../interfaces/ILocal';
import ICommune from '../interfaces/ICommune';
import IDeliveryRate from '../interfaces/IDeliveryRate';
import ICourier from '../interfaces/ICourier';
import IProductSize from '../interfaces/IProductSize';
import IPointOfSale from '../interfaces/IPointOfSale';
import IClient from '../interfaces/IClient';
import IAvailableRates from '../interfaces/IAvailableRates';
import IAvailableRatesRequestBody from '../interfaces/IAvailableRatesRequestBody';
import { IOrder } from '../interfaces/IOrder';
import IProductStock from '../interfaces/IProductStock';
import {
  getProductPacks, getProductPacksVariables, getProducts, getProductsVariables, getSizes, getSizesVariables,
  getCountries, getCountriesVariables, getDeliveryRates, getDeliveryRatesVariables, getProductsWithStock,
  getProductsWithStockVariables, getOrders, getOrdersVariables, getClients, getClientsVariables, getOrderTypes,
  getOrderTypesVariables,
} from '../graphql';
import IPaymentOrder from '../interfaces/IPaymentOrder';
import { ITracking } from '../interfaces/ITracking';
import { ICountry } from '../interfaces/ICountry';
import { IChargeRate } from '../interfaces/IChargeRate';
import { IOrderType } from '../interfaces/IOrderType';

export class Api {
  axios: AxiosInstance;

  graphql: AxiosInstance;

  constructor(token: string) {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    this.graphql = axios.create({
      baseURL: process.env.REACT_APP_GRAPHQL_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async getUser(): Promise<IUser | string> {
    try {
      const response = await this.axios.get<IUser>('/api/account');
      const user = response.data;
      return user;
    } catch (error) {
      if (error instanceof AxiosError) {
        return error.response?.data.error as string;
      }
      return 'Unexpected error';
    }
  }

  async getClientProducts(
    {
      filter = {},
      rowsPerPage = -1,
      page = 0,
    }: APIResourceOptions,
  ): Promise<{ products: IProduct[], count: number }> {
    const query = getProducts(rowsPerPage < 0 ? null : rowsPerPage, rowsPerPage < 0 ? 0 : (rowsPerPage * page));
    const variables = getProductsVariables({ filter });
    const response = await this.graphql.post('/graphql', {
      query,
      variables,
    });
    const products = response.data.data.Products;
    const { count } = response.data.data.Products_aggregate.aggregate;
    return { products, count };
  }

  async createReception(reception: Partial<IReception>): Promise<void> {
    try {
      await this.axios.post('/api/receptions', reception);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Error sending request: ${error.response?.data?.message || error.message}`);
      }
    }
  }

  async getLocalIdByName(name: string): Promise<number> {
    const response = await this.axios.get<ILocal[]>('/api/locals', {
      params: {
        filter: JSON.stringify({ name, active: true }),
      },
    });
    if (response.data.length !== 1) {
      throw new Error(`Error getting local: ${name}. It was not found.`);
    }
    const local = response.data[0];
    return local.id;
  }

  async getLocals({ filter = {} } = {}): Promise<{ locals: ILocal[]; count: number }> {
    const response = await this.axios.get<ILocal[]>('/api/locals', {
      params: {
        filter,
      },
    });
    const locals = response.data;
    const count = parseInt(response.headers['content-range'].split('/')[1], 10);
    return { locals, count };
  }

  async getCommunes(): Promise<ICommune[]> {
    const response = await this.axios.get<ICommune[]>('/api/communes', {
      params: {
        sort: JSON.stringify(['name']),
        range: '[0, 8500]',
      },
    });
    return response.data;
  }

  async getCommuneByName(name: string): Promise<ICommune> {
    const response = await this.axios.get<ICommune[]>('/api/communes', {
      params: {
        filter: JSON.stringify({ name }),
        range: '[0, 500]',
      },
    });
    const { data } = response;
    return data[0];
  }

  async getReceptionsData(clientId: number): Promise<IReceptionHistory[]> {
    const response = await this.axios.get<IReceptionHistory[]>('/api/receptions', {
      params: {
        filter: JSON.stringify({ ClientId: clientId }),
        sort: JSON.stringify(['id', 'DESC']),
        range: '[0, 500]',
      },
    });
    const receptions = response.data;
    const { locals } = await this.getLocals();
    receptions.forEach((reception) => {
      const obj = locals.find((o) => o.id === reception.LocalId);
      reception.localName = obj?.name;
    });
    return receptions;
  }

  async getDeliveryRates(
    {
      filter = {},
      rowsPerPage = -1,
      page = 0,
    }: APIResourceOptions,
  ): Promise<{ deliveryRates: IDeliveryRate[], count: number }> {
    const query = getDeliveryRates(rowsPerPage < 0 ? null : rowsPerPage, rowsPerPage < 0 ? 0 : (rowsPerPage * page));
    const variables = getDeliveryRatesVariables({ filter });
    const response = await this.graphql.post('/graphql', {
      query,
      variables,
    });
    if (response.data.data) {
      const deliveryRates = response.data.data.DeliveryRates;
      const { count } = response.data.data.DeliveryRates_aggregate.aggregate;
      return { deliveryRates, count };
    }
    return { deliveryRates: [], count: 0 };
  }

  async getCouriers(): Promise<ICourier[]> {
    const response = await this.axios.get<ICourier[]>('/api/couriers', {
      params: {
        range: '[0, 500]',
      },
    });
    const { data } = response;
    return data;
  }

  async createDeliveryRate(deliveryRate: Partial<IDeliveryRate>): Promise<void> {
    try {
      await this.axios.post('/api/delivery-rates', deliveryRate);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Error enviando solicitud:${error.response?.data}`);
      }
    }
  }

  async createProduct(product: Partial<IProduct>): Promise<void> {
    try {
      await this.axios.post('/api/products', product);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Error enviando solicitud:${error.response?.data}`);
      }
    }
  }

  async getProductSizes({
    filter = {},
  }: APIResourceOptions): Promise<IProductSize[]> {
    const query = getSizes(null, 0);
    const variables = getSizesVariables({ filter });
    const response = await this.graphql.post('/graphql', {
      query,
      variables,
    });
    const sizes = response.data.data.Sizes;
    return sizes;
  }

  async getClient(clientId: number): Promise<IClient> {
    const response = await this.axios.get(`/api/clients/${clientId}`);
    return response.data;
  }

  async getClients({
    page,
    rowsPerPage,
    sort = [],
  }: APIResourceOptions): Promise<{ clients: Partial<IClient>[]; count: number }> {
    const query = getClients(rowsPerPage || null, (Number(rowsPerPage) * Number(page)) || null);
    const variables = getClientsVariables({ sort });
    const response = await this.graphql.post<{
      data: {
        Clients: Partial<IClient>[];
        Clients_aggregate: { aggregate: { total: number; } };
      }
    }>('/graphql', {
      query,
      variables,
    });
    const clients = response.data.data.Clients;
    const count = response.data.data.Clients_aggregate.aggregate.total;
    return { clients, count };
  }

  async getClientPOS(clientId: number): Promise<IPointOfSale[]> {
    const response = await this.axios.get<IPointOfSale[]>('/api/pos', {
      params: {
        filter: JSON.stringify({ ClientId: clientId }),
      },
    });
    const pos = response.data;
    return pos;
  }

  async getClientPOSIntegration(clientId: number, integrationName: string): Promise<IPointOfSale> {
    const response = await this.axios.get<IPointOfSale[]>('/api/pos', {
      params: {
        filter: JSON.stringify({ ClientId: clientId }),
      },
    });
    const pos = response.data.filter((pointOfSale) => pointOfSale.name.includes(integrationName));
    return pos[0];
  }

  async getOrderTypes({
    filter = {},
    rowsPerPage = 100,
    page = 0,
  }: APIResourceOptions): Promise<IOrderType[]> {
    const query = getOrderTypes(rowsPerPage, rowsPerPage * page);
    const variables = getOrderTypesVariables({ filter });
    const response = await this.graphql.post('/graphql', {
      query,
      variables,
    });
    const orderTypes = response.data.data.OrderTypes;
    return orderTypes;
  }

  async getAvailableDeliveryRates(request: IAvailableRatesRequestBody): Promise<IAvailableRates[]> {
    const response = await this.axios.post('/api/delivery-rates/available', request);
    const availableRates: IAvailableRates[] = response.data.rates;
    return availableRates;
  }

  async createOrder(order: Partial<IOrder>): Promise<IOrder | null> {
    try {
      const response = await this.axios.post('/api/orders', order);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Error sending request: ${error.response?.data?.message || error.message}`);
      }
    }
    return null;
  }

  async getOrders({
    filter = {},
    sort = [],
    rowsPerPage = 100,
    page = 0,
  }: APIResourceOptions): Promise<{ orders: IOrder[], count: number }> {
    const query = getOrders(rowsPerPage, rowsPerPage * page);
    const variables = getOrdersVariables({ filter, sort });
    const response = await this.graphql.post('/graphql', {
      query,
      variables,
    });
    const orders = response.data.data.Orders;
    const count = response.data.data.Orders_aggregate.aggregate.total;
    return { orders, count };
  }

  async getProductStock(productId: number): Promise<IProductStock[]> {
    const response = await this.axios.get(`/api/products/${productId}/stock`);
    return response.data;
  }

  async getProductPacks({
    filter = {},
    rowsPerPage = 100,
    page = 0,
  }: APIResourceOptions): Promise<{ products: IProduct[], count: number }> {
    const query = getProductPacks(rowsPerPage, rowsPerPage * page);
    const variables = getProductPacksVariables({ filter });
    const response = await this.graphql.post('/graphql', {
      query,
      variables,
    });
    const products = response.data.data.Products;
    const { count } = response.data.data.Products_aggregate.aggregate;
    return { products, count };
  }

  async getPaymentOrders({
    rowsPerPage = 10,
    cursor,
    filter,
  }: APIResourceOptions): Promise<{ paymentOrders: IPaymentOrder[], count: number, cursor: string }> {
    const response = await this.axios.get('/billing/payments', {
      params: {
        limit: rowsPerPage,
        cursor,
        filter,
      },
    });
    return {
      paymentOrders: response.data.items,
      count: response.data.count,
      cursor: response.data.cursor,
    };
  }

  async updatePaymentOrder(paymentOrder: Partial<IPaymentOrder>, documents: string[], status: string): Promise<void> {
    try {
      await this.axios.put(`/billing/payments/${paymentOrder.id}`, {
        documents,
        status,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Error sending request: ${error.response?.data?.message || error.message}`);
      }
    }
  }

  async getOrderTracking(orderCode: string, signature: string): Promise<ITracking> {
    const url = `/tracking/${orderCode}`;
    const response = await this.axios.get(url, {
      params: { signature },
    });
    const tracking = response.data;
    return tracking;
  }

  async getTrackingSignature(orderCode: string): Promise<string> {
    const response = await this.axios.get(`/tracking/verify/${orderCode}`);
    const { signature } = response.data;
    return signature;
  }

  async getCountries({ filter = {} }): Promise<ICountry[]> {
    try {
      const query = getCountries();
      const variables = getCountriesVariables({ filter });
      const response = await this.graphql.post('/graphql', {
        query,
        variables,
      });
      return response.data.data.Countries;
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log(error.response?.data.message);
      }
      return [];
    }
  }

  async getClientChargeRates(clientId: number): Promise<IChargeRate[]> {
    const response = await this.axios.get<IChargeRate[]>('/api/charge-rates', {
      params: {
        filter: JSON.stringify({ ClientId: clientId }),
      },
    });
    const chargeRates = response.data;
    return chargeRates;
  }

  async getProductsWithStock(
    {
      filter = {},
      rowsPerPage = -1,
      page = 0,
    }: APIResourceOptions,
    localId: number,
  ): Promise<IPointOfSaleProduct[]> {
    const query = getProductsWithStock(
      rowsPerPage < 0 ? null : rowsPerPage,
      rowsPerPage < 0 ? 0 : (rowsPerPage * page),
      localId,
    );
    const variables = getProductsWithStockVariables({ filter });
    const response = await this.graphql.post('/graphql', {
      query,
      variables,
    });
    const products = response.data.data.PointOfSaleProducts.map(
      (pointOfSaleProduct: {
        Product: IPointOfSaleProduct,
      }) => {
        const product = pointOfSaleProduct.Product;
        return product;
      },
    );
    return products;
  }
}
