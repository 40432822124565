import {
  useEffect, useState, useContext, useMemo,
} from 'react';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import { GlobalContext } from '../../../store';
import ProductSearch from '../../../components/HomeComponents/ProductSearch';
import { IRowData, IColumnData } from '../../../interfaces/INavigableTableObjects';
import NavigableTable from '../../../NavigableTable/NavigableTable';
import { useTranslate } from '../../../hooks';
import { IOrder } from '../../../interfaces/IOrder';
import { IPointOfSaleProduct } from '../../../interfaces';
import IProduct from '../../../interfaces/IProduct';
import AlertDialog from '../../../components/HomeComponents/AlertDialog';

interface Props {
  goNextPage: () => void;
  order: Partial<IOrder>;
  orderDetails: IRowData[];
  goPreviousPage: () => void;
  setOrderDetails: React.Dispatch<React.SetStateAction<IRowData[]>>;
}

export default function Products(props: Props) {
  const {
    goNextPage, order, orderDetails, setOrderDetails, goPreviousPage,
  } = props;
  const translate = useTranslate('Page.Withdrawals');
  const { api } = useContext(GlobalContext);
  const [products, setProducts] = useState<IPointOfSaleProduct[]>([]);
  const [productToAdd, setProductToAdd] = useState<IPointOfSaleProduct>();
  const [loading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [notEnoughStockString, setNotEnoughStockString] = useState<string>('');
  const search = useMemo<{ name: string, rawSku: string }>(() => {
    const searchString = searchValue.split('||');
    let newSearch = {
      name: searchString[1] || '',
      rawSku: searchString[0].split('stock')[0],
    };
    if (searchString.length === 1) {
      newSearch = {
        name: searchString[0],
        rawSku: searchString[0].split('stock')[0],
      };
    }
    return newSearch;
  }, []);
  // define data of columns
  const columns: IColumnData[] = [
    { title: translate('Columns.products'), type: 'text', editable: false },
    { title: translate('Columns.quantity'), type: 'number', editable: true },
    { title: '', type: 'delete', editable: true },
  ];

  function handleGoNextPage() {
    order.OrderDetails = [];
    const notEnoughStock: string[] = [];
    orderDetails.forEach((dataRow: IRowData) => {
      const product = products.find((obj) => obj.id === dataRow.index);
      const productStock = product?.Stocks[0].available || 0;
      if (productStock < (dataRow.row[1].value as number) && product) {
        notEnoughStock.push(product?.name);
      } else {
        order.OrderDetails?.push({
          ProductId: dataRow.index,
          amount: (dataRow.row[1].value as number),
        });
      }
      if (notEnoughStock.length > 0) {
        setNotEnoughStockString(notEnoughStock.join(', '));
        setOpenModalAlert(true);
      } else {
        goNextPage();
      }
    });
  }

  function addProductToOrder(product: IPointOfSaleProduct | IProduct) {
    const isAlreadyAdded = orderDetails.find((obj) => obj.index === product.id);
    if (!isAlreadyAdded) {
      setProductToAdd(product as IPointOfSaleProduct);
    }
  }

  async function fetchProducts() {
    if (order.LocalId && order.PointOfSaleId) {
      const filter = { ...search, PointOfSaleId: order.PointOfSaleId };
      const response = await api.getProductsWithStock({ filter }, order.LocalId);
      setProducts(response);
    }
  }

  useEffect(() => {
    (async () => {
      await fetchProducts();
    })();
  }, [search]);

  useEffect(() => {
    if (productToAdd) {
      const cellData = [
        {
          index: 0,
          value: `${productToAdd?.rawSku}||${productToAdd?.name} (stock: ${productToAdd?.Stocks
            ? productToAdd?.Stocks[0].available : '0'})`,
          isDisable: false,
        },
        { index: 1, value: 1, isDisable: false },
        { index: 2, value: translate('ProductStep.delete'), isDisable: false },
      ];
      const rowData: IRowData = { index: productToAdd.id, row: cellData };
      setOrderDetails([...orderDetails, rowData]);
    }
  }, [productToAdd]);
  return (
    <>
      <div className="PageSubtitleContainer">
        {translate('ProductStep.subtitle')}
      </div>
      <AlertDialog
        title={translate('noStockTitle')}
        text={translate('noStockText', { products: notEnoughStockString })}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      <div className="SearchProductContainer">
        <p>{translate('product')}</p>
        <ProductSearch
          onUpdateSearch={(newValue: string) => setSearchValue(newValue)}
          allProducts={products}
          type="product"
          addProductToOrder={addProductToOrder}
          showStock
        />
      </div>
      {orderDetails.length === 0 && (
        <p className="Placeholder">
          {translate('ProductStep.placeholder')}
        </p>
      )}
      {orderDetails.length > 0 && (
        <>
          <div className="AddedProductsTable">
            <NavigableTable
              dataToShow={orderDetails}
              setDataToShow={setOrderDetails}
              columns={columns}
              loading={loading}
              setOrderDetails={setOrderDetails}
            />
          </div>
          <div className="BottomRow">
            <div
              className="GoBackOrderPage"
              onClick={goPreviousPage}
            >
              <LeftOutlined className="RightArrow" />
              <div>
                {translate('ProductStep.goBackButton')}
              </div>
            </div>
            <div
              className="Button Primary"
              onClick={() => handleGoNextPage()}
            >
              {translate('ProductStep.submit')}
            </div>
          </div>
        </>
      )}
    </>
  );
}
