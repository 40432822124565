import {
  useEffect, useState, useContext,
} from 'react';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import { useTranslate } from '../../../hooks';
import SelectInput from '../../../components/HomeComponents/SelectInputName';
import { IOrder } from '../../../interfaces/IOrder';
import ICommune from '../../../interfaces/ICommune';
import { GlobalContext } from '../../../store';
import { IRegion } from '../../../interfaces/IRegion';
import { ICountry } from '../../../interfaces/ICountry';
import Loading from '../../../components/LoadingComponent';

interface Props {
  goNextPage: () => void;
  goPreviousPage: () => void;
  order: Partial<IOrder>;
  setOrder: React.Dispatch<React.SetStateAction<Partial<IOrder>>>;
}

export default function Address(props: Props) {
  const {
    order, setOrder, goNextPage, goPreviousPage,
  } = props;
  const translate = useTranslate('Page.Withdrawals');
  const { user, api } = useContext(GlobalContext);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [selectedCommune, setSelectedCommune] = useState<string>(order.shippingAddress?.commune as string);
  const [enteredAddress1, setEnteredAddress1] = useState<string>(order.shippingAddress?.address1 as string);
  const [enteredAddress2, setEnteredAddress2] = useState<string>(order.shippingAddress?.address2 as string);
  const [disable, setDisable] = useState<boolean>(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [regions, setRegions] = useState<IRegion[]>([]);
  const [communes, setCommunes] = useState<ICommune[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [prevSelectedAddress, setPrevSelectedAddress] = useState<{ [key: string]: string } | null>(null);

  function saveData() {
    const shippingAddress = {
      commune: selectedCommune,
      address1: enteredAddress1,
      address2: enteredAddress2,
    };
    setOrder({ ...order, shippingAddress });
    goNextPage();
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      // set countries
      const allCountries = await api.getCountries({ filter: { ClientId: user.connectableId } });
      setCountries(allCountries);
      if (allCountries.length > 0) {
        setSelectedCountry(allCountries[0].name);
        setRegions(allCountries[0].Regions);
        setSelectedRegion(allCountries[0].Regions[0].name);
        setCommunes(allCountries[0].Regions[0].Communes);
        if (order.shippingAddress?.commune) {
          // Find previously selected commune and set it
          for (let i = 0; i < allCountries.length; i += 1) {
            const checkCountry = allCountries[i];
            for (let j = 0; j < checkCountry.Regions.length; j += 1) {
              const checkRegion = checkCountry.Regions[j];
              for (let k = 0; k < checkRegion.Communes.length; k += 1) {
                const checkCommune = checkRegion.Communes[k];
                if (checkCommune?.name === order.shippingAddress.commune) {
                  setPrevSelectedAddress({
                    region: checkRegion.name,
                    commune: checkCommune.name,
                  });
                  setSelectedCountry(checkCountry.name);
                  setSelectedRegion(checkRegion.name);
                  setCommunes(checkRegion.Communes);
                  setSelectedCommune(checkCommune.name);
                }
              }
            }
          }
        }
      }
      setLoading(false);
    })();
  }, []);

  // Set regions when selected country
  useEffect(() => {
    if (countries.length > 0) {
      const currentCountry = countries.find((country) => country.name === selectedCountry);
      if (currentCountry) {
        setRegions(currentCountry.Regions);
        setSelectedRegion(prevSelectedAddress?.region
          ? prevSelectedAddress.region : currentCountry.Regions[0].name);
      }
    }
  }, [selectedCountry, prevSelectedAddress]);

  // Set communes when set regions
  useEffect(() => {
    if (regions.length > 0) {
      const currentRegion = regions.find((reg) => reg.name === selectedRegion);
      if (currentRegion) {
        setCommunes(currentRegion.Communes);
        setSelectedCommune(prevSelectedAddress?.commune ? prevSelectedAddress.commune : currentRegion.Communes[0].name);
      }
    }
  }, [selectedRegion, prevSelectedAddress]);

  // Verification required inputs
  useEffect(() => {
    if (selectedCommune.length > 0 && enteredAddress1.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [selectedCommune, enteredAddress1]);

  if (loading) {
    return (
      <>
        <div className="PageSubtitleContainer">
          {translate('AddressStep.subtitle')}
        </div>
        <Loading />
      </>
    );
  }

  return (
    <>
      <div className="PageSubtitleContainer">
        {translate('AddressStep.subtitle')}
      </div>
      <div className="DirectionStepContainer">
        <div className="DirectionLabel">{translate('AddressStep.country')}</div>
        <SelectInput
          styleWidth="380px"
          title=""
          array={countries}
          selectedOption={selectedCountry}
          SetSelectedOption={setSelectedCountry}
          lightStyle={false}
        />
        <div className="DirectionLabel">{translate('AddressStep.region')}</div>
        <SelectInput
          styleWidth="380px"
          title=""
          array={regions}
          selectedOption={selectedRegion}
          SetSelectedOption={setSelectedRegion}
          lightStyle={false}
        />
        <div className="DirectionLabel">{translate('AddressStep.commune')}</div>
        <SelectInput
          styleWidth="380px"
          title=""
          array={communes}
          selectedOption={selectedCommune}
          SetSelectedOption={setSelectedCommune}
          lightStyle={false}
        />
        <div className="DirectionLabel">{translate('AddressStep.address1')}</div>
        <input
          className="TextInput"
          style={{ width: '380px', height: '30px' }}
          type="text"
          value={enteredAddress1}
          onChange={(e) => setEnteredAddress1(e.target.value)}
        />
        <div className="DirectionLabel">{translate('AddressStep.address2')}</div>
        <input
          className="TextInput"
          style={{ width: '380px', height: '30px' }}
          type="text"
          defaultValue={enteredAddress2}
          onChange={(e) => setEnteredAddress2(e.target.value)}
        />
      </div>
      <div className="BottomRow">
        <div
          className="GoBackOrderPage"
          onClick={goPreviousPage}
        >
          <LeftOutlined className="RightArrow" />
          <div>
            {translate('AddressStep.goBackButton')}
          </div>
        </div>
        <div
          className={`Button Primary ${disable ? 'Disable' : ''}`}
          onClick={saveData}
        >
          {translate('AddressStep.goNextButton')}
        </div>
      </div>
    </>
  );
}
