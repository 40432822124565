import NotionPage from '../components/NotionPageComponent';
import { useTranslate } from '../hooks';

export default function Changes() {
  const translate = useTranslate('Page.Changes');

  const data = [
    {
      id: 'bf86bdfad3e24eb0a08b2af76a3e32ca',
      title: translate('priceChange'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/cambio-precio.jpg',
    },
    {
      id: '21f8d75d2dbe436a89782b07bf9a7fa5',
      title: translate('newProducts'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/nuevos-productos.jpg',
    }];
  return (
    <NotionPage pageData={data} />
  );
}
