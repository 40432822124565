import {
  useContext,
  SyntheticEvent,
  useState,
  useEffect,
} from 'react';
import { Divider } from '@mui/material';
import 'react-notion/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css';
import { NumericFormat } from 'react-number-format';
import SelectInput from '../../components/HomeComponents/SelectInputName';
import { GlobalContext } from '../../store';
import AlertDialog from '../../components/HomeComponents/AlertDialog';
import LoadingPage from '../../components/LoadingPageComponent';
import GoBackButton from '../../components/HomeComponents/GoBackButton';
import IProduct from '../../interfaces/IProduct';
import IProductSize from '../../interfaces/IProductSize';
import { useTranslate } from '../../hooks';
import searchSize from '../../helpers/searchSize';

export default function UnitaryCreateProducts() {
  const translate = useTranslate('Page.UnitaryCreateProducts');
  const [loading, setLoading] = useState<boolean>(false);
  const { api, user, client } = useContext(GlobalContext);
  const [productSku, setProductSku] = useState<string>('');
  const [productName, setProductName] = useState<string>('');
  const [productBarcode, setProductBarcode] = useState<string>('');
  const [productIsPack, setProductIsPack] = useState<string>(translate('no'));
  const [productIsWeighted, setProductIsWeighted] = useState<string>(translate('no'));
  const [productAgeVerification, setAgeVerification] = useState<string>(translate('no'));
  const [productLotTracking, setProductLotTracking] = useState<string>(translate('no'));
  const [productPrice, setProductPrice] = useState<number>(0);
  const [productSafetyStock, setProductSafetyStock] = useState<number>(0);
  const [productExpirationAlert, setProductExpirationAlert] = useState<number>(30);
  const [productFamilySku, setProductFamilySku] = useState<string>('');
  const [productImageUrl, setProductImageUrl] = useState<string>('');
  const [productSize, setProductSize] = useState<string>('');
  const [productStorageType, setProductStorageType] = useState<string>('');
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [productSizes, setProductSizes] = useState<IProductSize[]>([]);

  const booleanOptions = [
    { name: translate('yes') },
    { name: translate('no') },
  ];
  const storageTypeOptions = [
    { name: translate('StorageTypeOptions.dry') },
    { name: translate('StorageTypeOptions.airConditioned') },
    { name: translate('StorageTypeOptions.refrigerated') },
    { name: translate('StorageTypeOptions.frozen') },
  ];

  // function to clean all inputs after success in submitted form
  function cleanAllInputs() {
    setProductSku('');
    setProductName('');
    setProductBarcode('');
    setProductIsPack(translate('no'));
    setProductIsWeighted(translate('no'));
    setAgeVerification(translate('no'));
    setProductLotTracking(translate('no'));
    setProductPrice(0);
    setProductSafetyStock(0);
    setProductExpirationAlert(30);
    setProductFamilySku('');
    setProductImageUrl('');
    setProductSize('');
    setProductStorageType('');
  }
  // function to submit form
  async function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();
    setDisabledSubmitBtn(true);
    setLoading(true);
    if (productSku === '' || productName === '' || productSize === ''
    || productStorageType === '') {
      setLoading(false);
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle1'));
      setModalAlertText(translate('Modal.errorText1'));
    } else {
      const result = await api.getClientProducts({});
      const allProducts = result.products;
      const createdProductsRawSku = allProducts.map((product: IProduct) => product.rawSku);
      if (createdProductsRawSku.includes(productSku)) {
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorTitle2'));
        setModalAlertText(translate('Modal.errorText2', { productSku }));
      } else {
        const dataHash: { [key: string]: IProductSize } = {};
        const productSizeIndex = await searchSize(dataHash, productSize, api);
        const body = {
          sku: `${client.prefix}-${productSku}`,
          rawSku: productSku,
          name: productName,
          barcode: productBarcode,
          isPack: productIsPack === translate('yes'),
          isWeighted: productIsWeighted === translate('yes'),
          familySku: productFamilySku,
          pvp: productPrice,
          imageUrl: productImageUrl,
          ClientId: user.connectableId,
          SizeId: productSizeIndex,
          storageType: productStorageType,
          safetyStock: productSafetyStock,
          expirationAlert: productExpirationAlert,
          ageVerification: productAgeVerification === translate('yes'),
          needsLotTracking: productLotTracking === translate('yes'),
        };
        try {
          // Post delivery rate
          await api.createProduct(body);
          setLoading(false);
          cleanAllInputs();
          setOpenModalAlert(true);
          setModalAlertTitle(translate('Modal.successTitle'));
          setModalAlertText(translate('Modal.successText'));
        } catch (error) {
          setLoading(false);
          setOpenModalAlert(true);
          setModalAlertTitle(translate('Modal.errorTitle3'));
          setModalAlertText(translate('Modal.errorText3'));
        }
      }
    }
    setDisabledSubmitBtn(false);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      // set sizes of products to show in form
      const allSizes = await api.getProductSizes({});
      setProductSizes(allSizes);
      setLoading(false);
    })();
  }, []);
  return (
    <div className="PageContainer">
      {loading ? (
        <LoadingPage />
      ) : (null)}
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      <div className="FormMainContainer">
        <GoBackButton
          goBackPath="/home/allproducts"
        />
        <form
          className="Form"
          onSubmit={handleSubmit}
        >
          <div className="FormTitle">
            {translate('title')}
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('sku')}
            </div>
            <input
              className="TextInput"
              type="text"
              value={productSku}
              onChange={(e) => setProductSku(e.target.value)}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('name')}
            </div>
            <input
              className="TextInput"
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('barcode')}
            </div>
            <input
              className="TextInput"
              type="text"
              value={productBarcode}
              onChange={(e) => setProductBarcode(e.target.value)}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('isPack')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={booleanOptions}
              selectedOption={productIsPack}
              SetSelectedOption={setProductIsPack}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('isWeighted')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={booleanOptions}
              selectedOption={productIsWeighted}
              SetSelectedOption={setProductIsWeighted}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('familySku')}
            </div>
            <input
              className="TextInput"
              type="text"
              value={productFamilySku}
              onChange={(e) => setProductFamilySku(e.target.value)}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('price')}
            </div>
            <NumericFormat
              className="TextInput"
              value={productPrice}
              allowNegative={false}
              decimalSeparator=","
              thousandSeparator="."
              onValueChange={(values) => setProductPrice(Number(values.value))}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('safetyStock')}
            </div>
            <NumericFormat
              className="TextInput"
              value={productSafetyStock}
              allowNegative={false}
              decimalSeparator=","
              thousandSeparator="."
              onValueChange={(values) => setProductSafetyStock(Number(values.value))}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('size')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={productSizes}
              selectedOption={productSize}
              SetSelectedOption={setProductSize}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('storageType')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={storageTypeOptions}
              selectedOption={productStorageType}
              SetSelectedOption={setProductStorageType}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('imageUrl')}
            </div>
            <input
              className="TextInput"
              type="text"
              value={productImageUrl}
              onChange={(e) => setProductImageUrl(e.target.value)}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('ageVerification')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={booleanOptions}
              selectedOption={productAgeVerification}
              SetSelectedOption={setAgeVerification}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('lotTracking')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={booleanOptions}
              selectedOption={productLotTracking}
              SetSelectedOption={setProductLotTracking}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('expirationAlert')}
            </div>
            <NumericFormat
              className="TextInput"
              value={productExpirationAlert}
              allowNegative={false}
              decimalSeparator=","
              thousandSeparator="."
              onValueChange={(values) => setProductExpirationAlert(Number(values.value))}
            />
          </div>
          <div className="FormFooter">
            <button
              className="GoBackButton"
              type="submit"
              disabled={disabledSubmitBtn}
            >
              {translate('createRate')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
