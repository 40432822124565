function joinLocalsFilters(selectedLocals: number[], localFilterTitle: string) {
  let joinedString;
  if (selectedLocals.length === 1) {
    joinedString = `${localFilterTitle}=${selectedLocals[0]}`;
  } else {
    joinedString = selectedLocals.reduce((result, current, index) => {
      if (index === selectedLocals.length - 1) {
        return `${result}${localFilterTitle}=${current}`;
      }
      return `${result}${localFilterTitle}=${current}&`;
    }, '');
  }
  return joinedString;
}

export default joinLocalsFilters;
