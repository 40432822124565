import { useEffect, useState } from 'react';
import { NotionRenderer } from 'react-notion';
import Loading from './LoadingComponent';
// notion-css-imports
import 'react-notion/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css';
import { useTranslate } from '../hooks';

interface Props {
  pageData: {
    id: string,
    title: string,
    img: string
  }[];
}

function NotionPage(props: Props) {
  const { pageData } = props;
  const [notionData, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [notionIdPage, setNotionIdPage] = useState('');
  const translate = useTranslate('Component.NotionPageComponent');

  const renderSwitch = (param: string) => {
    switch (param) {
      case '':
        return (
          <div className="PageHeaderContainer">
            {
              pageData.map((data) => (
                <div
                  style={{ backgroundImage: `url(${data.img})` }}
                  className="PageHeader"
                  onClick={() => setNotionIdPage(data.id)}
                >
                  <div className="PageHeaderContent">
                    <p className="PageHeaderText">
                      {data.title}
                    </p>
                  </div>
                </div>
              ))
            }
          </div>
        );
      case 'premiseList':
        return (
          <div className="PageHeaderContainer">
            <div
              style={{ backgroundImage: 'url(https://clients-platform-assets.s3.amazonaws.com/img/mfc.jpg)' }}
              className="PageHeader"
              onClick={() => setNotionIdPage('f1a68a9ddfa4415f865f24935c866526')}
            >
              <div className="PageHeaderContent">
                <p className="PageHeaderText">
                  {translate('MFCLaReina')}
                </p>
              </div>
            </div>
            <div
              style={{ backgroundImage: 'url(https://clients-platform-assets.s3.amazonaws.com/img/mfc.jpg)' }}
              className="PageHeader"
              onClick={() => setNotionIdPage('a08991a1369e45d09c1e065988a56789')}
            >
              <div className="PageHeaderContent">
                <p className="PageHeaderText">
                  {translate('MFCChicureo')}
                </p>
              </div>
            </div>
            <div
              style={{ backgroundImage: 'url(https://clients-platform-assets.s3.amazonaws.com/img/mfc.jpg)' }}
              className="PageHeader"
              onClick={() => setNotionIdPage('2be47b4da9994eddbe77288613ebe8cb')}
            >
              <div className="PageHeaderContent">
                <p className="PageHeaderText">
                  {translate('MFCLoBarnechea')}
                </p>
              </div>
            </div>
            <div
              style={{ backgroundImage: 'url(https://clients-platform-assets.s3.amazonaws.com/img/mfc.jpg)' }}
              className="PageHeader"
              onClick={() => setNotionIdPage('78390feb78f74b3b8524d14b905053a6')}
            >
              <div className="PageHeaderContent">
                <p className="PageHeaderText">
                  {translate('MFCVinaDelMar')}
                </p>
              </div>
            </div>
            <div
              style={{ backgroundImage: 'url(https://clients-platform-assets.s3.amazonaws.com/img/mfc.jpg)' }}
              className="PageHeader"
              onClick={() => setNotionIdPage('862dea9e1ecd448bae8ad759fbf6743d')}
            >
              <div className="PageHeaderContent">
                <p className="PageHeaderText">
                  {translate('MFCMaipu')}
                </p>
              </div>
            </div>
          </div>
        );
      case 'pageWebList':
        return (
          <div className="PageHeaderContainer">
            <div
              style={{ backgroundImage: 'url(https://clients-platform-assets.s3.amazonaws.com/img/woocommerce.png)' }}
              className="PageHeader"
              onClick={() => setNotionIdPage('pagesWoocommerce')}
            >
              <div className="PageHeaderContent">
                <p className="PageHeaderText">
                  {translate('woocommerce')}
                </p>
              </div>
            </div>
            <div
              style={{ backgroundImage: 'url(https://clients-platform-assets.s3.amazonaws.com/img/shopify.jpeg)' }}
              className="PageHeader"
              onClick={() => setNotionIdPage('pagesShopify')}
            >
              <div className="PageHeaderContent">
                <p className="PageHeaderText">
                  {translate('shopify')}
                </p>
              </div>
            </div>
          </div>
        );
      case 'pagesWoocommerce':
        return (
          <div className="PageHeaderContainer">
            <div
              style={
                { backgroundImage: 'url(https://clients-platform-assets.s3.amazonaws.com/img/crear-pedido-manual.jpg)' }
              }
              className="PageHeader"
              onClick={() => setNotionIdPage('05b21ee99f8f4d619c88198d7afb3295')}
            >
              <div className="PageHeaderContent">
                <p className="PageHeaderText">
                  {translate('manualOrder')}
                </p>
              </div>
            </div>
          </div>
        );
      case 'pagesShopify':
        return (
          <div className="PageHeaderContainer">
            <div
              style={
                { backgroundImage: 'url(https://clients-platform-assets.s3.amazonaws.com/img/crear-pedido-manual.jpg)' }
              }
              className="PageHeader"
              onClick={() => setNotionIdPage('37757279a4cb497194057b1623d0fd8e')}
            >
              <div className="PageHeaderContent">
                <p className="PageHeaderText">
                  {translate('freeShipping')}
                </p>
              </div>
            </div>
            <div
              style={
                { backgroundImage: 'url(https://clients-platform-assets.s3.amazonaws.com/img/crear-pedido-manual.jpg)' }
              }
              className="PageHeader"
              onClick={() => setNotionIdPage('160b6c5d3d0846a2b63d2d81b6d17789')}
            >
              <div className="PageHeaderContent">
                <p className="PageHeaderText">
                  {translate('manualOrder')}
                </p>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="NotionRenderContainer">
            <div className="NotionRender">
              <NotionRenderer blockMap={notionData} />
            </div>
            <div className="GoBackButtonContainer">
              <button
                className="GoBackButton"
                style={{ color: 'black' }}
                onClick={() => setNotionIdPage('')}
                type="button"
              >
                <p className="NotionGoBackText">
                  {translate('goBack')}
                </p>
              </button>
            </div>
          </div>
        );
    }
  };

  useEffect(() => {
    // notion-api-worker
    if (notionIdPage !== 'premiseList'
    && notionIdPage !== 'pageWebList' && notionIdPage !== 'pagesWoocommerce' && notionIdPage !== 'pagesShopify') {
      setLoading(true);
      fetch(
        `https://notion-api.splitbee.io/v1/page/${notionIdPage}`,
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data as object);
          setLoading(false);
        })
        .catch((error) => console.log(error));
    }
  }, [notionIdPage]);

  return (
    <div className="PageContainer">
      {loading && (
      <Loading />
      )}
      {!loading && (
        renderSwitch(notionIdPage)
      )}
    </div>
  );
}

export default NotionPage;
