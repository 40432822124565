import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@ant-design/icons/DownOutlined';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

interface Props {
  iconComponent: React.ReactNode;
  title: string;
  anotherAccordionTabOpen: string;
  setAnotherAccordionTabOpen: React.Dispatch<React.SetStateAction<string>>;
  toggleAnotherTab: string;
  setToggleAnotherTab: React.Dispatch<React.SetStateAction<string>>;
  closeNavBar: React.Dispatch<React.SetStateAction<boolean>>;
  toggleTab: boolean;
  componentData: {
    subTitle: string;
    component: React.ReactNode;
    link: string;
    toggleTo: string;
  }[];
}
function Accordion(props: Props) {
  const {
    title, componentData, iconComponent, anotherAccordionTabOpen, setAnotherAccordionTabOpen,
    toggleTab, toggleAnotherTab, setToggleAnotherTab, closeNavBar,
  } = props;
  const { hide, update } = useIntercom();
  const [open, setOpen] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);

  const updateIntercomPosition = (movePosition: boolean) => {
    if (movePosition) {
      update({
        hideDefaultLauncher: true,
      });
    } else {
      update({
        hideDefaultLauncher: false,
      });
    }
  };
  const toggleHandler = () => {
    if (anotherAccordionTabOpen === '') {
      setOpen(!open);
      setAnotherAccordionTabOpen(title);
    } else if (anotherAccordionTabOpen === title) {
      setOpen(!open);
      setAnotherAccordionTabOpen('');
    } else {
      setToggleAnotherTab(anotherAccordionTabOpen);
      setAnotherAccordionTabOpen(title);
      setOpen(!open);
    }
  };
  function toggleIt(str: string) {
    hide();
    setClick(!click);
    if (
      str === 'Stocks'
      || str === 'SalesReport'
      || str === 'OrderReport'
      || str === 'ReceptionFormHistory'
      || str === 'AllProducts'
      || str === 'AllDeliveryRates'
      || str === 'OnTime'
      || str === 'AbandonedCarts'
    ) {
      updateIntercomPosition(true);
    } else {
      updateIntercomPosition(false);
    }
  }
  function handleClick(toggleTo: string) {
    toggleIt(toggleTo);
    closeNavBar(false);
  }
  useEffect(() => {
    if (toggleAnotherTab !== '') {
      setOpen(!open);
      setToggleAnotherTab('');
    }
  }, [toggleTab]);

  return (
    <>
      <div
        className="AccordionHeader"
        onClick={toggleHandler}
      >
        {iconComponent}
        <p className={open ? 'AccordionTitle open' : 'AccordionTitle'}>{title}</p>
        <ExpandMoreIcon className={open ? 'IconAnimation open' : 'IconAnimation'} />
      </div>
      <div className={open ? 'AccordionContent open' : 'AccordionContent'}>
        {
            componentData.map((data) => (
              <div className="IconContainer">
                <Link
                  className="IconLink"
                  to={data.link}
                  style={{ textDecoration: 'none' }}
                  onClick={() => handleClick(data.toggleTo)}
                >
                  {data.component}
                  <p className="IconText">
                    {data.subTitle}
                  </p>
                </Link>
              </div>
            ))
            }
      </div>
    </>
  );
}
export default Accordion;
