import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Divider } from '@mui/material';
import capitalizeFirstLetter from '../../helpers/capitalizeFirstLetter';
import { GlobalContext } from '../../store';
import IProduct from '../../interfaces/IProduct';
import NavigableTable from '../../NavigableTable/NavigableTable';
import { IColumnData, IRowData } from '../../interfaces/INavigableTableObjects';
import IReception, { IReceptionDetail } from '../../interfaces/IReception';
import textInputToNumber from '../../helpers/textInputToNumber';
import GoBackButton from '../../components/HomeComponents/GoBackButton';
import { useTranslate } from '../../hooks';

export default function ReceptionFormDetail() {
  const location = useLocation();
  const { document }: { document: IReception } = location.state;
  const { user, api } = useContext(GlobalContext);
  const [dataToShow, setDataToShow] = useState<IRowData[]>([]);
  // define columns of data
  const [columns, setColumns] = useState<IColumnData[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  const translate = useTranslate('Page.ReceptionFormDetail');

  useEffect(() => {
    (async () => {
      if (dataToShow.length === 0) {
        const result = await api.getClientProducts({});
        const clientProducts = result.products;
        if (clientProducts.length > 0) {
          setLoading(true);
          const productsName: { [key: number]: string } = {};
          clientProducts.forEach((product: IProduct) => {
            productsName[product.id] = `${product.sku}-${product.name}`;
          });
          const list: IRowData[] = [];
          if (document.status === 'closed') {
            const defineColumns: IColumnData[] = [
              { title: translate('Columns.products'), type: 'text', editable: false },
              { title: translate('Columns.quantitySent'), type: 'text', editable: false },
              { title: translate('Columns.quantityReceived'), type: 'text', editable: false },
              { title: translate('Columns.expirationDate'), type: 'text', editable: false },
            ];
            setColumns(defineColumns);
            document.ReceptionDetails.forEach((product: IReceptionDetail) => {
              const expDate = product.expirationDate ? (
                `${new Date(product.expirationDate).getUTCDate()}/`
                + `${new Date(product.expirationDate).getUTCMonth() + 1}/`
                + `${new Date(product.expirationDate).getUTCFullYear()}`
              ) : ('');
              const cellData = [
                { index: 0, value: productsName[product.ProductId], isDisable: false },
                { index: 1, value: `${product.amount}`, isDisable: false },
                { index: 2, value: `${product.received}`, isDisable: false },
                { index: 3, value: expDate, isDisable: false },
              ];
              const rowData: IRowData = { index: product.ProductId, row: cellData };
              list.push(rowData);
            });
          } else {
            const defineColumns: IColumnData[] = [
              { title: translate('Columns.products'), type: 'text', editable: false },
              { title: translate('Columns.quantitySent'), type: 'text', editable: false },
              { title: translate('Columns.expirationDate'), type: 'text', editable: false },
            ];
            setColumns(defineColumns);
            document.ReceptionDetails.forEach((product: IReceptionDetail) => {
              const expDate = product.expirationDate ? (
                `${new Date(product.expirationDate).getUTCDate()}/`
                + `${new Date(product.expirationDate).getUTCMonth() + 1}/`
                + `${new Date(product.expirationDate).getUTCFullYear()}`
              ) : ('');
              const cellData = [
                { index: 0, value: productsName[product.ProductId], isDisable: false },
                { index: 1, value: `${product.amount}`, isDisable: false },
                { index: 2, value: expDate, isDisable: false },
              ];
              const rowData: IRowData = { index: product.ProductId, row: cellData };
              list.push(rowData);
            });
          }
          setDataToShow(list);
          setLoading(false);
        }
      }
    })();
  }, [dataToShow, user.connectableId]);
  return (
    <div className="PageContainer">
      <div className="FormMainContainer">
        <GoBackButton
          goBackPath="/home/formshistory"
        />
        <div className="FormTitle">
          {translate('documentNumber', {
            number: textInputToNumber(document.documentNumber!.toString(), false),
          })}
        </div>
        <Divider />
        <div className="FormContainer">
          <div className="FormLabel">
            {translate('mfc')}
          </div>
          <div className="FormLabel">{capitalizeFirstLetter(document.localName as string)}</div>
        </div>
        <Divider />
        <div className="FormContainer">
          <div className="FormLabel">
            {translate('documentPdf')}
          </div>
          <div className="FormLabel ReceptionFormDownloadContainer">
            {document.documentUrl.map((url: string) => (
              <a
                style={{ textDecoration: 'none', color: 'rgba(229, 75, 14)' }}
                target="_blank"
                href={url}
                rel="noreferrer"
              >
                {translate('download')}
              </a>
            ))}
          </div>
        </div>
        <Divider />
        <div className="FormContainer">
          <div className="FormLabel">
            {translate('receptionDate')}
          </div>
          <div className="FormLabel">
            {new Date(document.date).getUTCDate()}
            /
            {new Date(document.date).getUTCMonth() + 1}
            /
            {new Date(document.date).getUTCFullYear()}
          </div>
        </div>
        <Divider />
        <div className="FormContainer">
          <div className="FormLabel">
            {translate('receptionState')}
          </div>
          {document.status === 'closed' ? (
            <div
              className="FormLabel"
              style={{ textDecoration: 'none', color: 'green' }}
            >
              {translate('receptioned')}
            </div>
          ) : (
            <div
              className="FormLabel"
              style={{ textDecoration: 'none', color: 'red' }}
            >
              {translate('toBeReceptioned')}
            </div>
          )}
        </div>
        <Divider />
        <div className="FormContainerProducts">
          <NavigableTable
            dataToShow={dataToShow}
            setDataToShow={setDataToShow}
            columns={columns}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
