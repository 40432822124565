import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TableRow from '@mui/material/TableRow';
import DownOutlined from '@ant-design/icons/DownOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import textInputToNumber from '../../helpers/textInputToNumber';
import IProduct from '../../interfaces/IProduct';
import capitalizeFirstLetter from '../../helpers/capitalizeFirstLetter';

interface ProductPacksTableRowProps {
  product: IProduct;
}

export default function ProductPacksTableRow({
  product,
}: ProductPacksTableRowProps) {
  // const translate = useTranslate('Component.ProductTable');
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow
        sx={{ cursor: 'pointer' }}
        hover
        tabIndex={-1}
        key={product.id}
      >
        <TableCell>
          <div onClick={() => setOpen(!open)}>
            {open ? <UpOutlined /> : <DownOutlined />}
          </div>
        </TableCell>
        <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>{product.rawSku}</TableCell>
        <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>{capitalizeFirstLetter(product.name)}</TableCell>
        <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
          {`$${textInputToNumber((product.pvp || 0).toString(), false)}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box margin={1}>
              <div>
                Componentes del pack
              </div>
              <Table
                size="small"
                aria-label="purchases"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Sku</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Cantidad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {product.Components?.map((pack: { amount: number, Component: IProduct }) => (
                    <TableRow key={pack.Component.id}>
                      <TableCell
                        component="th"
                        scope="row"
                      >
                        {pack.Component.rawSku}
                      </TableCell>
                      <TableCell>{pack.Component.name}</TableCell>
                      <TableCell>{pack.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
