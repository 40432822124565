import NotionPage from '../components/NotionPageComponent';
import { useTranslate } from '../hooks';

export default function MerchandiseReposition() {
  const translate = useTranslate('Page.MerchandiseReposition');

  const data = [
    {
      id: '6949b2dde52c4131a198ddf69fa7dc75',
      title: translate('withdrawal'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/retiro-mercaderia.jpg',
    },
    {
      id: '9acc302e929f461383492ea29eca4a6b',
      title: translate('serviceHours'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/horarios-reposicion.jpg',
    }];
  return (
    <NotionPage pageData={data} />
  );
}
