import {
  Button,
  Flex,
  useAuthenticator,
  useTheme,
} from '@aws-amplify/ui-react';
import { useTranslate } from '../../hooks';

function LoginFooter() {
  const { toResetPassword } = useAuthenticator();
  const { tokens } = useTheme();
  const translate = useTranslate('Amplify');

  return (
    <Flex
      justifyContent="center"
      padding={`0 0 ${tokens.space.medium}`}
    >
      <Button onClick={toResetPassword}>
        {translate('passwordRecovery')}
      </Button>
    </Flex>
  );
}

export default LoginFooter;
