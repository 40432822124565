import '../css/AccessDenied.css';
import { useIntercom } from 'react-use-intercom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useTranslate } from '../hooks';

export default function AccessDenied() {
  const { shutdown } = useIntercom();
  const { signOut } = useAuthenticator((context) => [context.user]);
  const translate = useTranslate('Page.AccessDenied');

  function logOut(): void {
    shutdown();
    signOut();
  }

  return (
    <div className="AccessDeniedPage">
      <h2>
        {translate('title')}
      </h2>
      <p>
        {translate('subtitle1')}
      </p>
      <p>
        {translate('subtitle2')}
      </p>
      <p>
        {translate('subtitle3')}
      </p>
      <div className="Row">
        <div
          className="Button Secondary"
          onClick={(e) => {
            window.open(
              'https://stores.getnomad.cl',
              '_blank',
              'noopener,noreferrer',
            );
            e.preventDefault();
          }}
        >
          {translate('goToStore')}
        </div>
        <div
          className="Button"
          onClick={() => logOut()}
        >
          {translate('signOut')}
        </div>
      </div>
    </div>
  );
}
