import React from 'react';
import {
  Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText,
} from '@mui/material';
import { useTranslate } from '../../hooks';

interface Props {
  title: string;
  text: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}

export default function AlertDialog(props: Props) {
  const {
    title, text, open, setOpen,
  } = props;

  const translate = useTranslate('Component.AlertDialog');

  const handleCloseModalAlert = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseModalAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ whiteSpace: 'pre-line' }}
          >
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={handleCloseModalAlert}
            autoFocus
          >
            {translate('ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
