import { useContext, useEffect, useState } from 'react';
import { FilterOutlined } from '@ant-design/icons';
import PaymentOrdersTable from '../../components/PaymentOrders/PaymentOrdersTable';
import IPaymentOrder from '../../interfaces/IPaymentOrder';
import uploadDocumentsToS3 from '../../helpers/uploadDocuments';
import { GlobalContext } from '../../store';
import { useTranslate } from '../../hooks';
import SelectInput from '../../components/HomeComponents/SelectInputName';
import LoadingPage from '../../components/LoadingPageComponent';
import AlertDialog from '../../components/HomeComponents/AlertDialog';

const initialPageSize = 10;
const initialPage = 0;

export default function AllPaymentOrders() {
  const { api, user } = useContext(GlobalContext);
  const [rows, setRows] = useState<IPaymentOrder[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [cursors, setCursors] = useState<string[]>(['']);
  const [page, setPage] = useState<number>(initialPage);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showFiltersPannel, setShowFiltersPannel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [inputEvent, setInputEvent] = useState<React.ChangeEvent<HTMLInputElement>>();
  const [count, setCount] = useState<number>(-1);
  const [fetchOptions, setFetchOptions] = useState({
    filter: {},
    cursor: cursors[page],
    rowsPerPage: initialPageSize,
    page: initialPage,
  });

  const allStatus = [
    { name: 'pending' },
    { name: 'validating' },
    { name: 'validated' },
  ];

  const translate = useTranslate('Page.AllPaymentOrders');

  async function getPaymentOrders() {
    const response = await api.getPaymentOrders(fetchOptions);
    setRows(response.paymentOrders);
    if (!response.cursor) {
      setCount(page * fetchOptions.rowsPerPage + response.paymentOrders.length);
    }
    if (cursors.length - 1 <= page) {
      setCursors([...cursors, response.cursor]);
    }
  }

  async function handleUploadDocuments(id: string) {
    const paymentOrder = rows.find((row) => row.id === id);
    if (!paymentOrder || !(inputEvent && inputEvent.target.files)) {
      return;
    }
    setLoading(true);
    try {
      uploadDocumentsToS3(user.username, `Finanzas/PaymentOrders/${id}`, inputEvent.target);
      const urls = [];
      const baseUrl = `https://clients-platform.s3.amazonaws.com/Finanzas/PaymentOrders/${id}`;
      for (let i = 0; i < inputEvent.target.files.length; i += 1) {
        if (inputEvent.target.files.length === 1) {
          urls.push(`${baseUrl}-${user.username}.pdf`);
        } else {
          urls.push(`${baseUrl}-${i + 1}-${user.username}.pdf`);
        }
      }
      await api.updatePaymentOrder(paymentOrder, urls, 'validating');
      await getPaymentOrders();
      setModalAlertTitle(translate('Modal.successTitle'));
      setModalAlertText(translate('Modal.successText'));
    } catch (error) {
      setModalAlertTitle(translate('Modal.errorTitle'));
      setModalAlertText(translate('Modal.errorText'));
    }
    setOpenModalAlert(true);
    setSelected('');
    setInputEvent(undefined);
    setLoading(false);
  }

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setInputEvent(event);
    }
  };

  useEffect(() => {
    (async () => {
      setLoadingTable(true);
      try {
        await getPaymentOrders();
        setModalAlertTitle(translate('Modal.successTitle'));
        setModalAlertText(translate('Modal.successText'));
      } catch (error) {
        setModalAlertTitle(translate('Modal.errorTitle'));
        setModalAlertText(translate('Modal.errorText'));
        setOpenModalAlert(true);
      }
      setLoadingTable(false);
    })();
  }, [fetchOptions]);

  function handleChangeRequest(newRowsPerPage: number, newPage: number) {
    setPage(newPage);
    setFetchOptions({
      ...fetchOptions,
      rowsPerPage: newRowsPerPage,
      cursor: cursors[newPage],
      page,
    });
  }

  function handleShowModal() {
    if (inputEvent && inputEvent.target.files) {
      setInputEvent(undefined);
    }
    setShowModal(!!selected);
  }

  function applyFilters() {
    if (statusFilter) {
      setFetchOptions({
        ...fetchOptions,
        filter: {
          status: statusFilter,
        },
      });
    }
  }

  function clearFilters() {
    setStatusFilter('');
    setFetchOptions({
      ...fetchOptions,
      filter: {},
    });
  }

  if (loading) {
    return (
      <div className="PageContainer">
        <LoadingPage />
      </div>
    );
  }

  return (
    <div className="FormHistoryContainer">
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      <div className="FormHistoryTitle">
        {translate('title')}
      </div>
      <div className="ItemsFiltersContainer">
        {showFiltersPannel ? (
          <>
            <div className="ItemsFiltersBtn ItemsFiltersBtnPressed">
              <FilterOutlined
                onClick={() => setShowFiltersPannel(!showFiltersPannel)}
                className="LargeIcon"
              />
            </div>
            <div className="ItemsFiltersSelecInputsContainer">
              <SelectInput
                styleWidth="180px"
                title={translate('Filters.selectStatus')}
                array={allStatus}
                selectedOption={statusFilter}
                SetSelectedOption={setStatusFilter}
                lightStyle={false}
              />
            </div>
            <div className="ItemsBtnsContainer">
              <div
                className="ItemsFiltersBtn"
                onClick={() => applyFilters()}
              >
                {translate('Filters.apply')}
              </div>
              {statusFilter ? (
                <div
                  className="ItemsFiltersBtn"
                  onClick={() => clearFilters()}
                >
                  {translate('Filters.clean')}
                </div>
              ) : (null)}
            </div>
          </>
        ) : (
          <div className="ItemsFiltersBtn ItemsBtnMargin">
            <FilterOutlined
              onClick={() => setShowFiltersPannel(!showFiltersPannel)}
              className="LargeIcon"
            />
          </div>
        )}
      </div>
      { showModal ? (
        <div className="UpdateItemsContainer">
          {
            inputEvent && inputEvent.target.files ? (
              <div
                className="UpdateItemsBtns"
                onClick={() => handleUploadDocuments(selected)}
              >
                {translate('uploadDocuments')}
              </div>
            ) : (null)
          }
          <label
            className="UpdateItemsBtns"
            htmlFor="fileInput"
          >
            <input
              type="file"
              id="fileInput"
              multiple
              hidden
              onChange={handleChangeInput}
            />
            {translate('chooseDocuments')}
          </label>
        </div>
      ) : (null) }
      <PaymentOrdersTable
        rows={rows}
        onChangeRequest={handleChangeRequest}
        initialPageSze={initialPageSize}
        selected={selected}
        page={page}
        showModal={handleShowModal}
        count={count}
        setSelected={setSelected}
        loadingTable={loadingTable}
      />
    </div>
  );
}
