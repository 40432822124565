export const getProductPacksVariables = ({
  filter,
}: {
  filter?: { [key: string]: string | number | number[] | boolean | string[] | { [key: string]: string | string[] } }
}) => {
  const { isPack } = filter || {};
  return {
    where: {
      _and: [
        {
          isPack: isPack ? {
            _eq: isPack,
          } : {},
        },
      ],
    },
  };
};
