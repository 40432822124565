import {
  Flex, Image, useTheme, View,
} from '@aws-amplify/ui-react';

function Header() {
  const { tokens } = useTheme();

  return (
    <View>
      <Flex justifyContent="center">
        <Image
          alt="Nomad"
          src="https://nomad-assets-1.s3.amazonaws.com/public/NomadLogoBlack.png"
          padding={tokens.space.medium}
        />
      </Flex>
    </View>
  );
}

export default Header;
