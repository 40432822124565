import Papa from 'papaparse';
import { Dispatch } from 'react';
import languageEncoding from 'detect-file-encoding-and-language';
import capitalizeFirstLetter from './capitalizeFirstLetter';
import { ITranslationOptions } from '../services';

async function uploadCsvData(
  file: File,
  columnsShouldHave: string[],
  setOpenModalAlert: Dispatch<React.SetStateAction<boolean>>,
  setModalAlertTitle: Dispatch<React.SetStateAction<string>>,
  setModalAlertText: Dispatch<React.SetStateAction<string>>,
  setTableColumns: Dispatch<React.SetStateAction<string[]>>,
  setRowsValues: Dispatch<React.SetStateAction<unknown[]>>,
  translate: (path: string, options?: ITranslationOptions | undefined) => string,
) {
  const colsArray: string[] = [];
  const valuesArray: unknown[] = [];
  const fileInfo = await languageEncoding(file);
  Papa.parse(file, {
    header: true,
    encoding: fileInfo.encoding === 'UTF-8' ? 'UTF-8' : 'ISO-8859-1',
    skipEmptyLines: true,
    complete(results) {
      const filteredArray = Object.keys(results.data[0] as string)
        .filter((item: string) => columnsShouldHave.includes(item.trim().toLowerCase()));
      const filteredArrayLower = filteredArray.map((item: string) => item.trim().toLowerCase());
      // Check filtered column names
      const missingCols: string[] = [];
      columnsShouldHave.forEach((colName: string, idx: number) => {
        if (!filteredArrayLower.includes(colName.toLowerCase())) {
          missingCols.push(columnsShouldHave[idx]);
        }
      });
      if (missingCols.length > 0) {
        const strMissingCols = missingCols.join(', ');
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.csvTitle1'));
        setModalAlertText(translate('Modal.csvText1', { missingCols: strMissingCols }));
      } else {
        // Check if columns are in order
        let equal = true;
        filteredArrayLower.forEach((item: string, idx: number) => {
          if (columnsShouldHave[idx].toLocaleLowerCase() !== item.toLocaleLowerCase()) {
            equal = false;
          }
        });
        if (!equal) {
          setOpenModalAlert(true);
          setModalAlertTitle(translate('Modal.csvTitle2'));
          setModalAlertText(translate('Modal.csvText2'));
        } else {
          // Load data
          colsArray.push(...filteredArrayLower
            .map((value: string) => capitalizeFirstLetter(value.toLowerCase())));
          results.data.forEach((data: any) => {
            const filteredValues: { [key: number]: string } = {};
            filteredArray.forEach((col: string, idx: number) => {
              filteredValues[idx] = data[col];
            });
            valuesArray.push(Object.values(filteredValues as string));
          });
          setTableColumns(colsArray);
          setRowsValues(valuesArray);
        }
      }
    },
  });
}

export default uploadCsvData;
