export const getDeliveryRatesVariables = ({
  filter,
}: {
  filter?: { [key: string]: string | number | number[] | boolean | string[] | { [key: string]: string | string[] } }
}) => {
  const {
    id, shippingType, CommuneId, LocalId, activateAt, daysDelay, checkStock,
    scheduled, active, title, subtitle,
  } = filter || {};
  return {
    where: {
      _and: [
        {
          id: id ? {
            _in: id,
          } : {},
        },
        {
          shippingType: shippingType ? {
            _eq: shippingType,
          } : {},
        },
        {
          CommuneId: CommuneId ? {
            _eq: CommuneId,
          } : {},
        },
        {
          LocalId: LocalId ? {
            _eq: LocalId,
          } : {},
        },
        {
          activateAt: activateAt ? {
            _eq: activateAt,
          } : {},
        },
        {
          daysDelay: daysDelay ? {
            _eq: daysDelay,
          } : {},
        },
        {
          checkStock: checkStock ? {
            _eq: checkStock,
          } : {},
        },
        {
          scheduled: scheduled ? {
            _eq: scheduled,
          } : {},
        },
        {
          active: active ? {
            _eq: active,
          } : {},
        },
        {
          title: title ? {
            _ilike: `%${title}%`,
          } : {},
        },
        {
          subtitle: subtitle ? {
            _ilike: `%${subtitle}%`,
          } : {},
        },
      ],
    },
  };
};
