import textInputToNumber from '../../helpers/textInputToNumber';

import { IRowData } from '../../interfaces/INavigableTableObjects';

function OrderDetail({ orderDetail }: { orderDetail: IRowData; }) {
  return (
    <tr
      className="SummaryOrderDetail"
      key={orderDetail.index}
    >
      <td>
        {orderDetail.row[0].value as string}
      </td>
      <td>
        {textInputToNumber(orderDetail.row[1].value as string, true)}
      </td>
      <td>
        {`$${textInputToNumber(orderDetail.row[2].value as string, true)}`}
      </td>
      <td className="Orange">
        {`$${textInputToNumber((
          (orderDetail.row[1].value as number) * (orderDetail.row[2].value as number)).toString(), true)}`}
      </td>
    </tr>
  );
}

export default OrderDetail;
