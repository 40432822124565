import React, {
  useEffect, useState, useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../store';
import IProduct from '../../interfaces/IProduct';
import { useTranslate } from '../../hooks';
import ProductPacksTable from '../../components/ProductComponents/ProductPacksTable';

const initialPageSize = 10;
const initialPage = 0;
interface IFetchOptions {
  filter: { [key: string]: string | number | number[] | boolean | string[] | { [key: string]: string | string[] } }
  rowsPerPage: number;
  page: number;
}

export default function ProductsPack() {
  const navigate = useNavigate();
  const [products, setProducts] = useState<IProduct[]>([]);
  const { api } = useContext(GlobalContext);
  const [productsLength, setProductsLength] = useState<number>(0);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [filteredPacks, setFilteredPacks] = useState<IProduct[]>([]);
  const [showPacksWithNoProducts, setShowPacksWithNoProducts] = useState<boolean>(false);
  const [fetchOptions, setFetchOptions] = useState<IFetchOptions>({
    filter: { isPack: true },
    rowsPerPage: initialPageSize,
    page: initialPage,
  });

  const translate = useTranslate('Page.ProductsPack');

  function handleChangeRequest(newRowsPerPage: number, newPage: number) {
    setFetchOptions({
      ...fetchOptions,
      rowsPerPage: newRowsPerPage,
      page: newPage,
    });
  }

  async function fetchProducts() {
    const response = await api.getProductPacks(fetchOptions);
    setProducts(response.products);
    setFilteredPacks(response.products);
    setProductsLength(response.count);
  }

  useEffect(() => {
    (async () => {
      setLoadingTable(true);
      await fetchProducts();
      setLoadingTable(false);
    })();
  }, [fetchOptions]);
  // Filter packs with no products
  useEffect(() => {
    if (showPacksWithNoProducts) {
      const newFilteredPacks = products.filter((product) => (
        product.Components?.length === 0
      ));
      setFilteredPacks(newFilteredPacks);
      setProductsLength(newFilteredPacks.length);
    } else {
      setFilteredPacks(products);
      setProductsLength(products.length);
    }
  }, [showPacksWithNoProducts]);

  return (
    <div className="FormHistoryContainer">
      <div className="FormHistoryTitle">
        {translate('title')}
      </div>
      <div className="ItemsFiltersContainer">
        <div
          className="ItemsFiltersBtn"
          onClick={() => navigate('/home/packcreate')}
        >
          {translate('massiveCreate')}
        </div>
        <div
          className={`ItemsFiltersBtn ItemsBtnMargin ${showPacksWithNoProducts ? 'ItemsFiltersBtnPressed' : ''}`}
          onClick={() => setShowPacksWithNoProducts(!showPacksWithNoProducts)}
        >
          {translate('packsWithoutProducts')}
        </div>
      </div>
      <ProductPacksTable
        rows={filteredPacks}
        totalRowCount={productsLength}
        onChangeRequest={handleChangeRequest}
        initialPageSze={initialPageSize}
        initialPage={initialPage}
        loadingTable={loadingTable}
      />
    </div>
  );
}
