import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserHistory } from 'history';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initI18n } from './services';

import './css/App.css';
import './css/SideNavBar.css';
import './css/Home.css';
import './css/Login.css';
import './css/Forms.css';
import './css/LandingPage.css';
import './css/DropFileComponent.css';
import './css/CreateOrdersPage.css';
import './css/SelectCheckboxInputComponent.css';
import './css/Modal.css';
import './css/SelectInput.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const history = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  history.replace(path);
}

initI18n();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
