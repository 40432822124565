export const getProductsWithStock = (limit: number | null, offset: number, localId: number) => `
query getProductsWithStock($where: PointOfSaleProducts_bool_exp!, $sorting: [PointOfSaleProducts_order_by!]) {
  PointOfSaleProducts(limit: ${limit}, offset: ${offset}, order_by: $sorting, where: {
    _and: [{ Product: {
          activated: { _eq: true},
          isPack: { _eq: false },
          Stocks: { available: { _gt: 0 }, LocalId: { _eq: ${localId} } } 
        }}
        $where,
      ]
    }) {
    Product {
      id
      rawSku
      sku
      name
      Stocks(limit: 1, where: { LocalId: { _eq: ${localId} } }) {
          available
      }
    }
  }
}
`;
