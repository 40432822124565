import { Heading, Flex, useTheme } from '@aws-amplify/ui-react';
import { useTranslate } from '../../hooks';

function ResetPasswordHeader() {
  const { tokens } = useTheme();
  const translate = useTranslate('Amplify');

  return (
    <Flex
      justifyContent="flex-start"
      padding={`0 0 ${tokens.space.medium}`}
    >
      <Heading level={4}>
        {translate('passwordRecovery')}
      </Heading>
    </Flex>
  );
}

export default ResetPasswordHeader;
