export const getProductsWithStockVariables = ({
  filter,
}: {
  filter?: { [key: string]: string | number | number[] | boolean | string[] | { [key: string]: string | string[] } }
}) => {
  const {
    PointOfSaleId, name, rawSku,
  } = filter || {};
  return {
    where: {
      _and: [
        {
          PointOfSaleId: PointOfSaleId ? {
            _eq: PointOfSaleId,
          } : {},
        },
      ],
      Product: {
        _or: [
          {
            name: name ? {
              _ilike: `%${name}%`,
            } : {},
          },
          {
            rawSku: rawSku ? {
              _ilike: `%${rawSku}%`,
            } : {},
          },
        ],
      },
    },
    sorting: [{ Product: { name: 'asc' } }],
  };
};
