import AWS from 'aws-sdk';

function uploadFileToS3(json: string, key: string): string {
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  const params = {
    Bucket: process.env.REACT_APP_FUNCTIONS_BUCKET_NAME as string,
    Key: key,
    Body: json,
  };
  try {
    s3.upload(params, (err: Error) => {
      if (err) {
        throw new Error(`Error uploading json file on S3 Bucket ${err}`);
      }
    });
    return 'Success';
  } catch (error) {
    return `Error: ${error}`;
  }
}

export default uploadFileToS3;
