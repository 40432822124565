import { useContext, useState, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import DatePicker from 'react-datepicker';
import { useTranslate } from '../../hooks';
import ILocal from '../../interfaces/ILocal';
import { GlobalContext } from '../../store';
import joinLocalsFilters from '../../helpers/joinLocalsFilters';
import { SelectCheckboxInput } from '../../components/HomeComponents/SelectCheckboxInput';
import SelectInput from '../../components/HomeComponents/SelectInputName';

export default function Products() {
  const translate = useTranslate('Page.Products');
  const [url, setUrl] = useState<string>('');
  const { user, api } = useContext(GlobalContext);
  const { shutdown } = useIntercom();
  const [allLocals, setAllLocals] = useState<ILocal[]>([]);
  const [selectedLocals, setSelectedLocals] = useState<ILocal[]>([]);
  const [selectedCode, setSelectedCode] = useState<string>('');
  const [selectedSku, setSelectedSku] = useState<string>('');
  const [products, setProducts] = useState<{ name: string }[]>([]);
  const [selectedInitDate, setSelectedInitDate] = useState<Date>(
    new Date((new Date()).setMonth((new Date()).getMonth() - 1)),
  ); // one month ago
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const iframeStyle = {
    border: 'none',
    borderStyle: 'none',
    width: '80vw',
    height: '94vh',
  };
  useEffect(() => {
    shutdown();
  }, []);
  useEffect(() => {
    const clientFilter = user.connectableId.toString();
    const localFilterTitle = 'Local';
    const joinedString = joinLocalsFilters(selectedLocals.map((el) => el.id), localFilterTitle);
    setUrl('https://mb.getnomad.cl/public/question/dcc881b8-75ce-47e2-ad0b-dbaa3c38ef74?Client='
    + `${clientFilter}&${joinedString}`
    + `&Code=${encodeURIComponent(selectedCode)}`
    + `${selectedInitDate
      ? `&date_from=${encodeURIComponent(
        `${selectedInitDate.toISOString().split('T')[0]}`,
      )}` : ''}`
    + `${selectedEndDate
      ? `&date_to=${encodeURIComponent(
        `${selectedEndDate.toISOString().split('T')[0]}`,
      )}` : ''}`
    + `&Sku=${encodeURIComponent(selectedSku)}`
    + '#hide_parameters=Client,Local,Code,date_from,date_to,Sku');
  }, [selectedLocals, selectedCode, selectedInitDate, selectedEndDate, selectedSku]);
  useEffect(() => {
    (async () => {
      const { locals } = await api.getLocals();
      setAllLocals(locals);
      const response = await api.getClientProducts({});
      setProducts(response.products.map((p) => ({ name: p.rawSku } as { name: string })));
    })();
  }, []);
  return (
    <div className="PageContainer ReportFilterDisplay">
      <div
        className="ReportFilterContainer"
      >
        <div
          className="FilterContainer"
        >
          <h5>{translate('dateFromTitle')}</h5>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={selectedInitDate}
            onChange={(date: Date) => setSelectedInitDate(date)}
            className="ReportFilterDatePicker"
            placeholderText={translate('initDatePlaceholder')}
          />
          <h5>{translate('dateToTitle')}</h5>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={selectedEndDate}
            onChange={(date: Date) => setSelectedEndDate(date)}
            className="ReportFilterDatePicker"
            placeholderText={translate('endDatePlaceholder')}
          />
          <SelectCheckboxInput
            data={allLocals}
            inputTitle={translate('locals')}
            keyValue="id"
            checkedValues={selectedLocals}
            displayValue="name"
            setChecked={setSelectedLocals}
          />
          <input
            className="ReportTextInput"
            type="text"
            placeholder={translate('selectCodeTitle')}
            onChange={(e) => setSelectedCode(e.target.value)}
          />
          <SelectInput
            styleWidth="180px"
            title={translate('selectSkuTitle')}
            array={products}
            selectedOption={selectedSku}
            SetSelectedOption={setSelectedSku}
            lightStyle
          />
        </div>
      </div>
      <iframe
        title={translate('title')}
        src={url}
        style={iframeStyle}
      />
    </div>
  );
}
