import {
  useEffect, useState, useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { GlobalContext } from '../../store';
import textInputToNumber from '../../helpers/textInputToNumber';
import Loading from '../../components/LoadingComponent';
import capitalizeFirstLetter from '../../helpers/capitalizeFirstLetter';
import IReception from '../../interfaces/IReception';
import LoadingPage from '../../components/LoadingPageComponent';
import { useTranslate } from '../../hooks';

export default function ReceptionForm() {
  const [documents, setDocuments] = useState<IReception[] | undefined>();
  const { user, api } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [documentsLength, setdocumentsLength] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);

  const translate = useTranslate('Page.ReceptionFormHistory');

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleOnClick(document: IReception) {
    navigate('/home/receptiondetail', {
      state: {
        document,
      },
    });
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await api.getReceptionsData(user.connectableId);
      setDocuments(response);
      setLoading(false);
      setdocumentsLength(response.length);
    })();
  }, [user.connectableId]);

  if (loading) {
    return (
      <div className="PageContainer">
        <LoadingPage />
      </div>
    );
  }
  return (
    <div className="FormHistoryContainer">
      <div className="FormHistoryTitle">
        {translate('title')}
      </div>
      <Paper sx={{ width: '90%' }}>
        <TableContainer sx={{ minHeight: 620, maxHeight: 620 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                  {translate('Columns.documentNumber')}
                </TableCell>
                <TableCell
                  sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                  align="center"
                >
                  {translate('Columns.mfc')}
                </TableCell>
                <TableCell
                  sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                  align="center"
                >
                  {translate('Columns.documentDate')}
                </TableCell>
                <TableCell
                  sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                  align="right"
                >
                  {translate('Columns.document')}
                </TableCell>
              </TableRow>
            </TableHead>
            {documents?.length === 0 ? (
              <TableBody>
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell
                    sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                    colSpan={4}
                  >
                    {translate('noDocs')}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {loading ? (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <TableCell
                      sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                      colSpan={4}
                    >
                      <Loading />
                    </TableCell>
                  </TableRow>
                ) : (
                  documents?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((document: IReception) => (
                      <TableRow
                        sx={{ cursor: 'pointer' }}
                        hover
                        tabIndex={-1}
                      >
                        <TableCell
                          onClick={() => handleOnClick(document)}
                          sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                        >
                          {textInputToNumber(document.documentNumber!.toString(), true)}
                        </TableCell>
                        <TableCell
                          onClick={() => handleOnClick(document)}
                          sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                          align="center"
                        >
                          {capitalizeFirstLetter(document.localName)}
                        </TableCell>
                        <TableCell
                          onClick={() => handleOnClick(document)}
                          sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                          align="center"
                        >
                          {new Date(document.createdAt!).getUTCDate()}
                          /
                          {new Date(document.createdAt!).getUTCMonth() + 1}
                          /
                          {new Date(document.createdAt!).getUTCFullYear()}
                        </TableCell>
                        <TableCell
                          sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                          align="right"
                        >
                          <div className="FormHistoryPdfDownloadContainer">
                            {document.documentUrl.map((url: string) => (
                              <a
                                style={{ textDecoration: 'none', color: 'black' }}
                                target="_blank"
                                href={url}
                                rel="noreferrer"
                              >
                                {translate('downloadDocument')}
                              </a>
                            ))}
                          </div>
                        </TableCell>
                      </TableRow>
                    )))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={documentsLength}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Filas por página"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
