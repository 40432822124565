import { useState, useEffect, useContext } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useTranslate } from '../../hooks';
import SelectInput from '../HomeComponents/SelectInputName';
import ILocal from '../../interfaces/ILocal';
import { GlobalContext } from '../../store';
import { SelectCheckboxInput } from '../HomeComponents/SelectCheckboxInput';
import joinLocalsFilters from '../../helpers/joinLocalsFilters';

interface SalesReportProps {
  reportUrl: string;
}

export default function StockReport({
  reportUrl,
}: SalesReportProps) {
  const translate = useTranslate('Page.Stocks');
  const { user } = useContext(GlobalContext);
  const [selectedLocals, setSelectedLocals] = useState<ILocal[]>([]);
  const [total, setTotal] = useState<string>('Si');
  const [selectedSku, setSelectedSku] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [allLocals, setAllLocals] = useState<ILocal[]>([]);
  const [products, setProducts] = useState<{ name: string }[]>([]);
  const { shutdown } = useIntercom();
  const {
    api,
  } = useContext(GlobalContext);
  const iframeStyle = {
    border: 'none',
    borderStyle: 'none',
    width: '80vw',
    height: '90vh',
  };
  useEffect(() => {
    const clientFilter = user.connectableId.toString();
    const localFilterTitle = 'Local';
    const joinedString = joinLocalsFilters(selectedLocals.map((el) => el.id), localFilterTitle);
    setUrl(`${reportUrl}?Client=`
    + `${clientFilter}&${joinedString}`
    + `&SKU=${encodeURIComponent(selectedSku)}`
    + `&TOTAL=${total}`
    + '#hide_parameters=Client,Local,SKU,TOTAL');
  }, [selectedLocals, selectedSku, total]);
  useEffect(() => {
    shutdown();
  }, []);
  useEffect(() => {
    (async () => {
      const { locals } = await api.getLocals();
      setAllLocals(locals);
      const response = await api.getClientProducts({});
      setProducts(response.products.map((p) => ({ name: p.rawSku } as { name: string })));
    })();
  }, []);

  useEffect(() => {
    if (selectedLocals.length === 0) {
      setTotal('No');
    } else {
      setTotal('Si');
    }
  }, [selectedLocals]);

  return (
    <div className="PageContainer ReportFilterDisplay">
      <div
        className="ReportFilterContainer"
      >
        <div
          className="FilterContainer"
        >
          <SelectCheckboxInput
            data={allLocals}
            inputTitle={translate('locals')}
            keyValue="id"
            checkedValues={selectedLocals}
            displayValue="name"
            setChecked={setSelectedLocals}
          />
          <SelectInput
            styleWidth="180px"
            title={translate('selectSkuTitle')}
            array={products}
            selectedOption={selectedSku}
            SetSelectedOption={setSelectedSku}
            lightStyle
          />
        </div>
      </div>
      <iframe
        title={translate('title')}
        src={url}
        style={iframeStyle}
      />
    </div>
  );
}
