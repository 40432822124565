import { Api } from '../services/api';
import IProductSize from '../interfaces/IProductSize';

async function searchSize(dataHash: { [key: string]: IProductSize }, sizeName: string, api: Api) {
  if (dataHash[sizeName]) {
    return dataHash[sizeName].id;
  }
  const sizes = await api.getProductSizes({
    filter: {
      name: sizeName,
    },
  });
  if (sizes.length > 0) {
    const [firstSize] = sizes;
    dataHash[sizeName] = firstSize;
    return firstSize.id;
  }
  return -1;
}

export default searchSize;
