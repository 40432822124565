import { initializePaddle, Paddle } from '@paddle/paddle-js';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import '../css/Checkout.css';
import { IPaddleTransaction } from '../interfaces/IPaddleTransaction';
import { GlobalContext } from '../store';

function Checkout() {
  const { apiFunctions } = useContext(GlobalContext);
  const [paddle, setPaddle] = useState<Paddle>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get('_ptxn');
  const [todayTotal, setTodayTotal] = useState<IPaddleTransaction['details']['totals']>(
    {} as IPaddleTransaction['details']['totals'],
  );
  const [items, setItems] = useState<IPaddleTransaction['details']['line_items']>(
    [] as IPaddleTransaction['details']['line_items'],
  );
  const [currencyCode, setCurrencyCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  if (!transactionId) {
    navigate('/home');
  }

  useEffect(() => {
    initializePaddle({
      environment: process.env.REACT_APP_PADDLE_ENVIRONMENT as 'sandbox' | 'production',
      token: process.env.REACT_APP_PADDLE_TOKEN || '',
    }).then(
      (paddleInstance: Paddle | undefined) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
        apiFunctions.fetchTransaction(transactionId as string).then(
          (transaction) => {
            const currencyCodeData = transaction.currency_code;
            const itemsData = transaction.details.line_items;
            const totalsData = transaction.details.totals;
            setTodayTotal(totalsData);
            setItems(itemsData);
            setCurrencyCode(currencyCodeData || '');
            setLoading(false);
          },
        );
      },
    );
  }, []);

  useEffect(
    () => {
      if (paddle && transactionId) {
        paddle.Checkout.open({
          transactionId,
          settings: {
            displayMode: 'inline',
            frameTarget: 'checkout-container',
            frameStyle: 'border: 0; width: 100%;',
          },
        });
      }
    },
    [paddle, transactionId],
  );

  return (
    <div className="background">
      <div className="container">
        <div className="checkout-column">
          <div className="details">

            {loading && (
            <div className="Spinner" />)}
            {transactionId && !loading && (
            <>
              <img
                className="logo"
                src="https://nomadassets.s3.amazonaws.com/public/platform/mailing/nomad-logo-white.png"
                alt="Nomad Logo"
              />
              <div className="title">
                $
                {todayTotal.total}
                {' '}
                {currencyCode}

              </div>
              {items.map((item) => (
                <div
                  key={item.product.id}
                  className="product-container"
                >
                  <div className="product-info">
                    <div className="product-title">
                      {item.product.name}

                    </div>
                    <div className="product-price">
                      $
                      {item.totals.subtotal}
                    </div>
                  </div>
                </div>
              ))}
              <div className="product-container">
                <div className="product-info">
                  Subtotal
                  <div className="product-price">
                    $
                    {todayTotal.subtotal}
                  </div>
                </div>
                <div className="product-info">
                  Taxes
                  <div className="product-price">
                    $
                    {todayTotal.tax}
                  </div>
                </div>
                <div className="product-info">
                  Discount
                  <div className="product-price">
                    $
                    {todayTotal.discount}
                  </div>
                </div>
                <div className="product-info">
                  Total
                  <div className="product-price">
                    $
                    {todayTotal.total}
                  </div>
                </div>
              </div>
            </>
            )}
          </div>
        </div>
        <div className="checkout-container payment-column" />
      </div>
    </div>
  );
}

export default Checkout;
