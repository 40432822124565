export const getCountries = () => `
  query getCountries(
    $where: Countries_bool_exp!,
  ) {
    Countries(where: $where, order_by: {name: asc}) {
      id
      name
      Regions {
        id
        name
        Communes(order_by: {name: asc}) {
          id
          name
        }
      }
    }
  }
`;
