import NotionPage from '../components/NotionPageComponent';
import { useTranslate } from '../hooks';

export default function Premises() {
  const translate = useTranslate('Page.Premises');

  const data = [
    {
      id: 'premiseList',
      title: translate('addresses'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/direcciones.jpg',
    },
    {
      id: 'c7c9afc3454542b4b8bbc425b3f95eba',
      title: translate('serviceHours'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/horario-atencion.jpg',
    }];
  return (
    <NotionPage pageData={data} />
  );
}
