interface Props {
  title: string;
  text: string;
}

function TrackingBaseData(props: Props) {
  const { title, text } = props;

  return (
    <div className="TrackingBodyData">
      <span className="TrackingBodyDataTitle">{title}</span>
      <br />
      {text}
    </div>
  );
}

export default TrackingBaseData;
