import i18n, { TFunction, TOptionsBase, StringMap } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

export type ITranslationOptions = TOptionsBase & StringMap;

export function initI18n(): void {
  i18n
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: 'es-CL',
      detection: {
        order: ['localStorage', 'navigator'],
        caches: ['localStorage'],
      },
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
}

export function setTranslations(t: TFunction<'translation'>): (path: string, options?: ITranslationOptions) => string {
  return function getTranslations(path: string, options?: ITranslationOptions): string {
    const translateString = (options) ? t(path, options) : t(path);
    return translateString;
  };
}
