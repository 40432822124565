import { Autocomplete, TextField } from '@mui/material';
import {
  ChangeEvent, SyntheticEvent, useEffect, useState,
} from 'react';
import { IRowData } from '../../interfaces/INavigableTableObjects';
import IProduct from '../../interfaces/IProduct';
import { useTranslate } from '../../hooks';
import { IPointOfSaleProduct } from '../../interfaces';

interface Props {
  allProducts: IRowData[] | IProduct[] | IPointOfSaleProduct[];
  type: 'row' | 'product';
  showStock?: boolean;
  onUpdateSearch?: (newValue: string) => void;
  addProductToOrder?: (product: IProduct | IPointOfSaleProduct) => void;
}

function ProductSearch(props: Props) {
  const {
    type, allProducts, showStock, onUpdateSearch, addProductToOrder,
  } = props;
  const [options, setOptions] = useState<string[]>(type === 'row'
    ? (allProducts as IRowData[]).map((data) => data.row[0].value as string)
    : (allProducts as IProduct[]).map((data) => `${data.rawSku} - ${data.name}`));
  const [inputValue, setInputValue] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>('');
  const [lastTimeout, setLastTimeout] = useState<ReturnType<typeof setTimeout>>();
  const translate = useTranslate('Component.ProductSearch');

  function handleChange(e: SyntheticEvent, newValue: string | null) {
    if (!newValue) {
      setInputValue('');
      setValue('');
      return;
    }
    let searchedProduct;
    if (type === 'row') {
      searchedProduct = (allProducts as IRowData[])
        .filter((element) => (element.row[0].value as string)
          .toLowerCase().includes(newValue.toLowerCase()));
      setInputValue(searchedProduct[0].row[0].value as string);
    } else {
      if (showStock) {
        searchedProduct = (allProducts as IPointOfSaleProduct[])
          .filter((element) => (
            `${element.rawSku} || ${element.name} (stock: ${element.Stocks ? element.Stocks[0]?.available : '0'})`)
            .toLowerCase().includes(newValue.toLowerCase()));
      } else {
        searchedProduct = (allProducts as IProduct[])
          .filter((element) => (`${element.rawSku} || ${element.name}`)
            .toLowerCase().includes(newValue.toLowerCase()));
      }
      setInputValue(searchedProduct[0].rawSku as string);
      if (addProductToOrder) {
        addProductToOrder(searchedProduct[0]);
      }
    }
    setValue(newValue);
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value);
    setValue(e.target.value);
  }
  useEffect(() => {
    if (inputValue === null) {
      return;
    }
    if (lastTimeout) {
      clearTimeout(lastTimeout);
    }
    const timeoutId = setTimeout(() => {
      if (onUpdateSearch) {
        onUpdateSearch(inputValue);
      }
      setLastTimeout(undefined);
    }, 500);
    setLastTimeout(timeoutId);
  }, [inputValue, value]);

  useEffect(() => {
    if (options.length === 0 || options.length !== allProducts.length) {
      if (type === 'row') {
        setOptions((allProducts as IRowData[]).map((data) => data.row[0].value as string));
      } else if (showStock) {
        setOptions((allProducts as IPointOfSaleProduct[]).map(
          (data) => (`${data.rawSku} || ${data.name} (stock: ${data.Stocks ? data.Stocks[0]?.available : '0'})`),
        ));
      } else {
        setOptions((allProducts as IProduct[]).map(
          (data) => (`${data.rawSku} || ${data.name}`),
        ));
      }
    }
  }, [open, allProducts]);
  useEffect(() => {
    if (type === 'product') {
      if (showStock) {
        setOptions((allProducts as IPointOfSaleProduct[]).map(
          (data) => (`${data.rawSku} || ${data.name} (stock: ${data.Stocks ? data.Stocks[0]?.available : '0'})`),
        ));
      } else {
        setOptions((allProducts as IPointOfSaleProduct[]).map((data) => (`${data.rawSku} || ${data.name}`)));
      }
    }
  }, [allProducts]);
  return (
    <Autocomplete
      loading
      className="SelectInput"
      onChange={handleChange}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      options={options}
      isOptionEqualToValue={(option, val) => option === val}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={translate('label')}
          placeholder={translate('placeholder')}
          value={inputValue}
          onChange={handleInputChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <div>
                {params.InputProps.endAdornment}
              </div>
            ),
          }}
        />
      )}
    />
  );
}

export default ProductSearch;
