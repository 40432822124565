import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import Menu from '@ant-design/icons/MenuOutlined';
import CloseMenu from '@ant-design/icons/CloseOutlined';
import '../css/NavBar.css';
import { useTranslate } from '../hooks';

export default function Navbar() {
  const navigate = useNavigate();
  const { show, hide } = useIntercom();
  const [inLogin, setInLogin] = useState(false);
  const [inTracking, setInTracking] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [click, setClick] = useState<boolean>(false);

  const translate = useTranslate('Navigation.Navbar');

  const handleClickMenu = () => setClick(!click);

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const showContact = () => {
    setClick(!click);
    show();
  };

  function goToLogin() {
    hide();
    navigate('/login');
    setInLogin(true);
  }

  function goToHome() {
    hide();
    navigate('/');
    setInLogin(false);
    setInTracking(false);
  }

  function goToTracking() {
    hide();
    navigate('/tracking');
    setInTracking(true);
  }

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground);
  });

  return (
    <div className={`Nav ${navbar ? 'NavWhenScroll' : 'NavAtFirst'}`}>
      <div className="MobileMenuNavBar">
        {click ? (
          <CloseMenu
            className="LandingMenuIcon"
            onClick={handleClickMenu}
          />
        ) : (
          <Menu
            className="LandingMenuIcon"
            onClick={handleClickMenu}
          />
        )}
      </div>
      <div className="NavBarContainer">
        <div className="NavBarLogo">
          <img
            className="NavBarLogoImg"
            src="https://clients-platform-assets.s3.amazonaws.com/img/NomadLogoBlack.png"
            alt="Nomad Logo"
          />
        </div>
        <div className="NavLinks">
          {(!inLogin && !inTracking) ? (
            <>
              <div className="NavLink HideMobile">
                <a
                  href="#HowSection"
                  className="NavLinkText"
                >
                  {translate('howItWorks')}
                </a>
              </div>
              <div className="NavLink HideMobile">
                <a
                  href="#WhereSection"
                  className="NavLinkText"
                >
                  {translate('whereAreWe')}
                </a>
              </div>
              <div
                className="NavLink HideMobile"
                onClick={(e) => {
                  window.open(
                    'https://get-nomad.notion.site/Trabaja-en-Nomad-8610b4dc4c04489e846fe7a17bac6e49',
                    '_blank',
                    'noopener,noreferrer',
                  );
                  e.preventDefault();
                }}
              >
                <span className="NavLinkText">
                  {translate('workWithUs')}
                </span>
              </div>
              <div
                className="NavLink NavLinkDefault HideMobile"
                onClick={() => goToTracking()}
              >
                <span className="NavLinkText">
                  {translate('orderTracking')}
                </span>
              </div>
            </>
          ) : null }
        </div>
      </div>
      <div className="NavBtn">
        {(inLogin || inTracking) ? (
          <>
            <div
              className="NavLink HideMobile"
              onClick={() => show()}
            >
              {translate('contactUs')}
            </div>
            <div
              className="NavLink"
              onClick={() => goToHome()}
            >
              {translate('home')}
            </div>
          </>
        ) : (
          <>
            <div
              className="NavLink HideMobile"
              onClick={() => show()}
            >
              {translate('contactUs')}
            </div>
            <div
              className="NavLoginBtn"
              onClick={() => goToLogin()}
            >
              {translate('signIn')}
            </div>
          </>
        )}
      </div>
      <div className={click ? 'MobileNavigationBar active' : 'MobileNavigationBar'}>
        <div
          className="NavLinkMobileNavBar"
          onClick={(e) => {
            window.open(
              'https://get-nomad.notion.site/Trabaja-en-Nomad-8610b4dc4c04489e846fe7a17bac6e49',
              '_blank',
              'noopener,noreferrer',
            );
            e.preventDefault();
            handleClickMenu();
          }}
        >
          {translate('workWithUs')}
        </div>
        <div
          className="NavLinkMobileNavBar"
          onClick={handleClickMenu}
        >
          <a href="#HowSection">
            {translate('howItWorks')}
          </a>
        </div>
        <div
          className="NavLinkMobileNavBar"
          onClick={handleClickMenu}
        >
          <a href="#WhereSection">
            {translate('whereAreWe')}
          </a>
        </div>
        <div
          className="NavLinkMobileNavBar"
          onClick={() => {
            handleClickMenu();
            goToTracking();
          }}
        >
          {translate('orderTracking')}
        </div>
        <div
          className="NavLinkMobileNavBar"
          onClick={showContact}
        >
          {translate('contactUs')}
        </div>
      </div>
    </div>
  );
}
