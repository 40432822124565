import { useState, useEffect, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslate } from '../../hooks';
import SelectInput from '../HomeComponents/SelectInputName';
import { GlobalContext } from '../../store';

interface PaymentsReportProps {
  reportUrl: string;
}

export default function PaymentsReport({
  reportUrl,
}: PaymentsReportProps) {
  const { user } = useContext(GlobalContext);
  const [url, setUrl] = useState<string>('');
  const [selectedInitDate, setSelectedInitDate] = useState<Date>(
    new Date((new Date()).getFullYear(), 0, 1),
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const [status, setStatus] = useState<string>('');
  const iframeStyle = {
    border: 'none',
    borderStyle: 'none',
    width: '100%',
    height: '100vh',
  };

  const translate = useTranslate('Page.Payments');

  const booleanOptions = [
    { name: 'Pagada' },
    { name: 'Pendiente' },
    { name: 'Por Verificar' },
  ];

  useEffect(() => {
    const clientFilter = user.connectableId.toString();
    setUrl(`${reportUrl}?cliente=`
    + `${clientFilter}`
    + `${status ? `&Estado=${encodeURIComponent(status)}` : ''}`
    + `${selectedInitDate
      ? `&from_date=${encodeURIComponent(
        `${selectedInitDate.toISOString().split('T')[0]}`,
      )}` : ''}`
    + `${selectedEndDate
      ? `&end_date=${encodeURIComponent(
        `${selectedEndDate.toISOString().split('T')[0]}`,
      )}` : ''}`
    + '#hide_parameters=cliente,from_date,end_date,Estado');
  }, [selectedEndDate, selectedInitDate, status]);

  return (
    <div className="PageContainer ReportFilterDisplay">
      <div
        className="ReportFilterContainer"
      >
        <div
          className="FilterContainer"
        >
          <h5>{translate('dateFromTitle')}</h5>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={selectedInitDate}
            onChange={(date: Date) => setSelectedInitDate(date)}
            className="ReportFilterDatePicker"
            placeholderText={translate('initDatePlaceholder')}
          />
          <h5>{translate('dateToTitle')}</h5>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={selectedEndDate}
            onChange={(date: Date) => setSelectedEndDate(date)}
            className="ReportFilterDatePicker"
            placeholderText={translate('endDatePlaceholder')}
          />
          <h5>{translate('statusTitle')}</h5>
          <SelectInput
            styleWidth="180px"
            lightStyle
            title={translate('statusPlaceholder')}
            array={booleanOptions}
            selectedOption={status}
            SetSelectedOption={setStatus}
          />
        </div>
      </div>
      <iframe
        title={translate('title')}
        src={url}
        style={iframeStyle}
      />
    </div>
  );
}
