import {
  useContext,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { Divider } from '@mui/material';
import 'react-notion/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css';
import { NumericFormat } from 'react-number-format';
import SelectInput from '../../components/HomeComponents/SelectInputName';
import ILocal from '../../interfaces/ILocal';
import ICommune from '../../interfaces/ICommune';
import { GlobalContext } from '../../store';
import AlertDialog from '../../components/HomeComponents/AlertDialog';
import LoadingPage from '../../components/LoadingPageComponent';
import GoBackButton from '../../components/HomeComponents/GoBackButton';
import IDeliveryRate from '../../interfaces/IDeliveryRate';
import IPointOfSale from '../../interfaces/IPointOfSale';
import ICourier from '../../interfaces/ICourier';
import { useTranslate } from '../../hooks';
import { ICountry } from '../../interfaces/ICountry';
import { IRegion } from '../../interfaces/IRegion';

export default function UnitaryCreateDeliveryRates() {
  const translate = useTranslate('Page.UnitaryCreateDeliveryRates');

  const [loading, setLoading] = useState<boolean>(false);
  const { user, api } = useContext(GlobalContext);
  const [deliveryType, setDeliveryType] = useState<string>('');
  const [deliveryLocal, setDeliveryLocal] = useState<ILocal['name']>('');
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [deliveryCommune, setDeliveryCommune] = useState<string>('');
  const [deliveryCourier, setDeliveryCourier] = useState<string>('');
  const [selectedPointOfSale, setSelectedPointOfSale] = useState<string>('');
  const [selectedPrice, setSelectedPrice] = useState<number>(0);
  const [selectedActivationPrice, setSelectedActivationPrice] = useState<number>(0);
  const [selectedDaysDelay, setSelectedDaysDelay] = useState<number>(0);
  const [selectedTitle, setSelectedTitle] = useState<string>('');
  const [selectedSubtitle, setSelectedSubtitle] = useState<string>('');
  const [selectedCheckStock, setSelectedCheckStock] = useState<string>(translate('yes'));
  const [selectedScheduled, setSelectedScheduled] = useState<string>(translate('no'));
  const [selectedActive, setSelectedActive] = useState<string>(translate('yes'));
  const [transferredPrice, setTransferredPrice] = useState<string>(translate('no'));
  const [locals, setLocals] = useState<ILocal[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [communes, setCommunes] = useState<ICommune[]>([]);
  const [regions, setRegions] = useState<IRegion[]>([]);
  const [couriers, setCouriers] = useState<ICourier[]>([]);
  const [pos, setPos] = useState<IPointOfSale[]>([]);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');

  const shippingType = [
    { name: 'pickup' },
    { name: 'courier' },
    { name: 'express' },
    { name: 'same-day' },
  ];
  const booleanOptions = [
    { name: translate('yes') },
    { name: translate('no') },
  ];

  // function to check if delivery rates exists
  async function verifyDeliveryRate(
    checkedActivateAt: number,
    checkedLocalId: number,
    checkedCommuneId: number,
    checkedShippingType: string,
    checkedCourierId: number,
    checkedPointOfSaleId: number,
    checkedCheckStock: boolean,
    checkedScheduled: boolean,
    checkedDaysDelay: number,
  ) {
    let count = 0;
    const response = await api.getDeliveryRates({});
    const { deliveryRates } = response;
    deliveryRates.forEach((rate: IDeliveryRate) => {
      if (rate.LocalId === checkedLocalId
        && rate.CommuneId === checkedCommuneId
        && rate.shippingType === checkedShippingType
        && rate.activateAt === checkedActivateAt
        && rate.CourierId === checkedCourierId
        && rate.PointOfSaleId === checkedPointOfSaleId
        && rate.checkStock === checkedCheckStock
        && rate.scheduled === checkedScheduled
        && rate.daysDelay === checkedDaysDelay
      ) {
        count += 1;
      }
    });
    return count;
  }

  // function to clean all inputs after success in submitted form
  function cleanAllInputs() {
    setSelectedPrice(0);
    setSelectedPointOfSale('');
    setTransferredPrice(translate('no'));
    setSelectedScheduled(translate('no'));
    setSelectedCheckStock(translate('yes'));
    setSelectedActive(translate('yes'));
    setSelectedActivationPrice(0);
    setSelectedTitle('');
    setSelectedSubtitle('');
    setDeliveryType('');
    setDeliveryLocal('');
    setDeliveryCommune('');
    setDeliveryCourier('');
    setSelectedDaysDelay(0);
  }

  // function to submit form
  async function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();
    setDisabledSubmitBtn(true);
    setLoading(true);
    if (deliveryType === '' && deliveryLocal === '' && deliveryCommune === '') {
      setLoading(false);
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle1'));
      setModalAlertText(translate('Modal.errorText1'));
    } else {
      const LocalId = (locals.find((local) => local.name === deliveryLocal) as ILocal).id;
      const CommuneId = (communes.find((commune) => commune.name === deliveryCommune) as ICommune).id;
      const CourierId = (couriers.find((courier) => courier.name === deliveryCourier) as ICourier).id;
      const PointOfSaleId = (pos.find((pointOfSale) => pointOfSale.name === selectedPointOfSale) as IPointOfSale).id;
      const deliveryRateExist = await verifyDeliveryRate(
        selectedActivationPrice,
        LocalId,
        CommuneId,
        deliveryType,
        CourierId,
        PointOfSaleId,
        selectedCheckStock === translate('yes'),
        selectedScheduled === translate('yes'),
        selectedDaysDelay,
      );
      if (deliveryRateExist > 0) {
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorTitle2'));
        setModalAlertText(translate('Modal.errorText2', {
          commune: deliveryCommune,
          local: deliveryLocal,
          shippingType: deliveryType,
          activatedAt: selectedActivationPrice,
          courier: deliveryCourier,
          pos: selectedPointOfSale,
        }));
      } else {
        const body = {
          price: transferredPrice === translate('yes') ? null : selectedPrice,
          activateAt: selectedActivationPrice,
          title: selectedTitle || null,
          subtitle: selectedSubtitle || null,
          CourierId,
          PointOfSaleId,
          LocalId,
          CommuneId,
          ClientId: user.connectableId,
          shippingType: deliveryType,
          checkStock: selectedCheckStock === translate('yes'),
          active: selectedActive === translate('yes'),
          scheduled: selectedScheduled === translate('yes'),
          daysDelay: selectedDaysDelay,
        };
        try {
          // Post delivery rate
          await api.createDeliveryRate(body);
          setLoading(false);
          cleanAllInputs();
          setOpenModalAlert(true);
          setModalAlertTitle(translate('Modal.successTitle'));
          setModalAlertText(translate('Modal.successText'));
        } catch (error) {
          setLoading(false);
          setOpenModalAlert(true);
          setModalAlertTitle(translate('Modal.errorTitle3'));
          setModalAlertText(translate('Modal.errorText3'));
        }
      }
    }
    setDisabledSubmitBtn(false);
  }

  // Set regions when selected country
  useEffect(() => {
    if (countries.length > 0) {
      const currentCountry = countries.find((country) => country.name === selectedCountry);
      if (currentCountry) {
        setRegions(currentCountry.Regions);
        setSelectedRegion(currentCountry.Regions[0].name);
      }
    }
  }, [selectedCountry]);

  // Set communes when set regions
  useEffect(() => {
    if (regions.length > 0) {
      const currentRegion = regions.find((reg) => reg.name === selectedRegion);
      if (currentRegion) {
        setCommunes(currentRegion.Communes);
        setDeliveryCommune(currentRegion.Communes[0].name);
      }
    }
  }, [selectedRegion]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      // set locals to show in form
      const allLocalsData = await api.getLocals();
      setLocals(allLocalsData.locals);
      // set couriers to show in form
      const allCouriers = await api.getCouriers();
      setCouriers(allCouriers);
      // set pos to show in form
      const allPos = await api.getClientPOS(user.connectableId);
      setPos(allPos);
      // set countries
      const allCountries = await api.getCountries({ filter: { ClientId: user.connectableId } });
      setCountries(allCountries);
      if (allCountries.length > 0) {
        setSelectedCountry(allCountries[0].name);
        setRegions(allCountries[0].Regions);
        setSelectedRegion(allCountries[0].Regions[0].name);
        setCommunes(allCountries[0].Regions[0].Communes);
        setDeliveryCommune(allCountries[0].Regions[0].Communes[0].name);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <div className="PageContainer">
      {loading ? (
        <LoadingPage />
      ) : (null)}
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      <div className="FormMainContainer">
        <GoBackButton
          goBackPath="/home/alldeliveryrates"
        />
        <form
          className="Form"
          onSubmit={handleSubmit}
        >
          <div className="FormTitle">
            {translate('title')}
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('pos')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={pos}
              selectedOption={selectedPointOfSale}
              SetSelectedOption={setSelectedPointOfSale}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('shippingType')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={shippingType}
              selectedOption={deliveryType}
              SetSelectedOption={setDeliveryType}
              lightStyle={false}
            />
          </div>
          <Divider />
          {countries.length > 1 && (
            <>
              <div className="FormContainer">
                <div className="FormLabel">
                  {translate('country')}
                </div>
                <SelectInput
                  styleWidth="180px"
                  title=""
                  array={countries}
                  selectedOption={selectedCountry}
                  SetSelectedOption={setSelectedCountry}
                  lightStyle={false}
                />
              </div>
              <Divider />
            </>
          )}
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('region')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={regions}
              selectedOption={selectedRegion}
              SetSelectedOption={setSelectedRegion}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('commune')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={communes}
              selectedOption={deliveryCommune}
              SetSelectedOption={setDeliveryCommune}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('local')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={locals}
              selectedOption={deliveryLocal}
              SetSelectedOption={setDeliveryLocal}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('courier')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={couriers}
              selectedOption={deliveryCourier}
              SetSelectedOption={setDeliveryCourier}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('transferredPrice')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={booleanOptions}
              selectedOption={transferredPrice}
              SetSelectedOption={setTransferredPrice}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('price')}
            </div>
            <NumericFormat
              disabled={transferredPrice === translate('yes')}
              className="TextInput"
              value={selectedPrice}
              allowNegative={false}
              decimalSeparator=","
              thousandSeparator="."
              onValueChange={(values) => setSelectedPrice(Number(values.value))}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('activateAt')}
            </div>
            <NumericFormat
              className="TextInput"
              value={selectedActivationPrice}
              allowNegative={false}
              decimalSeparator=","
              thousandSeparator="."
              onValueChange={(values) => setSelectedActivationPrice(Number(values.value))}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('rateTitle')}
            </div>
            <input
              className="TextInput"
              type="text"
              value={selectedSubtitle}
              onChange={(e) => setSelectedSubtitle(e.target.value)}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('subtitle')}
            </div>
            <input
              className="TextInput"
              type="text"
              value={selectedTitle}
              onChange={(e) => setSelectedTitle(e.target.value)}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('checkStock')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={booleanOptions}
              selectedOption={selectedCheckStock}
              SetSelectedOption={setSelectedCheckStock}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('active')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={booleanOptions}
              selectedOption={selectedActive}
              SetSelectedOption={setSelectedActive}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('scheduled')}
            </div>
            <SelectInput
              styleWidth="180px"
              title=""
              array={booleanOptions}
              selectedOption={selectedScheduled}
              SetSelectedOption={setSelectedScheduled}
              lightStyle={false}
            />
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('daysDelay')}
            </div>
            <NumericFormat
              className="TextInput"
              value={selectedDaysDelay}
              allowNegative={false}
              decimalSeparator=","
              thousandSeparator="."
              onValueChange={(values) => setSelectedDaysDelay(Number(values.value))}
            />
          </div>
          <Divider />
          <div className="FormFooter">
            <button
              className="GoBackButton"
              type="submit"
              disabled={disabledSubmitBtn}
            >
              {translate('createRate')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
