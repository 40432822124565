export const getOrderTypes = (limit: number | null, offset: number | null) => `
query getOrderTypes(
  $where: OrderTypes_bool_exp!,
) {
  OrderTypes(limit: ${limit}, offset: ${offset}, where: $where) {
    id
    name
  }
}
`;
