export const getProductPacks = (limit: number, offset: number) => `
query getProducts($where: Products_bool_exp!) {
  Products(limit: null, offset: ${offset},  where: {
    _and: [
      { activated: { _eq: true } },
      $where,
    ]
  }) {
      id
      barcode
      sku
      rawSku
      name
      isPack
      Components {
        amount
        Component {
          name
          rawSku
        }
      }
    }
    Products_aggregate( where: {
      _and: [
        { activated: { _eq: true } },
        $where,
      ]
    }) {
      aggregate {
        count
      }
    }
  }
`;
