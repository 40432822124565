export const getSizesVariables = ({
  filter,
}: {
  filter?: { [key: string]: string | number | number[] | boolean | string[] | { [key: string]: string | string[] } }
}) => {
  const { name } = filter || {};
  return {
    where: {
      _and: [
        {
          name: name ? {
            _ilike: `${name}`,
          } : {},
        },
      ],
    },
  };
};
