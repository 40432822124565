import LoadingIcon from '@ant-design/icons/LoadingOutlined';
import { useTranslate } from '../hooks';

function Loading() {
  const translate = useTranslate('Component.LoadingComponent');

  return (
    <div className="LoadingContainer">
      <LoadingIcon className="BlackIcon" />
      <div className="LoadingText">{translate('loading')}</div>
    </div>
  );
}

export default Loading;
