import NotionPage from '../components/NotionPageComponent';
import { useTranslate } from '../hooks';

export default function Information() {
  const translate = useTranslate('Page.Information');

  const data = [
    {
      id: 'b4fef83d33fc4d0b8eab43a72ea9d7dc',
      title: translate('cooler'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/cooler.jpg',
    },
    {
      id: '1e39792f803441c09540a7e95901dc0f',
      title: translate('deliveryProtocols'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/protocolo-entrega.jpg',
    },
    {
      id: '4be360aea481453baef88f5798fe8824',
      title: translate('deliveryRange'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/cobertura-delivery.jpg',
    },
    {
      id: '589542cba634421a9f7f55bf64b6f6f0',
      title: translate('deliveryRates'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/tarifas-delivery.jpg',
    },
    {
      id: 'pageWebList',
      title: translate('webPages'),
      img: 'https://clients-platform-assets.s3.amazonaws.com/img/paginas-web.jpg',
    },
  ];
  return (
    <NotionPage pageData={data} />
  );
}
