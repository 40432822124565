import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import IProduct from '../../interfaces/IProduct';
import { useTranslate } from '../../hooks';
import ProductPacksTableRow from './ProductPacksTableRow';

interface ProductsTableProps {
  rows: IProduct[];
  onChangeRequest: (rowsPerPage: number, page: number) => void;
  initialPageSze: number;
  initialPage: number;
  totalRowCount: number;
  loadingTable: boolean;
}

export default function ProductPacksTable({
  rows,
  onChangeRequest,
  initialPageSze,
  initialPage,
  totalRowCount,
  loadingTable,
}: ProductsTableProps) {
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialPageSze);
  const translate = useTranslate('Component.ProductPacksTable');

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    onChangeRequest(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    onChangeRequest(newRowsPerPage, 0);
  };

  return (
    <Paper sx={{ width: '90%' }}>
      <TableContainer sx={{ minHeight: 620, maxHeight: 620 }}>
        <Table
          stickyHeader
          aria-label="collapsible table"
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('sku')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('name')}
              </TableCell>
              <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                {translate('price')}
              </TableCell>
            </TableRow>
          </TableHead>
          {rows.length === 0 ? (
            <TableBody>
              <TableRow
                hover
                tabIndex={-1}
              >
                <TableCell
                  sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                  colSpan={7}
                >
                  {translate('noProducts')}
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {loadingTable ? (
                <TableRow
                  hover
                  tabIndex={-1}
                >
                  <TableCell
                    sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                    colSpan={8}
                  >
                    <LoadingOutlined />
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((product: IProduct) => (
                  <ProductPacksTableRow
                    key={product.id}
                    product={product}
                  />
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100, 500, { value: -1, label: translate('all') }]}
        component="div"
        count={totalRowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={translate('perPage')}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
