import {
  useEffect, useState, useContext, useRef,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import FilterOutlined from '@ant-design/icons/FilterOutlined';
import { NumericFormat } from 'react-number-format';
import AlertDialog from '../../components/HomeComponents/AlertDialog';
import { GlobalContext } from '../../store';
import ConfirmDialog from '../../components/HomeComponents/ConfirmDialog';
import LoadingPage from '../../components/LoadingPageComponent';
import IDeliveryRate from '../../interfaces/IDeliveryRate';
import IGetTasksStatus from '../../interfaces/IGetTasksStatus';
import SelectInput from '../../components/HomeComponents/SelectInputName';
import ILocal from '../../interfaces/ILocal';
import ICommune from '../../interfaces/ICommune';
import DeliveryRateTable from '../../components/DeliveryRateComponents/DeliveryRateTable';
import { useTranslate } from '../../hooks';
import { APIResourceOptions, IModalRef } from '../../interfaces';
import { Modal } from '../../components/HomeComponents/Modal';
import uploadFileToS3 from '../../helpers/uploadFileToS3';

const initialPageSize = 10;
const initialPage = 0;
interface IFilter {
  [key: string]: string | number | number[] | boolean | string[] | { [key: string]: string | string[] }
}

export default function AllDeliveryRates() {
  const navigate = useNavigate();
  const [deliveryRates, setDeliveryRates] = useState<IDeliveryRate[]>([]);
  const {
    context,
    api,
    apiFunctions,
    user,
  } = useContext(GlobalContext);
  const { setFilteredItemsToEdit } = context;
  const location = useLocation();
  let editSuccess = location.state?.editSuccess;
  const [deliveryRatesLength, setDeliveryRatesLength] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deliveryRatesToDeactivate, setDeliveryRatesToDeactivate] = useState<IDeliveryRate[]>([]);
  const [openConfirmAlert, setOpenConfirmAlert] = useState<boolean>(false);
  const [confirmDeactivate, setConfirmDeactivate] = useState<string>('');
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [deliveryType, setDeliveryType] = useState<string>('');
  const [deliveryLocal, setDeliveryLocal] = useState<string>('');
  const [deliveryCommune, setDeliveryCommune] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>('');
  const [deliveryActivationPrice, setDeliveryActivationPrice] = useState<number>();
  const [daysDelay, setDaysDelay] = useState<number>();
  const [locals, setLocals] = useState<ILocal[]>([]);
  const [communes, setCommunes] = useState<ICommune[]>([]);
  const [showCleanFilters, setShowCleanFilters] = useState<boolean>(false);
  const [selected, setSelected] = useState<number[]>([]);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [resetPage, setResetPage] = useState<boolean>(false);
  const [checkStock, setCheckStock] = useState<string>('');
  const [scheduled, setScheduled] = useState<string>('');
  const [active, setActive] = useState<string>('');
  const translate = useTranslate('Page.AllDeliveryRates');
  const [fetchOptions, setFetchOptions] = useState<APIResourceOptions>({
    rowsPerPage: initialPageSize,
    page: initialPage,
  });
  const booleanOptions = [
    { name: translate('yes') },
    { name: translate('no') },
  ];
  const shippingType = [
    { name: 'pickup' },
    { name: 'courier' },
    { name: 'express' },
    { name: 'same-day' },
  ];

  const filtersModalRef = useRef<IModalRef>(null);

  let retryTime = 20;
  let taskId: string | null = null;
  let delayTime = 3000;

  async function deactivateRates() {
    const response = await api.getDeliveryRates({
      filter: { id: selected },
      page: initialPage,
    });
    const activeRates = response.deliveryRates.filter((rate) => rate.active);
    // Check if there is minimun one rate to deactivate
    if (activeRates.length === 0) {
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.deactivateNoRatesTitle'));
      setModalAlertText(translate('Modal.deactivateNoRatesText'));
    } else {
      setDeliveryRatesToDeactivate(response.deliveryRates);
      setOpenConfirmAlert(true);
    }
  }

  // function to update the delivery rates based on the selected filters
  async function applyDeliveryRatesFilters() {
    const filter: IFilter = {};
    const checkFields = [
      deliveryType,
      deliveryCommune,
      deliveryLocal,
      deliveryActivationPrice,
      daysDelay,
      checkStock,
      scheduled,
      active,
      title,
      subtitle,
    ];
    if (checkFields.every((value) => value === '' || value === undefined)) { return; }
    setLoadingTable(true);
    if (deliveryType !== '') {
      filter.shippingType = deliveryType;
    }
    if (deliveryCommune !== '') {
      const commune = await api.getCommuneByName(deliveryCommune);
      filter.CommuneId = commune.id;
    }
    if (deliveryLocal !== '') {
      const foundLocal = locals.find((local) => local.name === deliveryLocal);
      if (foundLocal) {
        filter.LocalId = foundLocal?.id;
      }
    }
    if (deliveryActivationPrice !== undefined) {
      filter.activateAt = deliveryActivationPrice;
    }
    if (daysDelay !== undefined) {
      filter.daysDelay = daysDelay;
    }
    if (checkStock !== '') {
      filter.checkStock = checkStock === translate('yes');
    }
    if (scheduled !== '') {
      filter.scheduled = scheduled === translate('yes');
    }
    if (active !== '') {
      filter.active = active === translate('yes');
    }
    if (title !== '') {
      filter.title = title;
    }
    if (subtitle !== '') {
      filter.subtitle = subtitle;
    }
    setFetchOptions({
      ...fetchOptions,
      page: initialPage,
      filter: {
        ClientId: user.connectableId,
        ...filter,
      },
    } as APIResourceOptions);
    setResetPage(true);
    filtersModalRef.current?.close();
  }

  function deleteDeliveryRatesFilters() {
    if (selectAll) {
      setSelectAll(!selectAll);
    }
    setDeliveryType('');
    setDeliveryLocal('');
    setDeliveryCommune('');
    setCheckStock('');
    setScheduled('');
    setActive('');
    setTitle('');
    setSubtitle('');
    setDeliveryActivationPrice(0);
    setDaysDelay(0);
    setFetchOptions({
      filter: { ClientId: user.connectableId },
      rowsPerPage: initialPageSize,
      page: initialPage,
    });
    filtersModalRef.current?.close();
  }

  async function goToUpdate() {
    const response = await api.getDeliveryRates({
      filter: { id: selected },
      page: initialPage,
    });
    setDeliveryRatesToDeactivate(response.deliveryRates);
    setFilteredItemsToEdit(response.deliveryRates);
    navigate('/home/alldeliveryrates/update');
  }

  async function fetchDeliveryRates() {
    const response = await api.getDeliveryRates(fetchOptions);
    setDeliveryRates(response.deliveryRates);
    setDeliveryRatesLength(response.count);
  }

  async function getTaskResponse() {
    if (retryTime > 0) {
      // The client's tasks are obtained from dynamoDb
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      // If the answer was failed, the item creation was wrong
      if (res?.status === 'FAILED') {
        const responseMessage = res.executions.message;
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res?.status === 'SUCCESS') {
        // If the response was successful, the item creation was well done
        setDeliveryRatesToDeactivate([]);
        setSelected([]);
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.successTitle'));
        setModalAlertText(translate('Modal.successText'));
        await fetchDeliveryRates();
      } else if (res?.status === 'ERROR') {
        // If the answer was error, it did not find the creation of items in the queue
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorTitle'));
        setModalAlertText(translate('Modal.errorText'));
      } else {
        // This case is for when the status is PENDING, the timeout will re-execute the
        // function until one of the previous cases is triggered
        setTimeout(getTaskResponse, delayTime);
        delayTime = 15000;
      }
      retryTime -= 1;
    } else {
      setLoading(false);
      setOpenModalAlert(true);
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      if (res?.status === 'FAILED' || res?.status === 'ERROR') {
        const responseMessage = res.executions?.message || 'Unexpected error';
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res?.status === 'SUCCESS') {
        setDeliveryRatesToDeactivate([]);
        setSelected([]);
        setModalAlertTitle(translate('Modal.successTitle'));
        setModalAlertText(translate('Modal.successText'));
        await fetchDeliveryRates();
      } else {
        setModalAlertTitle(translate('Modal.noticeTitle'));
        setModalAlertText(translate('Modal.noticeText'));
      }
    }
  }

  function handleShowModal(value: boolean) {
    setShowModal(value);
  }

  function handleChangeRequest(newRowsPerPage: number, newPage: number) {
    setFetchOptions({
      ...fetchOptions,
      rowsPerPage: newRowsPerPage,
      page: newPage,
    });
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      // set locals to show in form
      const allLocalsData = await api.getLocals();
      setLocals(allLocalsData.locals);
      // set locals to show in form
      const allCommunes = await api.getCommunes();
      setCommunes(allCommunes);
      setLoading(false);
    })();
  }, [user.connectableId]);

  useEffect(() => {
    (async () => {
      setLoadingTable(true);
      await fetchDeliveryRates();
      setLoadingTable(false);
    })();
  }, [fetchOptions]);

  useEffect(() => {
    (async () => {
      if (confirmDeactivate === 'true') {
        // Set data
        setLoading(true);
        const bodyData = deliveryRatesToDeactivate.map((rate: IDeliveryRate) => ({
          ...rate,
          id: rate.id,
          active: false,
        }));
        // Upload data to s3
        const id = uuid();
        const fileToUpload = JSON.stringify(bodyData);
        const response = uploadFileToS3(
          fileToUpload,
          `massive/input/${id}`,
        );
        if (response.includes('Error')) {
          setLoading(false);
          setOpenModalAlert(true);
          setModalAlertTitle(translate('Modal.errorUploadingCsvTitle'));
          setModalAlertText(translate('Modal.errorUploadingCsvText', { error: response }));
        } else {
          const body = {
            queue: 'models/deliveryRates',
            event: 'update',
            clientId: user.connectableId,
            pathName: 'api/delivery-rates',
            processedItemsIds: [],
            cursor: 0,
            lambdasAlreadyProcessed: 0,
            fileName: id,
          };
          try {
            taskId = await apiFunctions.postEnqueueTask(body);
            if (taskId === null) {
              setOpenModalAlert(true);
              setModalAlertTitle(translate('Modal.failedTitle'));
              setModalAlertText(translate('Modal.failedText', { message: 'Unexpected error' }));
            } else {
              getTaskResponse();
            }
            setOpenConfirmAlert(false);
          } catch (error) {
            setLoading(false);
            setOpenConfirmAlert(false);
          }
        }
      }
    })();
    setConfirmDeactivate('');
  }, [confirmDeactivate]);

  useEffect(() => {
    (async () => {
      if (editSuccess) {
        await fetchDeliveryRates();
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.editSuccessTitle'));
        setModalAlertText(translate('Modal.editSuccessText'));
        editSuccess = false;
      }
    })();
  }, [editSuccess]);

  useEffect(() => {
    if (deliveryType !== '' || deliveryLocal !== '' || deliveryCommune !== '' || scheduled !== '' || active !== ''
    || checkStock !== '' || deliveryActivationPrice !== undefined || daysDelay !== undefined
    || title !== '' || subtitle !== '') {
      setShowCleanFilters(true);
    } else {
      setShowCleanFilters(false);
    }
  }, [deliveryType, deliveryLocal, deliveryCommune, deliveryActivationPrice, scheduled, active, checkStock,
    daysDelay, title, subtitle]);

  if (loading) {
    return (
      <div className="PageContainer">
        <LoadingPage />
      </div>
    );
  }

  return (
    <div className="FormHistoryContainer">
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      <ConfirmDialog
        data={deliveryRatesToDeactivate}
        type="rates"
        description={translate('confirmDialog', { ratesLength: deliveryRatesToDeactivate.length })}
        open={openConfirmAlert}
        setOpen={setOpenConfirmAlert}
        setConfirmData={setConfirmDeactivate}
      />
      <Modal
        ref={filtersModalRef}
      >
        <p className="Title">
          {translate('Filters.title')}
        </p>
        <div className="Center Gap">
          <SelectInput
            styleWidth="180px"
            title={translate('selectRateTitle')}
            array={shippingType}
            selectedOption={deliveryType}
            SetSelectedOption={setDeliveryType}
            lightStyle={false}
          />
          <SelectInput
            styleWidth="180px"
            title={translate('Filters.commune')}
            array={communes}
            selectedOption={deliveryCommune}
            SetSelectedOption={setDeliveryCommune}
            lightStyle={false}
          />
          <SelectInput
            styleWidth="180px"
            title={translate('Filters.local')}
            array={locals}
            selectedOption={deliveryLocal}
            SetSelectedOption={setDeliveryLocal}
            lightStyle={false}
          />
          <SelectInput
            styleWidth="180px"
            title={translate('Filters.scheduled')}
            array={booleanOptions}
            selectedOption={scheduled}
            SetSelectedOption={setScheduled}
            lightStyle={false}
          />
          <SelectInput
            styleWidth="180px"
            title={translate('Filters.active')}
            array={booleanOptions}
            selectedOption={active}
            SetSelectedOption={setActive}
            lightStyle={false}
          />
          <SelectInput
            styleWidth="180px"
            title={translate('Filters.checkStock')}
            array={booleanOptions}
            selectedOption={checkStock}
            SetSelectedOption={setCheckStock}
            lightStyle={false}
          />
          <NumericFormat
            className="NumericDeliveryRatesFilter"
            style={{ height: '30px' }}
            prefix="$"
            value={deliveryActivationPrice}
            allowNegative={false}
            decimalSeparator=","
            thousandSeparator="."
            placeholder={translate('Filters.activateAt')}
            onValueChange={(values: { value: string }) => setDeliveryActivationPrice(Number(values.value))}
          />
          <NumericFormat
            className="NumericDeliveryRatesFilter"
            style={{ height: '30px' }}
            value={daysDelay}
            allowNegative={false}
            decimalSeparator=","
            thousandSeparator="."
            placeholder={translate('Filters.daysDelay')}
            onValueChange={(values: { value: string }) => setDaysDelay(Number(values.value))}
          />
          <input
            className="TextInput"
            type="text"
            placeholder={translate('Filters.titlePlaceholder')}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <input
            className="TextInput"
            type="text"
            placeholder={translate('Filters.subtitlePlaceholder')}
            value={subtitle}
            onChange={(e) => setSubtitle(e.target.value)}
          />
        </div>
        <div className="Center Gap VerticalMargin">
          <div
            className="Button Primary"
            onClick={applyDeliveryRatesFilters}
          >
            {translate('Filters.apply')}
          </div>
          {showCleanFilters ? (
            <div
              className="Button Secondary"
              onClick={deleteDeliveryRatesFilters}
            >
              {translate('Filters.clean')}
            </div>
          ) : (null)}
        </div>
      </Modal>
      <div className="FormHistoryTitle">
        {translate('title')}
      </div>
      <div className="ItemsFiltersContainer">
        <div
          className="ItemsFiltersBtn"
          onClick={() => navigate('/home/alldeliveryrates/create')}
        >
          {translate('massiveCreate')}
        </div>
        <div
          className="ItemsFiltersBtn ItemsBtnMargin"
          onClick={() => navigate('/home/alldeliveryrates/unitarycreate')}
        >
          {translate('unitCreate')}
        </div>
        <div
          className="ItemsFiltersBtn ItemsBtnMargin"
          onClick={() => filtersModalRef.current?.show()}
        >
          <FilterOutlined className="LargeIcon" />
        </div>
      </div>
      <div
        className="EditTutorialText"
        onClick={
            () => window.open(
              'https://get-nomad.notion.site/Cambio-de-Tarifas-de-Env-o-0b6dfedd089649d7abfb9650128d75ec?pvs=4',
              '_blank',
            )
          }
      >
        {translate('editRatesTutorial')}
      </div>
      { showModal ? (
        <div className="UpdateItemsContainer">
          <div
            className="UpdateItemsBtns"
            onClick={() => goToUpdate()}
          >
            {translate('editRates')}
          </div>
          <div
            className="UpdateItemsBtns"
            onClick={() => deactivateRates()}
          >
            {translate('deactivateRates')}
          </div>
        </div>
      ) : (null) }
      <DeliveryRateTable
        rows={deliveryRates}
        totalRowCount={deliveryRatesLength}
        onChangeRequest={handleChangeRequest}
        initialPageSze={initialPageSize}
        initialPage={initialPage}
        showModal={handleShowModal}
        selected={selected}
        setSelected={setSelected}
        loadingTable={loadingTable}
        setResetPage={setResetPage}
        resetPage={resetPage}
      />
    </div>
  );
}
