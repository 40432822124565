import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLSubscription } from '@aws-amplify/api';
import { IShipmentTrackingData } from '../interfaces/ITracking';

interface IMessage {
  id: string;
  data: string; // string representation of a JSON
}

const onCreateMessage = (internalCode?: string) => /* GraphQL */ `
  subscription OnShipmentUpdate {
    onCreateMessage(topic: "Shipment#${internalCode}#update") {
      id
      data
    }
  }
`;

export function useShipmentSubscription(token: string, internalCode?: string) {
  const [shipmentTrackingData, setShipmentTrackingData] = useState<IShipmentTrackingData | null>(null);

  useEffect(() => {
    if (!internalCode) {
      return () => (null);
    }
    const subscription = (API
      .graphql<GraphQLSubscription<{ onCreateMessage: IMessage }>>(graphqlOperation(onCreateMessage(internalCode)), {
      Authorization: token,
    }) as Exclude<ReturnType<typeof API.graphql>, Promise<unknown>>)
      .subscribe({
        next: ({ value }: { value: { data: { onCreateMessage: IMessage } } }) => {
          const shipmentTracking: IShipmentTrackingData = JSON.parse(value.data.onCreateMessage.data);
          setShipmentTrackingData(shipmentTracking);
        },
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [internalCode]);

  return {
    shipmentTrackingData,
    setShipmentTrackingData,
  };
}
