import { useMemo, useReducer } from 'react';

// Store
import GlobalContext from './GlobalContext';
import { clientDefaultState, reducer } from './reducer';

// Interfaces
import { ContextInterface, IUser, StateInterface } from '../interfaces';

// Hooks
import { useLocalStorage } from '../hooks';

// Components
import { Api } from '../services';
import LoadingPage from '../components/LoadingPageComponent';
import GetGlobalContext from './GetGlobalContex';
import IClient from '../interfaces/IClient';
import { ApiFunctions } from '../apiRequester/ApiFunctions';

interface StoreWrapperProps {
  children: JSX.Element | JSX.Element[];
  api: Api;
  apiFunctions: ApiFunctions;
  user: IUser;
  client: IClient;
}

function StoreWrapper({
  children, api, apiFunctions, user, client,
}: StoreWrapperProps) {
  // Load local storage to app state
  const [localStorage] = useLocalStorage('state', clientDefaultState);
  const [state, dispatch] = useReducer(reducer, localStorage);
  // Can receive an api param
  // const globalContext = GetGlobalContext(dispatch, api);
  const globalContext = GetGlobalContext(dispatch);

  // Provider value
  const providerValue = useMemo<{
    state: StateInterface; context: ContextInterface; api: Api; apiFunctions: ApiFunctions; user: IUser; client: IClient
  }>(() => (
    {
      state,
      context: globalContext,
      api,
      apiFunctions,
      user,
      client,
    }
  ), [state]);

  return (
    <GlobalContext.Provider value={providerValue}>
      {children}
      {(state.loading > 0) && (
        <LoadingPage full />
      )}
    </GlobalContext.Provider>
  );
}

export default StoreWrapper;
