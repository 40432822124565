import { useNavigate } from 'react-router-dom';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import { useTranslate } from '../../hooks';

interface Props {
  goBackPath: string;
}

function GoBackButton(props: Props) {
  const { goBackPath } = props;
  const navigate = useNavigate();
  const translate = useTranslate('Component.GoBackButton');

  function handleGoBack() {
    navigate(`${goBackPath}`);
  }
  return (
    <div className="PageContainerGoBack">
      <div
        className="GoBackIcon"
        onClick={handleGoBack}
      >
        <LeftOutlined />
      </div>
      <div
        className="GoBackText"
        onClick={handleGoBack}
      >
        {translate('goBack')}
      </div>
    </div>
  );
}

export default GoBackButton;
