export const config = {
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APP_SYNC_URL,
  aws_appsync_region: process.env.REACT_APP_COGNITO_REGION,
  aws_appsync_authenticationType: 'AWS_LAMBDA',
};
