import {
  useState, useEffect, useContext, SyntheticEvent,
} from 'react';
import { Divider } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { GlobalContext } from '../../store';
import DropFile from '../../components/HomeComponents/DropFile';
import AlertDialog from '../../components/HomeComponents/AlertDialog';
import 'react-datepicker/dist/react-datepicker.css';
import NavigableTable from '../../NavigableTable/NavigableTable';
import LoadingPage from '../../components/LoadingPageComponent';
import { ICellData, IColumnData, IRowData } from '../../interfaces/INavigableTableObjects';
import IPackComponentCreate from '../../interfaces/IPackComponentCreate';
import downloadFileFromS3 from '../../helpers/downloadFileFromS3';
import GoBackButton from '../../components/HomeComponents/GoBackButton';
import uploadCsvData from '../../helpers/uploadCsvData';
import IGetTasksStatus from '../../interfaces/IGetTasksStatus';
import { useTranslate } from '../../hooks';
import uploadFileToS3 from '../../helpers/uploadFileToS3';

export default function ProductsCreatePack() {
  const { api, apiFunctions, user } = useContext(GlobalContext);
  const [tableColumns, setTableColumns] = useState<string[]>([]);
  const [rowsValues, setRowsValues] = useState<unknown[]>([]);
  const [dataToShow, setDataToShow] = useState<IRowData[]>([]);
  const [columns, setColumns] = useState<IColumnData[]>([]);
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [createProductsSubmited, setCreateProductsSubmited] = useState<boolean>(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const translate = useTranslate('Page.ProductsCreatePack');

  const columnsShouldHave = [
    translate('CSVColumns.packSku'),
    translate('CSVColumns.componentsSku'),
    translate('CSVColumns.amount'),
  ];
  let taskId: string | null = null;
  let retryTime = 20;
  let delayTime = 3000;

  function cleanUploadedDocument() {
    setDataToShow([]);
    setCreateProductsSubmited(true);
  }

  async function getTaskResponse() {
    if (retryTime > 0) {
      // The client's 'products' tasks are obtained from dynamoDb
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      // If the answer was failed, the product creation was wrong
      if (res.status === 'FAILED') {
        const responseMessage = res.executions.message;
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res.status === 'SUCCESS') {
        // If the response was successful, the product creation was well done
        cleanUploadedDocument();
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.successTitle'));
        setModalAlertText(translate('Modal.successText'));
      } else if (res.status === 'ERROR') {
        // If the answer was error, it did not find the creation of products in the queue
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorTitle5'));
        setModalAlertText(translate('Modal.errorText5'));
      } else {
        // This case is for when the status is PENDING, the timeout will re-execute the
        // function until one of the previous cases is triggered
        // After the first execution, the delayTime is changed to 15 sec to give a
        // a longer response time for product creation
        setTimeout(getTaskResponse, delayTime);
        delayTime = 15000;
      }
      retryTime -= 1;
    } else {
      setOpenModalAlert(true);
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      if (res?.status === 'FAILED' || res?.status === 'ERROR') {
        const responseMessage = res.executions?.message || 'Unexpected error';
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res?.status === 'SUCCESS') {
        cleanUploadedDocument();
        setModalAlertTitle(translate('Modal.successTitle'));
        setModalAlertText(translate('Modal.successText'));
      } else {
        setModalAlertTitle(translate('Modal.noticeTitle'));
        setModalAlertText(translate('Modal.noticeText'));
      }
    }
  }

  async function submitProducts(e: SyntheticEvent) {
    e.preventDefault();
    setLoading(true);
    setDisabledSubmitBtn(true);
    const getProducts = await api.getClientProducts({});
    const { products } = getProducts;
    const packs: IPackComponentCreate[] = [];
    const packsMissingInfo: string[] = [];
    const productsNotFound: string[] = [];
    let productNotPack = false;
    dataToShow.forEach((pack: IRowData) => {
      if (pack.row[1].value === '' || pack.row[2].value === '' || pack.row[3].value === '') {
        packsMissingInfo.push(pack.row[0].value as string);
      }
      const packProduct = products.find((p) => p.rawSku === (pack.row[1].value as string));
      const componentProduct = products.find((p) => p.rawSku === (pack.row[2].value as string));
      if (!packProduct) {
        productsNotFound.push(pack.row[1].value as string);
      } else if (!componentProduct) {
        productsNotFound.push(pack.row[2].value as string);
      } else if (!packProduct.isPack) {
        productNotPack = true;
      } else {
        const packsData = {
          PackId: packProduct.id,
          ComponentId: componentProduct.id,
          amount: Number(pack.row[3].value),
        };
        packs.push(packsData);
      }
    });
    setLoading(false);
    // Check if products doesn't repeat
    if (productsNotFound.length > 0) {
      const notFoundSkus = productsNotFound.join(', ');
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle3'));
      setModalAlertText(translate('Modal.errorText3', { message: notFoundSkus }));
    } else if (productNotPack) {
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle4'));
      setModalAlertText(translate('Modal.errorText4'));
    } else if (packsMissingInfo.length > 0) {
      // Check if all required info is given
      const missingData = packsMissingInfo.join(', ');
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle1'));
      setModalAlertText(translate('Modal.errorText1', { message: missingData }));
    } else {
      // Upload data to s3
      const id = uuid();
      const fileToUpload = JSON.stringify(packs);
      const res = uploadFileToS3(
        fileToUpload,
        `massive/input/${id}`,
      );
      if (res.includes('Error')) {
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorUploadingCsvTitle'));
        setModalAlertText(translate('Modal.errorUploadingCsvText', { error: res }));
      } else {
        const body = {
          queue: 'models/packComponents',
          event: 'create',
          clientId: user.connectableId,
          pathName: 'api/pack-components',
          processedItemsIds: [],
          cursor: 0,
          lambdasAlreadyProcessed: 0,
          fileName: id,
        };
        try {
          taskId = await apiFunctions.postEnqueueTask(body);
          if (taskId === null) {
            setLoading(false);
            setOpenModalAlert(true);
            setModalAlertTitle(translate('Modal.errorTitle2'));
            setModalAlertText(translate('Modal.errorText2'));
          } else {
            getTaskResponse();
            setOpenModalAlert(true);
            setModalAlertTitle(translate('Modal.sentTitle'));
            setModalAlertText(translate('Modal.sentText'));
          }
        } catch (error) {
          setOpenModalAlert(true);
          setModalAlertTitle(translate('Modal.errorTitle2'));
          setModalAlertText(translate('Modal.errorText2'));
          setDisabledSubmitBtn(false);
        }
      }
    }
    setDisabledSubmitBtn(false);
  }
  useEffect(() => {
    const defineColumns: IColumnData[] = [];
    defineColumns.push({ title: translate('Columns.number'), type: 'number', editable: false });
    tableColumns.forEach((columnName: string) => {
      if (columnName === translate('Columns.amount')) {
        defineColumns.push({ title: columnName, type: 'number', editable: true });
      } else {
        defineColumns.push({ title: columnName, type: 'text', editable: true });
      }
    });
    setColumns(defineColumns);
    const list: IRowData[] = [];
    rowsValues.forEach((data: unknown, idx: number) => {
      const cellData: ICellData[] = [];
      cellData.push({ index: 0, value: idx + 1, isDisable: false });
      (data as string[]).forEach((value: string, index: number) => {
        cellData.push({ index: index + 1, value, isDisable: false });
      });
      const rowData: IRowData = { index: idx, row: cellData };
      list.push(rowData);
    });
    setDataToShow(list);
  }, [tableColumns, rowsValues]);

  return (
    <div className="PageContainer">
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      {loading ? (
        <LoadingPage />
      ) : (
        <div className="FormMainContainer">
          <GoBackButton
            goBackPath="/home/productspack"
          />
          <form
            className="Form"
            onSubmit={submitProducts}
          >
            <div className="FormTitle">
              {translate('title')}
            </div>
            <Divider />
            <div className="FormContainer">
              <div className="FormLabel">
                {translate('loadCSV')}
                <div
                  className="UploadTutorialText"
                  onClick={
                    () => window.open(
                      'https://get-nomad.notion.site/Asignar-SKU-a-Packs-en-Nomad-'
                      + '986a1f6138c945cc8752bb7264904b94?pvs=4',
                      '_blank',
                    )
                  }
                >
                  {translate('bulkUploadTutorial')}
                </div>
              </div>
              <div className="FileUploadItemsContainer">
                {/* File Uploader */}
                <DropFile
                  changeHandler={
                    (file: File) => uploadCsvData(
                      file,
                      columnsShouldHave,
                      setOpenModalAlert,
                      setModalAlertTitle,
                      setModalAlertText,
                      setTableColumns,
                      setRowsValues,
                      translate,
                    )
                  }
                  createItemsSubmitted={createProductsSubmited}
                  setCreateItemsSubmitted={setCreateProductsSubmited}
                />
                <div className="UploadItemsText">
                  {translate('download1')}
                  <div
                    onClick={() => downloadFileFromS3(
                      'Planillas/Ejemplo planilla creación packs.csv',
                      'text/csv;charset=utf-8',
                    )}
                    className="UploadItemsTemplate"
                  >
                    {translate('download2')}
                  </div>
                  {translate('download3')}
                </div>
              </div>
            </div>
            <Divider />
            {dataToShow.length > 0 ? (
              <>
                <div className="FormContainerItems">
                  <NavigableTable
                    dataToShow={dataToShow}
                    setDataToShow={setDataToShow}
                    columns={columns}
                    loading={false}
                  />
                </div>
                <div className="FormFooter">
                  <button
                    className="GoBackButton"
                    type="submit"
                    disabled={disabledSubmitBtn}
                  >
                    {translate('createPacks')}
                  </button>
                </div>
              </>
            ) : (null)}
          </form>
        </div>
      )}
    </div>
  );
}
