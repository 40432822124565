import React from 'react';

interface Props {
  text: string;
  switchButton: boolean;
  toggleSwitchBtn: () => void;
}

function SwitchButton(props: Props) {
  const {
    text, switchButton, toggleSwitchBtn,
  } = props;
  return (
    <div className="CheckboxContainer">
      <span className="checkbox">
        <input
          type="checkbox"
          checked={switchButton}
        />
        <span
          className="wrapper"
          onClick={toggleSwitchBtn}
        >
          <span className="knob" />
        </span>
      </span>
      <div>{text}</div>
    </div>
  );
}

export default SwitchButton;
