import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import { IAddress, IOrder } from '../../../interfaces/IOrder';
import { GlobalContext } from '../../../store';
import { useTranslate } from '../../../hooks';
import IAvailableRatesRequestBody from '../../../interfaces/IAvailableRatesRequestBody';
import IAvailableRates from '../../../interfaces/IAvailableRates';
import { IRowData } from '../../../interfaces/INavigableTableObjects';
import Loading from '../../../components/LoadingComponent';
import textInputToNumber from '../../../helpers/textInputToNumber';

interface Props {
  goPreviousPage: () => void;
  order: Partial<IOrder>;
  orderDetails: IRowData[];
  setOrder: React.Dispatch<React.SetStateAction<Partial<IOrder>>>;
  setRateId: React.Dispatch<React.SetStateAction<number | null>>;
  rateId: number | null;
  setOpenAlertDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setModalAlertTitle: React.Dispatch<React.SetStateAction<string>>;
  setModalAlertText: React.Dispatch<React.SetStateAction<string>>;
}

export default function Rates(props: Props) {
  const {
    order, setOrder, goPreviousPage, orderDetails, setRateId, rateId, setOpenAlertDialog,
    setModalAlertTitle, setModalAlertText,
  } = props;
  const navigate = useNavigate();
  const translate = useTranslate('Page.Withdrawals');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedProgrammedTime] = useState<string | null>(order.pat as string);
  const [availableRates, setAvailableRates] = useState<IAvailableRates[]>([]);
  const [disable, setDisable] = useState<boolean>(true);
  const { api } = useContext(GlobalContext);

  async function handleSubmit() {
    setLoading(true);
    order.DeliveryRateId = rateId;
    try {
      // Post order type withdrawal
      const withdrawalOrder = await api.createOrder(order);
      setLoading(false);
      if (withdrawalOrder) {
        navigate(`/home/withdrawals/${withdrawalOrder.id}`, {
          state: {
            withdrawalOrder,
          },
        });
      }
    } catch (error) {
      let message = translate('ProductStep.errorMessage');
      if (error instanceof Error) {
        message = error.message;
      }
      order.OrderDetails = [];
      setLoading(false);
      setOpenAlertDialog(true);
      setModalAlertTitle(translate('ProductStep.modalErrorTitle'));
      setModalAlertText(`${translate('ProductStep.modalErrorDescription')} ${message},`
      + ` ${translate('ProductStep.modalErrorDescriptionContact')}`);
    }
  }

  function setRateData(rate?: IAvailableRates) {
    setDisable(false);
    if (!rate) {
      setOrder({
        ...order, shippingType: 'pickup', shippingCost: 0, pat: selectedProgrammedTime,
      });
      setRateId(null);
      return;
    }
    setOrder({
      ...order,
      shippingType: rate.shippingType,
      shippingCost: rate.price,
      pat: selectedProgrammedTime,
      CourierId: rate.courierId,
    });
    setRateId(rate.id);
  }

  useEffect(() => {
    (async () => {
      if (availableRates.length === 0) {
        setLoading(true);
        const addedProducts = orderDetails
          ?.filter((product) => (product.row[1].value as number) > 0);
        const items = addedProducts.map((detail: IRowData) => {
          const str = detail.row[0].value as string;
          const lastIndex = str.lastIndexOf('||');
          const rawSku = str.slice(0, lastIndex);
          return {
            sku: rawSku,
            quantity: detail.row[1].value as number,
            price: detail.row[2].value as number,
          };
        });
        const request: IAvailableRatesRequestBody = {
          communeId: null,
          shippingAddress: order.shippingAddress as IAddress,
          pointOfSaleId: order.PointOfSaleId as number,
          items,
          options: { shippingTypes: ['pickup', 'same-day'], courierId: 7 },
        };
        const response: IAvailableRates[] = await api.getAvailableDeliveryRates(request);
        const filteredRates = response
          .filter((rate) => rate.Local.id === order.LocalId);
        setAvailableRates(filteredRates);
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <div className="InfoTableContainer">
        <div className="Column RateLeft">
          <div className="ColumnTitle">
            {translate('RateStep.subtitle')}
          </div>
          {loading && (
            <Loading />
          )}
          {!loading && (
            <div className="ItemsContainer">
              {availableRates.map((rate) => (
                <div
                  className="ItemWithPrice"
                  key={rate.id}
                >
                  <div
                    className="FlexRow"
                    style={{ width: '80%' }}
                  >
                    <input
                      type="radio"
                      value={rate.title}
                      checked={rateId === rate.id}
                      onChange={() => setRateData(rate)}
                    />
                    <div className="RateDescriptionContainer">
                      <div className="Title">
                        {rate.title}
                      </div>
                      <div className="Label">
                        {rate.description}
                      </div>
                    </div>
                  </div>
                  <div className="RatePrice">
                    {rate.price === 0 ? translate('RateStep.free')
                      : `$ ${textInputToNumber(rate.price.toString(), true)}`}
                  </div>
                </div>
              ))}
              {availableRates.filter((rate) => rate.shippingType === 'pickup').length === 0 && (
                <div
                  className="ItemWithPrice"
                  key={null}
                >
                  <div
                    className="FlexRow"
                    style={{ width: '80%' }}
                  >
                    <input
                      type="radio"
                      value="Pickup estandar"
                      checked={rateId === null}
                      onChange={() => setRateData()}
                    />
                    <div className="RateDescriptionContainer">
                      <div className="Title">
                        {translate('RateStep.standardPickupTitle')}
                      </div>
                      <div className="Label">
                        {translate('RateStep.standardPickupDescription')}
                      </div>
                    </div>
                  </div>
                  <div className="RatePrice">
                    {translate('RateStep.free')}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="BottomRow">
        <div
          className="GoBackOrderPage"
          onClick={goPreviousPage}
        >
          <LeftOutlined className="RightArrow" />
          <div>
            {translate('RateStep.goBackButton')}
          </div>
        </div>
        <div
          className={`Button Primary ${disable ? 'Disable' : ''}`}
          onClick={handleSubmit}
        >
          {translate('RateStep.goNextButton')}
        </div>
      </div>
    </>
  );
}
