import { IColumnData, IRowData } from '../interfaces/INavigableTableObjects';

function keyPressEvent(
  e: KeyboardEvent,
  currentRow: number,
  currentCol: number,
  allData: IRowData[],
  columns: IColumnData[],
  updateLastCell: (last: number[]) => void,
  updateFirstCell: (first: number[]) => void,
): [number | null, number | null] {
  const tableLen = allData.length;
  if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'ArrowDown') {
    updateLastCell([tableLen - 1, currentCol]);
    return [tableLen - 1, currentCol];
  }
  if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'ArrowUp') {
    updateLastCell([0, currentCol]);
    return [0, currentCol];
  }
  if (e.key === 'ArrowUp' && !e.shiftKey && currentRow !== 0) {
    if (!allData[currentRow - 1].row[currentCol].isDisable) {
      updateFirstCell([currentRow - 1, currentCol]);
      updateLastCell([currentRow - 1, currentCol]);
      return [currentRow - 1, currentCol];
    }
  }
  if (e.key === 'ArrowDown' && !e.shiftKey && currentRow + 1 < tableLen) {
    if (!allData[currentRow + 1].row[currentCol].isDisable) {
      updateFirstCell([currentRow + 1, currentCol]);
      updateLastCell([currentRow + 1, currentCol]);
      return [currentRow + 1, currentCol];
    }
  }
  if (e.key === 'ArrowDown' && e.shiftKey && currentRow + 1 < tableLen) {
    // shift + down arrow pressed
    e.preventDefault();
    if (!allData[currentRow + 1].row[currentCol].isDisable) {
      updateLastCell([currentRow + 1, currentCol]);
      return [currentRow + 1, currentCol];
    }
  }
  if (e.key === 'ArrowUp' && e.shiftKey && currentRow !== 0) {
    // shift + up arrow pressed
    e.preventDefault();
    if (!allData[currentRow - 1].row[currentCol].isDisable) {
      updateLastCell([currentRow - 1, currentCol]);
      return [currentRow - 1, currentCol];
    }
  }
  if (e.key === 'Tab') {
    e.preventDefault();
    let col = currentCol;
    for (let row = currentRow; row < tableLen; row += 1) {
      for (let newCol = col + 1; newCol < columns.length; newCol += 1) {
        if (!allData[row].row[newCol].isDisable) {
          updateFirstCell([row, newCol]);
          updateLastCell([row, newCol]);
          return [row, newCol];
        }
      }
      col = -1;
    }
  } if (e.key === 'Enter' && currentRow + 1 < tableLen) {
    e.preventDefault();
    if (!allData[currentRow + 1].row[currentCol].isDisable) {
      updateFirstCell([currentRow + 1, currentCol]);
      updateLastCell([currentRow + 1, currentCol]);
      return [currentRow + 1, currentCol];
    }
  }
  return [null, null];
}

export default keyPressEvent;
