import { useState, useEffect, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslate } from '../../hooks';
import SelectInput from '../HomeComponents/SelectInputName';
import ILocal from '../../interfaces/ILocal';
import { GlobalContext } from '../../store';
import joinLocalsFilters from '../../helpers/joinLocalsFilters';
import { SelectCheckboxInput } from '../HomeComponents/SelectCheckboxInput';

interface SalesReportProps {
  reportUrl: string;
}

export default function OrderDetailsReport({
  reportUrl,
}: SalesReportProps) {
  const translate = useTranslate('Page.OrderDetail');
  const { user } = useContext(GlobalContext);
  const [url, setUrl] = useState<string>('');
  const [allLocals, setAllLocals] = useState<ILocal[]>([]);
  const [selectedLocals, setSelectedLocals] = useState<ILocal[]>([]);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [selectedPlatform, setSelectedPlatform] = useState<string>('');
  const [selectedInitDate, setSelectedInitDate] = useState<Date>(
    new Date((new Date()).setMonth((new Date()).getMonth() - 1)),
  ); // one month ago
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const shippingType = [
    { name: 'pickup' },
    { name: 'courier' },
    { name: 'express' },
    { name: 'same-day' },
    { name: 'shopper' },
  ];
  const status = [
    { name: 'En Revisión' },
    { name: 'Cancelado' },
    { name: 'Entregado' },
    { name: 'En Camino' },
    { name: 'Por Entregar' },
    { name: 'Por Pickear' },
  ];
  const platform = [
    { name: 'clients-platform' },
    { name: 'cornershop' },
    { name: 'generic' },
    { name: 'justo' },
    { name: 'mercado libre' },
    { name: 'pedidos ya' },
    { name: 'rappi' },
    { name: 'shopify' },
    { name: 'shopify-if' },
    { name: 'ubereats' },
    { name: 'woocommerce' },
  ];
  const {
    api,
  } = useContext(GlobalContext);
  const iframeStyle = {
    border: 'none',
    borderStyle: 'none',
    width: '100%',
    height: '90vh',
  };
  useEffect(() => {
    const clientFilter = user.connectableId.toString();
    const localFilterTitle = 'Local';
    const joinedString = joinLocalsFilters(selectedLocals.map((el) => el.id), localFilterTitle);
    setUrl(`${reportUrl}?Cliente=`
    + `${clientFilter}&${joinedString}`
    + `&Status=${encodeURIComponent(selectedStatus)}`
    + `&TipoEnvio=${encodeURIComponent(selectedDeliveryType)}`
    + `&Plataforma=${encodeURIComponent(selectedPlatform)}`
    + `${selectedInitDate
      ? `&date_from=${encodeURIComponent(
        `${selectedInitDate.toISOString().split('T')[0]}`,
      )}` : ''}`
    + `${selectedEndDate
      ? `&date_to=${encodeURIComponent(
        `${selectedEndDate.toISOString().split('T')[0]}`,
      )}` : ''}`
    + '#hide_parameters=Cliente,Local,TipoEnvio,Plataforma,date_from,date_to,fulfillment,Status');
  }, [
    selectedLocals, selectedPlatform, selectedDeliveryType, selectedInitDate, selectedEndDate,
    selectedStatus,
  ]);
  useEffect(() => {
    (async () => {
      const { locals } = await api.getLocals();
      setAllLocals(locals);
    })();
  }, []);

  return (
    <div className="PageContainer ReportFilterDisplay">
      <div
        className="ReportFilterContainerNoFlex"
      >
        <div
          className="FilterContainer"
        >
          <h5>{translate('dateFromTitle')}</h5>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={selectedInitDate}
            onChange={(date: Date) => setSelectedInitDate(date)}
            className="ReportFilterDatePicker"
            placeholderText={translate('initDatePlaceholder')}
          />
          <h5>{translate('dateToTitle')}</h5>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={selectedEndDate}
            onChange={(date: Date) => setSelectedEndDate(date)}
            className="ReportFilterDatePicker"
            placeholderText={translate('endDatePlaceholder')}
          />
          <SelectCheckboxInput
            data={allLocals}
            inputTitle={translate('locals')}
            keyValue="id"
            checkedValues={selectedLocals}
            displayValue="name"
            setChecked={setSelectedLocals}
          />
          <SelectInput
            title={translate('selectPlatformTitle')}
            array={platform}
            selectedOption={selectedPlatform}
            SetSelectedOption={setSelectedPlatform}
            lightStyle
          />
          <SelectInput
            title={translate('selectShippingTypeTitle')}
            array={shippingType}
            selectedOption={selectedDeliveryType}
            SetSelectedOption={setSelectedDeliveryType}
            lightStyle
          />
          <SelectInput
            title={translate('selectStatusTitle')}
            array={status}
            selectedOption={selectedStatus}
            SetSelectedOption={setSelectedStatus}
            lightStyle
          />
        </div>
      </div>
      <iframe
        title={translate('title')}
        src={url}
        style={iframeStyle}
      />
    </div>
  );
}
