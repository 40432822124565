interface TimelineProps {
  data: {
    fill: boolean;
    title: string;
  }[];
}

export function Timeline(props: TimelineProps) {
  const { data } = props;
  return (
    <div className="Timeline">
      <div className="Body">
        <div className="Line" />
        <div
          className="Item"
          key={data[0].title}
        >
          <div className={`Dot ${(data[0].fill) ? 'Fill' : ''}`} />
          <p className="DotLabel">{data[0].title}</p>
        </div>
        <div
          className="Item"
          key={data[1].title}
        >
          <div className={`Dot Small ${(data[1].fill) ? 'Fill' : ''}`} />
          <p className="DotLabel Small">{data[1].title}</p>
        </div>
        <div
          className="Item"
          key={data[2].title}
        >
          <div className={`Dot ${(data[2].fill) ? 'Fill' : ''}`} />
          <p className="DotLabel">{data[2].title}</p>
        </div>
      </div>
    </div>
  );
}
