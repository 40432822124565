import { useEffect, useState, useRef } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '../../hooks';
import '../../css/Tracking.css';
import { Api } from '../../services';
import AlertDialog from '../../components/HomeComponents/AlertDialog';
import { Modal } from '../../components/HomeComponents/Modal';
import { IModalRef } from '../../interfaces';

export default function TrackingIndex() {
  const translate = useTranslate('Tracking');
  const [internalCode, setInternalCode] = useState<string>('');
  const [disable, setDisable] = useState<boolean>(true);
  const api = new Api('');
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const helpModalRef = useRef<IModalRef>(null);
  const { shutdown } = useIntercom();
  const navigate = useNavigate();

  // Verification required inputs
  useEffect(() => {
    if (internalCode.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [internalCode]);

  useEffect(() => {
    shutdown();
  }, []);

  async function signTrackingCode() {
    try {
      const signature = await api.getTrackingSignature(internalCode);
      navigate(`/tracking/${internalCode}?signature=${signature}`);
    } catch (err) {
      setOpenModalAlert(true);
      setModalAlertTitle(translate('IndexModal.alertTitle'));
      setModalAlertText(translate('IndexModal.alertText'));
    }
  }

  return (
    <div className="TrackingIndexView">
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      <Modal
        ref={helpModalRef}
        onClose={() => {}}
      >
        <div className="HelpContent">
          <h2>{translate('helpModalTitle')}</h2>
          <p>{translate('trackingCodeHelpText')}</p>
          <img
            src="https://clients-platform-assets.s3.amazonaws.com/img/internalCodeMailHelp.png"
            alt="Example"
            style={{ width: '100%', marginTop: '20px' }}
          />
          <div
            className="AdditionalInfo"
            style={{ marginTop: '20px' }}
          >
            <h3>{translate('helpModalExtraChannelTitle')}</h3>
            <p>{translate('helpModalExtraChannelText')}</p>
            <img
              src="https://clients-platform-assets.s3.amazonaws.com/img/internalCodeWhatsappHelp.png"
              alt="Example"
              style={{ width: '40%', marginTop: '20px' }}
            />
          </div>
        </div>
      </Modal>
      <div className="TrackingIndexContainer">
        <div className="TrackingLogoContainer">
          <img
            className="NomadTitleLogo"
            alt="Nomad"
            src="https://nomad-assets-1.s3.amazonaws.com/public/NomadLogoBlack.png"
          />
        </div>
        <h2 className="TrackingSubtitle">{translate('orderTracking')}</h2>
        <div className="TrackingFormContainer">
          <div className="DirectionLabel">{translate('trackingCode')}</div>
          <input
            className="TrackingTextInput"
            type="text"
            value={internalCode}
            placeholder="Ej: 123456-NOM-EX-4321"
            onChange={(e) => setInternalCode(e.target.value)}
          />
        </div>
        <br />
        <div
          className={`NavLoginBtn ${disable ? 'Disable' : ''}`}
          onClick={signTrackingCode}
        >
          {translate('submitForm')}
        </div>
        <div className="HelpSection">
          <div
            className="HelpText"
            onClick={() => {
              if (helpModalRef.current) {
                helpModalRef.current.show();
              }
            }}
          >
            {translate('helpModalTitle')}
          </div>
        </div>
      </div>
    </div>
  );
}
