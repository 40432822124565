import {
  useState, useEffect, useContext, SyntheticEvent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import UncheckedIcon from '@ant-design/icons/MinusSquareOutlined';
import { GlobalContext } from '../../store';
import AlertDialog from '../../components/HomeComponents/AlertDialog';
import NavigableTable from '../../NavigableTable/NavigableTable';
import { ICellData, IColumnData, IRowData } from '../../interfaces/INavigableTableObjects';
import IDeliveryRate from '../../interfaces/IDeliveryRate';
import LoadingPage from '../../components/LoadingPageComponent';
import GoBackButton from '../../components/HomeComponents/GoBackButton';
import IGetTasksStatus from '../../interfaces/IGetTasksStatus';
import { useTranslate } from '../../hooks';
import uploadFileToS3 from '../../helpers/uploadFileToS3';

export default function EditDeliveryRates() {
  const {
    state, api, apiFunctions, user,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [dataToShow, setDataToShow] = useState<IRowData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const translate = useTranslate('Page.EditDeliveryRates');

  let taskId: string | null = null;
  let retryTime = 20;
  let delayTime = 3000;

  const columns: IColumnData[] = [
    { title: translate('Columns.number'), type: 'number', editable: false },
    { title: translate('Columns.price'), type: 'number', editable: true },
    { title: translate('Columns.activateAt'), type: 'number', editable: true },
    { title: translate('Columns.title'), type: 'text', editable: true },
    { title: translate('Columns.subtitle'), type: 'text', editable: true },
    { title: translate('Columns.localId'), type: 'number', editable: false },
    { title: translate('Columns.communeId'), type: 'number', editable: false },
    { title: translate('Columns.shippingType'), type: 'text', editable: false },
    { title: translate('Columns.courierId'), type: 'number', editable: true },
    { title: translate('Columns.posId'), type: 'number', editable: false },
    {
      title: translate('Columns.checkStock'),
      type: 'checkbox',
      editable: true,
      selectedAll: false,
      selectedAllIcon: <UncheckedIcon />,
    },
    {
      title: translate('Columns.active'),
      type: 'checkbox',
      editable: true,
      selectedAll: false,
      selectedAllIcon: <UncheckedIcon />,
    },
    { title: translate('Columns.daysDelay'), type: 'number', editable: true },
  ];

  function verifyUniqueDeliveryRate(
    deliveryRates: IDeliveryRate[],
    rateId: number,
    checkedPrice: number | null,
    checkedTitle: string,
    checkedSubtitle: string,
    checkedActivateAt: number,
    checkedLocalId: number,
    checkedCommuneId: number,
    checkedShippingType: string,
    checkedCourierId: number,
    checkedPointOfSaleId: number,
    checkedCheckStock: boolean,
    checkedActive: boolean,
    checkedDaysDelay: number,
  ) {
    let verify = true;
    deliveryRates.forEach((rate: IDeliveryRate) => {
      if (
        rate.price === checkedPrice
        && rate.LocalId === checkedLocalId
        && rate.CommuneId === checkedCommuneId
        && rate.shippingType === checkedShippingType
        && rate.activateAt === checkedActivateAt
        && rate.CourierId === checkedCourierId
        && rate.PointOfSaleId === checkedPointOfSaleId
        && rate.title === checkedTitle
        && rate.subtitle === checkedSubtitle
        && rate.checkStock === checkedCheckStock
        && rate.active === checkedActive
        && rate.daysDelay === checkedDaysDelay
        && rate.id !== rateId
      ) {
        verify = false;
      }
    });
    return verify;
  }

  async function getTaskResponse() {
    if (retryTime > 0) {
      // The client's tasks are obtained from dynamoDb
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      // If the answer was failed, the item creation was wrong
      if (res?.status === 'FAILED') {
        const responseMessage = res.executions.message;
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res?.status === 'SUCCESS') {
        // If the response was successful, the item creation was well done
        setLoading(false);
        navigate('/home/alldeliveryrates', {
          state: {
            editSuccess: true,
          },
        });
      } else if (res?.status === 'ERROR') {
        // If the answer was error, it did not find the creation of items in the queue
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorTitle4'));
        setModalAlertText(translate('Modal.errorText4'));
      } else {
        // This case is for when the status is PENDING, the timeout will re-execute the
        // function until one of the previous cases is triggered
        setTimeout(getTaskResponse, delayTime);
        delayTime = 15000;
      }
      retryTime -= 1;
    } else {
      setLoading(false);
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      if (res?.status === 'FAILED' || res?.status === 'ERROR') {
        const responseMessage = res.executions?.message || 'Unexpected error';
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res?.status === 'SUCCESS') {
        navigate('/home/alldeliveryrates', {
          state: {
            editSuccess: true,
          },
        });
      } else {
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.noticeTitle'));
        setModalAlertText(translate('Modal.noticeText'));
      }
    }
  }

  // function to submit form
  async function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();
    setDisabledSubmitBtn(true);
    setLoading(true);
    const bodyData: IDeliveryRate[] = [];
    const deliveryRatesExists: string[] = [];
    const itemsMissingInfo: string[] = [];
    const response = await api.getDeliveryRates({});
    const allDeliveryRates = response.deliveryRates;
    dataToShow.forEach((item: IRowData) => {
      if (item.row[2].value === null || item.row[6].value === null) {
        itemsMissingInfo.push(item.row[5].value as string);
      }
      const price = (item.row[1].value === '' || item.row[1].value === null) ? null : Number(item.row[1].value);
      const verified = verifyUniqueDeliveryRate(
        allDeliveryRates,
        Number(item.index),
        price,
        item.row[3].value as string,
        item.row[4].value as string,
        Number(item.row[2].value),
        Number(item.row[5].value),
        Number(item.row[6].value),
        item.row[7].value as string,
        Number(item.row[8].value),
        Number(item.row[9].value),
        item.row[10].value === translate('yes'),
        item.row[11].value === translate('yes'),
        Number(item.row[12].value),
      );
      if (!verified) {
        deliveryRatesExists.push(item.row[0].value as string);
      }
      const itemsInfo = {
        id: Number(item.index),
        price,
        activateAt: Number(item.row[2].value),
        title: item.row[3].value as string,
        subtitle: item.row[4].value as string,
        LocalId: Number(item.row[5].value),
        CommuneId: Number(item.row[6].value),
        shippingType: item.row[7].value as string,
        CourierId: Number(item.row[8].value),
        PointOfSaleId: Number(item.row[9].value),
        checkStock: item.row[10].value === translate('yes'),
        active: item.row[11].value === translate('yes'),
        daysDelay: Number(item.row[12].value),
      };
      bodyData.push(itemsInfo);
    });
    setLoading(false);
    // Check if all required info is given
    if (itemsMissingInfo.length > 0) {
      const missingData = itemsMissingInfo.join(', ');
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle1'));
      setModalAlertText(translate('Modal.errorText1', { message: missingData }));
    } else if (deliveryRatesExists.length > 0) {
      const alreadyExists = deliveryRatesExists.join(', ');
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle2'));
      setModalAlertText(translate('Modal.errorText2', { message: alreadyExists }));
    } else {
      // Upload data to s3
      const id = uuid();
      const fileToUpload = JSON.stringify(bodyData);
      const res = uploadFileToS3(
        fileToUpload,
        `massive/input/${id}`,
      );
      if (res.includes('Error')) {
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorUploadingCsvTitle'));
        setModalAlertText(translate('Modal.errorUploadingCsvText', { error: res }));
      } else {
        const body = {
          queue: 'models/deliveryRates',
          event: 'update',
          clientId: user.connectableId,
          pathName: 'api/delivery-rates',
          processedItemsIds: [],
          cursor: 0,
          lambdasAlreadyProcessed: 0,
          fileName: id,
        };
        try {
          taskId = await apiFunctions.postEnqueueTask(body);
          if (taskId === null) {
            setLoading(false);
            setOpenModalAlert(true);
            setModalAlertTitle(translate('Modal.errorTitle3'));
            setModalAlertText(translate('Modal.errorText3'));
          } else {
            getTaskResponse();
            setOpenModalAlert(true);
            setModalAlertTitle(translate('Modal.sentTitle'));
            setModalAlertText(translate('Modal.sentText'));
          }
        } catch (error) {
          setOpenModalAlert(true);
          setModalAlertTitle(translate('Modal.errorTitle3'));
          setModalAlertText(translate('Modal.errorText3'));
          setDisabledSubmitBtn(false);
        }
      }
    }
    setDisabledSubmitBtn(false);
  }

  useEffect(() => {
    if (dataToShow.length === 0) {
      setLoading(true);
      const list: IRowData[] = [];
      (state.filteredItemsToEdit as IDeliveryRate[]).forEach((rate: IDeliveryRate, idx: number) => {
        let cellData: ICellData[] = [];
        cellData.push({ index: 0, value: idx + 1, isDisable: false });
        cellData = [
          ...cellData,
          { index: 1, value: rate.price, isDisable: false },
          { index: 2, value: rate.activateAt, isDisable: false },
          { index: 3, value: rate.title, isDisable: false },
          { index: 4, value: rate.subtitle, isDisable: false },
          { index: 5, value: rate.LocalId, isDisable: true },
          { index: 6, value: rate.CommuneId, isDisable: true },
          { index: 7, value: rate.shippingType, isDisable: true },
          { index: 8, value: rate.CourierId, isDisable: false },
          { index: 9, value: rate.PointOfSaleId, isDisable: false },
          { index: 10, value: rate.checkStock ? translate('yes') : translate('no'), isDisable: false },
          { index: 11, value: rate.active ? translate('yes') : translate('no'), isDisable: false },
          { index: 12, value: rate.daysDelay, isDisable: false },
        ];
        const rowData: IRowData = { index: rate.id as number, row: cellData };
        list.push(rowData);
      });
      setDataToShow(list);
      setLoading(false);
    }
  }, [dataToShow]);

  return (
    <div className="PageContainer">
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      {loading ? (
        <LoadingPage />
      ) : (null)}
      <div className="FormMainContainer">
        <GoBackButton
          goBackPath="/home/alldeliveryrates"
        />
        <div className="FormHistoryTitle">
          {translate('editRates')}
        </div>
        <form
          className="Form"
          onSubmit={handleSubmit}
        >
          <div className="FormContainerProducts">
            <NavigableTable
              dataToShow={dataToShow}
              setDataToShow={setDataToShow}
              columns={columns}
              loading={loading}
            />
          </div>
          <div className="FormFooter">
            <button
              className="GoBackButton"
              type="submit"
              disabled={disabledSubmitBtn}
            >
              {translate('editRates')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
