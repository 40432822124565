import {
  BrowserRouter, Routes as Router, Route,
} from 'react-router-dom';
import HomeLayout from '../components/HomeLayout';
import Login from '../pages/Login';
import LandingPage from '../pages/LandingPage';
import TrackingIndex from '../pages/Tracking/TrackingIndex';
import { TrackingOrder } from '../pages/Tracking/TrackingOrder';
import Checkout from '../pages/Checkout';

function Redirect({ url }: { url: string }) {
  window.location.href = url;
  return null;
}

function UnauthenticatedRoutes() {
  return (
    <BrowserRouter>
      <Router>
        <Route element={<HomeLayout />}>
          <Route
            path="/"
            element={<LandingPage />}
          />
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="/tracking"
            element={<TrackingIndex />}
          />
          <Route
            path="*"
            element={<Redirect url="/" />}
          />
        </Route>
        <Route
          path="whatsapp"
          element={<Redirect url="https://wa.me/56962073219" />}
        />
        <Route
          path="/tracking/:internalCode"
          element={<TrackingOrder />}
        />
        <Route
          path="checkout"
          element={<Checkout />}
        />
        <Route
          path="*"
          element={<HomeLayout />}
        />
      </Router>
    </BrowserRouter>
  );
}

export default UnauthenticatedRoutes;
