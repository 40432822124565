import { useIntercom } from 'react-use-intercom';
import { useTranslate } from '../hooks';

export default function LandingPage() {
  const { show } = useIntercom();
  const translate = useTranslate('Page.Landing');

  return (
    <div className="LandingPageMainContainer">
      <section className="LandingPageFirstSection">
        <div className="TitleWrapper">
          <h1 className="MainTitle">
            {translate('mainTitle')}
          </h1>
          <h2 className="MainSubTitle">
            {translate('mainDescription')}
          </h2>
        </div>
        <div
          className="LandingPageMainBtn"
          onClick={() => show()}
        >
          {' '}
          {translate('contactUs')}
        </div>
        <div className="LandingPageImgContainer">
          <img
            className="LandingPageGif"
            src="https://clients-platform-assets.s3.amazonaws.com/img/MapGif.gif"
            alt="Imagen"
          />
        </div>
      </section>
      <section
        id="HowSection"
        className="IconsComputerSection"
      >
        <div className="ComputerSectionTitleWrapper">
          <p className="MainTitle">
            {translate('howItWorks')}
          </p>
        </div>
        <div className="LineIconsImgContainer">
          <img
            className="LineIconsImg"
            src="https://clients-platform-assets.s3.amazonaws.com/img/lineIcon.png"
            alt="Imagen"
          />
          <p className="TextInLineIcons FirstIcon">
            {translate('step1')}
          </p>
          <p className="TextInLineIcons SecondIcon">
            {translate('step2')}
          </p>
          <p className="TextInLineIcons ThirdIcon">
            {translate('step3')}
          </p>
          <p className="TextInLineIcons FourthIcon">
            {translate('step4')}
          </p>
          <p className="TextInLineIcons FifthIcon">
            {translate('step5')}
          </p>
          <div className="SeparateIconsContainer">
            <img
              className="LineIconOneImg"
              src="https://clients-platform-assets.s3.amazonaws.com/img/IconoCelular.png"
              alt="Imagen"
            />
            <p className="LineIconOneText">
              {translate('step1')}
            </p>
            <img
              className="LineIconDotsRightImg"
              src="https://clients-platform-assets.s3.amazonaws.com/img/LineaPunteadaAbajo.png"
              alt="Imagen"
            />
            <img
              className="LineIconOneImg"
              src="https://clients-platform-assets.s3.amazonaws.com/img/IconoEstante.png"
              alt="Imagen"
            />
            <p className="LineIconOneText">
              {translate('step2')}
            </p>
            <img
              className="LineIconDotsLeftImg"
              src="https://clients-platform-assets.s3.amazonaws.com/img/LineaPunteadaAbajo.png"
              alt="Imagen"
            />
            <img
              className="LineIconOneImg"
              src="https://clients-platform-assets.s3.amazonaws.com/img/IconoPc.png"
              alt="Imagen"
            />
            <p className="LineIconOneText">
              {translate('step3')}
            </p>
            <img
              className="LineIconDotsRightImg"
              src="https://clients-platform-assets.s3.amazonaws.com/img/LineaPunteadaAbajo.png"
              alt="Imagen"
            />
            <img
              className="LineIconOneImg"
              src="https://clients-platform-assets.s3.amazonaws.com/img/IconoCajas.png"
              alt="Imagen"
            />
            <p className="LineIconOneText">
              {translate('step4')}
            </p>
            <img
              className="LineIconDotsLeftImg"
              src="https://clients-platform-assets.s3.amazonaws.com/img/LineaPunteadaAbajo.png"
              alt="Imagen"
            />
            <img
              className="LineIconOneImg"
              src="https://clients-platform-assets.s3.amazonaws.com/img/IconoPersona.png"
              alt="Imagen"
            />
            <p className="LineIconOneText">
              {translate('step5')}
            </p>
          </div>
        </div>
        <div className="ComputerImgContainer">
          <img
            className="ComputerImg"
            src="https://clients-platform-assets.s3.amazonaws.com/img/computador.png"
            alt="Imagen"
          />
        </div>
      </section>
      <section
        id="WhereSection"
        className="FullfilmentSection"
      >
        <div className="FullfilmentSectionTitleWrapper">
          <p className="MainTitle">
            {translate('mfcs')}
          </p>
        </div>
        <div className="MapImgContainer">
          <img
            className="MapImg"
            src="https://clients-platform-assets.s3.amazonaws.com/img/mapaLandingPage.png"
            alt="Imagen"
          />
        </div>
        <div className="TextFullfilmentSectionContainer">
          <div>
            <h1>
              {translate('RM')}
            </h1>
            <li>
              {translate('maipu')}
            </li>
            <li>
              {translate('laReina')}
            </li>
            <li>
              {translate('loBarnechea')}
            </li>
            <li>
              {translate('chicureo')}
            </li>
          </div>
          <div className="DescriptionTextFullfilmentSection NotMobileTextFullfilmentSection">
            <h1 className="OrangeText">
              {translate('expressCommunesTitle')}
            </h1>
            <li className="OrangeTextSize">
              {translate('expressCommunes1')}
            </li>
            <li className=" OrangeTextSize">
              {translate('expressCommunes2')}
            </li>
            <li className=" OrangeTextSize">
              {translate('expressCommunes3')}
            </li>
            <li className="OrangeTextSize">
              {translate('expressCommunes4')}
            </li>
            <li className="OrangeTextSize">
              {translate('expressCommunes5')}
            </li>
          </div>
          <div className="DescriptionTextFullfilmentSection NotMobileTextFullfilmentSection">
            <h1 className="OrangeText">
              {translate('sameDayCommunesTitle')}
            </h1>
            <li className="OrangeTextSize">
              {translate('sameDayCommunes1')}
            </li>
          </div>
          <div>
            <h1>
              {translate('VRegion')}
            </h1>
            <li>
              {translate('vinaDelMar')}
            </li>
            <li>
              {translate('cachagua')}
            </li>
          </div>
          <div className="DescriptionTextFullfilmentSection MobileTextFullfilmentSection">
            <h1 className="OrangeText">
              {translate('expressCommunesTitle')}
            </h1>
            <li className="OrangeTextSize">
              {translate('expressCommunes1')}
            </li>
            <li className="OrangeTextSize">
              {translate('expressCommunes2')}
            </li>
            <li className="OrangeTextSize">
              {translate('expressCommunes3')}
            </li>
            <li className="OrangeTextSize">
              {translate('expressCommunes4')}
            </li>
            <li className="OrangeTextSize">
              {translate('expressCommunes5')}
            </li>
          </div>
          <div className="DescriptionTextFullfilmentSection MobileTextFullfilmentSection">
            <h1 className="OrangeText">
              {translate('sameDayCommunesTitle')}
            </h1>
            <li className="OrangeTextSize">
              {translate('sameDayCommunes1')}
            </li>
          </div>
        </div>
      </section>
      <section className="FooterSection">
        <div className="FooterLogoContainer">
          <div className="FooterInfoTitle">
            <img
              className="FooterLogo"
              src="https://clients-platform-assets.s3.amazonaws.com/img/NomadLogoBlack.png"
              alt="Nomad"
            />
          </div>
          <div className="FooterInfoDetail">
            <p>
              {translate('Footer.copyright')}
            </p>
          </div>
        </div>
        <div className="FooterInfoContainer">
          <div className="FooterInfoTitle">
            <p>
              {translate('Footer.aboutUs')}
            </p>
          </div>
          <div className="FooterInfoDetail">
            <p>
              {translate('Footer.mail')}
            </p>
          </div>
          <div className="FooterInfoDetail">
            <p>
              {translate('Footer.address')}
            </p>
          </div>
        </div>
        <div className="FooterInfoContainer SecondFooterInfoContainer">
          <div className="FooterInfoTitle">
            <p>
              {translate('Footer.workWithUs')}
            </p>
          </div>
          <div className="FooterInfoDetail">
            <div
              className="IconLink"
              onClick={(e) => {
                window.open(
                  'https://get-nomad.notion.site/Trabaja-en-Nomad-8610b4dc4c04489e846fe7a17bac6e49',
                  '_blank',
                  'noopener,noreferrer',
                );
                e.preventDefault();
              }}
            >
              <p className="LandingFooterText">
                {translate('Footer.knowMore')}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
