import {
  useEffect, useState, useContext,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import FilterOutlined from '@ant-design/icons/FilterOutlined';
import { GlobalContext } from '../../store';
import IProduct from '../../interfaces/IProduct';
import LoadingPage from '../../components/LoadingPageComponent';
import ProductSearch from '../../components/HomeComponents/ProductSearch';
import AlertDialog from '../../components/HomeComponents/AlertDialog';
import ConfirmDialog from '../../components/HomeComponents/ConfirmDialog';
import IGetTasksStatus from '../../interfaces/IGetTasksStatus';
import ProductTable from '../../components/ProductComponents/ProductTable';
import { useTranslate } from '../../hooks';
import uploadFileToS3 from '../../helpers/uploadFileToS3';

const initialPageSize = 10;
const initialPage = 0;
interface IFetchOptions {
  filter: { [key: string]: string | number | boolean | string[] | number[] };
  rowsPerPage: number;
  page: number;
}

export default function AllProducts() {
  const navigate = useNavigate();
  const [products, setProducts] = useState<IProduct[]>([]);
  const {
    user, context, api, apiFunctions,
  } = useContext(GlobalContext);
  const location = useLocation();
  const { setFilteredItemsToEdit } = context;
  let editSuccess = location.state?.editSuccess;
  const [productsLength, setProductsLength] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [showFiltersPannel, setShowFiltersPannel] = useState<boolean>(false);
  const [productsToDelete, setProductsToDelete] = useState<IProduct[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [openConfirmAlert, setOpenConfirmAlert] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<string>('');
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [selected, setSelected] = useState<number[]>([]);
  const [fetchOptions, setFetchOptions] = useState<IFetchOptions>({
    filter: {},
    rowsPerPage: initialPageSize,
    page: initialPage,
  });

  const translate = useTranslate('Page.AllProducts');

  let taskId: string | null = null;
  let retryTime = 20;
  let delayTime = 3000;
  async function goToUpdate() {
    const response = await api.getClientProducts({
      filter: { id: selected },
      page: initialPage,
    });
    setFilteredItemsToEdit(response.products);
    navigate('/home/allproducts/update');
  }
  async function deleteItems() {
    const response = await api.getClientProducts({
      filter: { id: selected },
      page: initialPage,
    });
    setProductsToDelete(response.products);
    setOpenConfirmAlert(true);
  }
  function handleShowModal(value: boolean) {
    setShowModal(value);
  }
  function handleUpdateSearch(newValue: string) {
    const searchString = newValue.split('||');
    let filter = {
      name: searchString[1] || '',
      rawSku: searchString[0],
    };
    if (searchString.length === 1) {
      filter = {
        name: searchString[0],
        rawSku: searchString[0],
      };
    }
    setFetchOptions({
      ...fetchOptions,
      filter,
    });
  }
  function handleChangeRequest(newRowsPerPage: number, newPage: number) {
    setFetchOptions({
      ...fetchOptions,
      rowsPerPage: newRowsPerPage,
      page: newPage,
    });
  }
  async function fetchProducts() {
    const response = await api.getClientProducts(fetchOptions);
    setProducts(response.products);
    setProductsLength(response.count);
  }
  async function getTaskResponse() {
    if (retryTime > 0) {
      // The client's tasks are obtained from dynamoDb
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      // If the answer was failed, the item creation was wrong
      if (res?.status === 'FAILED') {
        const responseMessage = res.executions.message;
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res?.status === 'SUCCESS') {
        // If the response was successful, the item creation was well done
        setSelected([]);
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.successTitle'));
        setModalAlertText(translate('Modal.successText'));
        await fetchProducts();
      } else if (res?.status === 'ERROR') {
        // If the answer was error, it did not find the creation of items in the queue
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorTitle'));
        setModalAlertText(translate('Modal.errorText'));
      } else {
        // This case is for when the status is PENDING, the timeout will re-execute the
        // function until one of the previous cases is triggered
        setTimeout(getTaskResponse, delayTime);
        delayTime = 15000;
      }
      retryTime -= 1;
    } else {
      setLoading(false);
      setOpenModalAlert(true);
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      if (res?.status === 'FAILED' || res?.status === 'ERROR') {
        const responseMessage = res.executions?.message || 'Unexpected error';
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res?.status === 'SUCCESS') {
        setSelected([]);
        setModalAlertTitle(translate('Modal.successTitle'));
        setModalAlertText(translate('Modal.successText'));
        await fetchProducts();
      } else {
        setModalAlertTitle(translate('Modal.noticeTitle'));
        setModalAlertText(translate('Modal.noticeText'));
      }
    }
  }
  useEffect(() => {
    (async () => {
      setLoadingTable(true);
      await fetchProducts();
      setLoadingTable(false);
    })();
  }, [fetchOptions]);
  useEffect(() => {
    (async () => {
      if (confirmDelete === 'true') {
        // Set data of reception
        setLoading(true);
        // Upload data to s3
        const id = uuid();
        const fileToUpload = JSON.stringify(selected);
        const res = uploadFileToS3(
          fileToUpload,
          `massive/input/${id}`,
        );
        if (res.includes('Error')) {
          setLoading(false);
          setOpenModalAlert(true);
          setModalAlertTitle(translate('Modal.errorUploadingCsvTitle'));
          setModalAlertText(translate('Modal.errorUploadingCsvText', { error: res }));
        } else {
          const body = {
            queue: 'models/products',
            event: 'delete',
            clientId: user.connectableId,
            pathName: 'api/products',
            processedItemsIds: [],
            cursor: 0,
            lambdasAlreadyProcessed: 0,
            fileName: id,
          };
          try {
            taskId = await apiFunctions.postEnqueueTask(body);
            if (taskId === null) {
              setLoading(false);
              setOpenModalAlert(true);
              setModalAlertTitle(translate('Modal.errorTitle'));
              setModalAlertText(translate('Modal.errorText'));
            } else {
              setOpenConfirmAlert(false);
              getTaskResponse();
            }
          } catch (error) {
            setLoading(false);
            setOpenConfirmAlert(false);
          }
        }
      }
    })();
    setConfirmDelete('');
  }, [confirmDelete]);
  useEffect(() => {
    (async () => {
      if (editSuccess) {
        await fetchProducts();
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.editSuccessTitle'));
        setModalAlertText(translate('Modal.editSuccessText'));
        editSuccess = false;
      }
    })();
  }, [editSuccess]);
  if (loading) {
    return (
      <div className="PageContainer">
        <LoadingPage />
      </div>
    );
  }
  return (
    <div className="FormHistoryContainer">
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      <ConfirmDialog
        data={productsToDelete}
        type="products"
        description={translate('confirmDialog', { productsLength: productsToDelete.length })}
        open={openConfirmAlert}
        setOpen={setOpenConfirmAlert}
        setConfirmData={setConfirmDelete}
      />
      <div className="FormHistoryTitle">
        {translate('title')}
      </div>
      <div className="ItemsFiltersContainer">
        {showFiltersPannel ? (
          <>
            <div
              className="ItemsFiltersBtn ItemsFiltersBtnPressed"
              onClick={() => setShowFiltersPannel(!showFiltersPannel)}
            >
              <FilterOutlined className="LargeIcon" />
            </div>
            <div className="ItemsFiltersSelecInputsContainer ItemsBtnMargin">
              <ProductSearch
                onUpdateSearch={handleUpdateSearch}
                allProducts={products}
                type="product"
              />
            </div>
          </>
        ) : (
          <>
            <div
              className="ItemsFiltersBtn"
              onClick={() => navigate('/home/allproducts/create')}
            >
              {translate('massiveCreate')}
            </div>
            <div
              className="ItemsFiltersBtn ItemsBtnMargin"
              onClick={() => navigate('/home/allproducts/unitarycreate')}
            >
              {translate('unitCreate')}
            </div>
            <div
              className="ItemsFiltersBtn ItemsBtnMargin"
              onClick={() => setShowFiltersPannel(!showFiltersPannel)}
            >
              <FilterOutlined className="LargeIcon" />
            </div>
          </>
        )}
      </div>
      { showModal ? (
        <div className="UpdateItemsContainer">
          <div
            className="UpdateItemsBtns"
            onClick={() => goToUpdate()}
          >
            {translate('editProducts')}
          </div>
          <div
            className="UpdateItemsBtns"
            onClick={() => deleteItems()}
          >
            {translate('deleteProducts')}
          </div>
        </div>
      ) : (null) }
      <ProductTable
        rows={products}
        totalRowCount={productsLength}
        onChangeRequest={handleChangeRequest}
        initialPageSze={initialPageSize}
        initialPage={initialPage}
        showModal={handleShowModal}
        selected={selected}
        setSelected={setSelected}
        loadingTable={loadingTable}
      />
    </div>
  );
}
