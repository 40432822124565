import { useTranslate } from '../hooks';

import '../css/Unauthorized.css';

export default function NotFound() {
  const translate = useTranslate('Page.NotFound');

  return (
    <div className="UnauthorizedPage">
      <h2>
        {translate('title')}
      </h2>
      <p>
        {translate('subtitle1')}
      </p>
      <p>
        {translate('subtitle2')}
      </p>
    </div>
  );
}
