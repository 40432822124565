import AWS from 'aws-sdk';
import { MouseEventHandler } from 'react';

function downloadFileFromS3(fileName: string, type: string): MouseEventHandler<HTMLAnchorElement> | undefined {
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME as string,
    Key: fileName,
  };
  s3.getObject(params, (err, data) => {
    const blob = new Blob(['\uFEFF', data.Body as BlobPart], { type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
  });
  return undefined;
}

export default downloadFileFromS3;
