import { useState, useContext, useEffect } from 'react';
import { Divider } from '@mui/material';
import RightArrow from '@ant-design/icons/RightOutlined';
import { useTranslate } from '../../hooks';
import { GlobalContext } from '../../store';
import Products from './Products';
import Address from './Address';
import Rates from './Rates';
import Data from './Data';
import Summary from './Summary';
import { IRowData } from '../../interfaces/INavigableTableObjects';
import { IOrder } from '../../interfaces/IOrder';
import LoadingPage from '../../components/LoadingPageComponent';
import AlertDialog from '../../components/HomeComponents/AlertDialog';
import Unauthorized from '../Unauthorized';
import Type from './Type';
import { IOrderType } from '../../interfaces';

function RenderPage({
  activePage, goNextPage, orderDetails, setOrderDetails, goPreviousPage, order, setOrder, setLoading, orderTypes,
  setOpenAlertDialog, setModalAlertTitle, setModalAlertText, rateId, setRateId, uploadedInvoice, setUploadedInvoice,
}: {
  activePage: string;
  goNextPage: () => void;
  orderDetails: IRowData[];
  setOrderDetails: React.Dispatch<React.SetStateAction<IRowData[]>>;
  goPreviousPage: () => void;
  order: IOrder;
  setOrder: React.Dispatch<React.SetStateAction<IOrder>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAlertDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setModalAlertTitle: React.Dispatch<React.SetStateAction<string>>;
  setModalAlertText: React.Dispatch<React.SetStateAction<string>>;
  orderTypes: IOrderType[];
  rateId: number;
  setRateId: React.Dispatch<React.SetStateAction<number>>;
  uploadedInvoice: HTMLInputElement | null;
  setUploadedInvoice: React.Dispatch<React.SetStateAction<HTMLInputElement | null>>;
}) {
  switch (activePage) {
    case 'type':
      return (
        <Type
          goNextPage={goNextPage}
          order={order}
          setOrder={setOrder}
          orderTypes={orderTypes}
        />
      );
    case 'products':
      return (
        <Products
          goNextPage={goNextPage}
          goPreviousPage={goPreviousPage}
          order={order}
          orderDetails={orderDetails}
          setOrderDetails={setOrderDetails}
          setOpenAlertDialog={setOpenAlertDialog}
          setModalAlertTitle={setModalAlertTitle}
          setModalAlertText={setModalAlertText}
        />
      );
    case 'address':
      return (
        <Address
          goNextPage={goNextPage}
          goPreviousPage={goPreviousPage}
          order={order}
          setOrder={setOrder}
        />
      );
    case 'rates':
      return (
        <Rates
          goNextPage={goNextPage}
          goPreviousPage={goPreviousPage}
          order={order}
          orderDetails={orderDetails}
          setOrder={setOrder}
          setRateId={setRateId}
          rateId={rateId}
        />
      );
    case 'data':
      return (
        <Data
          goNextPage={goNextPage}
          goPreviousPage={goPreviousPage}
          order={order}
          setOrder={setOrder}
          setUploadedInvoice={setUploadedInvoice}
          setOpenAlertDialog={setOpenAlertDialog}
          setModalAlertTitle={setModalAlertTitle}
          setModalAlertText={setModalAlertText}
        />
      );
    case 'summary':
      return (
        <Summary
          goPreviousPage={goPreviousPage}
          goNextPage={goNextPage}
          order={order}
          orderDetails={orderDetails}
          setOrderDetails={setOrderDetails}
          setLoading={setLoading}
          setOpenAlertDialog={setOpenAlertDialog}
          setModalAlertTitle={setModalAlertTitle}
          setModalAlertText={setModalAlertText}
          setRateId={setRateId}
          rateId={rateId}
          uploadedInvoice={uploadedInvoice}
        />
      );
    default:
      return null;
  }
}

export default function Orders() {
  const translate = useTranslate('Page.CreateOrder');
  const { api, user, client } = useContext(GlobalContext);
  const [activePage, setActivePage] = useState<
  'type' | 'products' | 'address' | 'rates' | 'data' | 'summary'
  >(client.hasB2B ? 'type' : 'products');
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [rateId, setRateId] = useState<number>(0);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [orderTypes, setOrderTypes] = useState<IOrderType[]>([]);
  const [posId, setPosId] = useState<number | null>(null);
  const [orderDetails, setOrderDetails] = useState<IRowData[]>([]);
  const [uploadedInvoice, setUploadedInvoice] = useState<HTMLInputElement | null>(null);
  const [order, setOrder] = useState<IOrder>({
    customerName: '',
    customerEmail: '',
    customerPhone: '',
    note: '',
    pat: '',
    shippingAddress: { address1: '', address2: '', commune: '' },
    shippingType: '',
    ClientId: user.connectableId,
    shippingCost: 0,
    OrderDetails: [],
    date: (new Date()).toISOString(),
    PointOfSaleId: null,
    code: '',
    platformCode: '',
  });

  function goNextPage() {
    switch (activePage) {
      case 'type':
        setActivePage('products');
        break;
      case 'products':
        setActivePage('address');
        break;
      case 'address':
        setActivePage('rates');
        break;
      case 'rates':
        setActivePage('data');
        break;
      case 'data':
        setActivePage('summary');
        break;
      case 'summary':
        setActivePage('products');
        break;
      default:
        break;
    }
  }

  function goPreviousPage() {
    switch (activePage) {
      case 'products':
        setActivePage('type');
        break;
      case 'address':
        setActivePage('products');
        break;
      case 'rates':
        setActivePage('address');
        break;
      case 'data':
        setActivePage('rates');
        break;
      case 'summary':
        setActivePage('data');
        break;
      default:
        break;
    }
  }

  function goToPage(page: string) {
    switch (page) {
      case 'type':
        setActivePage('type');
        break;
      case 'products':
        if (activePage === 'address' || activePage === 'rates' || activePage === 'data' || activePage === 'summary') {
          setActivePage('products');
        }
        break;
      case 'address':
        if (activePage === 'address' || activePage === 'rates' || activePage === 'data' || activePage === 'summary') {
          setActivePage('address');
        }
        break;
      case 'rates':
        if (activePage === 'data' || activePage === 'summary') {
          setActivePage('rates');
        }
        break;
      case 'data':
        if (activePage === 'summary') {
          setActivePage('data');
        }
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const posIntegration = await api.getClientPOSIntegration(user.connectableId, 'clients-platform');
      const orderTypesFiltered = await api.getOrderTypes({ filter: { name: ['B2B', 'B2C'] } });
      if (posIntegration) {
        order.PointOfSaleId = posIntegration.id;
        order.OrderTypeId = orderTypesFiltered.find((ot) => ot.name === 'B2C')?.id;
        setPosId(posIntegration.id);
        setOrderTypes(orderTypesFiltered);
        setOrder(order);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingPage full />
      ) : (null)}
      {posId === null && !loading ? (
        <div style={{ width: '80%' }}>
          <Unauthorized
            showSignout={false}
          />
        </div>
      ) : (
        <div className="OrderPageContainer">
          <AlertDialog
            title={modalAlertTitle}
            text={modalAlertText}
            open={openAlertDialog}
            setOpen={setOpenAlertDialog}
          />
          <div className="PageTitleContainer">
            <h4>{translate('mainTitle')}</h4>
            <Divider />
          </div>
          <div className="StepsContainer">
            {client.hasB2B && (
              <>
                <p
                  onClick={() => goToPage('type')}
                  className={`Cursor ${activePage === 'type' ? 'ActiveStep' : ''}`}
                >
                  {translate('type')}
                </p>
                <RightArrow className="RightArrow" />
              </>
            )}
            <p
              onClick={() => goToPage('products')}
              className={`Cursor ${activePage === 'products' ? 'ActiveStep' : ''}`}
            >
              {translate('product')}
            </p>
            <RightArrow className="RightArrow" />
            <p
              onClick={() => goToPage('address')}
              className={`Cursor ${activePage === 'address' ? 'ActiveStep' : ''}`}
            >
              {translate('address')}
            </p>
            <RightArrow className="RightArrow" />
            <p
              onClick={() => goToPage('rates')}
              className={`Cursor ${activePage === 'rates' ? 'ActiveStep' : ''}`}
            >
              {translate('rate')}
            </p>
            <RightArrow className="RightArrow" />
            <p
              onClick={() => goToPage('data')}
              className={`Cursor ${activePage === 'data' ? 'ActiveStep' : ''}`}
            >
              {translate('data')}
            </p>

            <RightArrow className="RightArrow" />
            <p
              onClick={() => goToPage('summary')}
              className={`Cursor ${activePage === 'summary' ? 'ActiveStep' : ''}`}
            >
              {translate('summary')}
            </p>
          </div>
          <RenderPage
            activePage={activePage}
            goNextPage={goNextPage}
            orderDetails={orderDetails}
            setOrderDetails={setOrderDetails}
            goPreviousPage={goPreviousPage}
            order={order}
            setOrder={setOrder}
            setLoading={setLoading}
            setOpenAlertDialog={setOpenAlertDialog}
            setModalAlertTitle={setModalAlertTitle}
            setModalAlertText={setModalAlertText}
            orderTypes={orderTypes}
            setRateId={setRateId}
            rateId={rateId}
            uploadedInvoice={uploadedInvoice}
            setUploadedInvoice={setUploadedInvoice}
          />
        </div>
      )}
    </>
  );
}
