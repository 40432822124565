import { useEffect, useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useIntercom } from 'react-use-intercom';
import { Api } from '../services';
import { IUser } from '../interfaces';
import LoadingPage from '../components/LoadingPageComponent';

// Store
import StoreWrapper from './StoreWrapper';

// Navigation
import UnauthenticatedRoutes from '../navigation/UnauthenticatedRoutes';
import AuthenticatedRoutes from '../navigation/AuthenticatedRoutes';
import { config } from '../awsConfig';
import Unauthorized from '../pages/Unauthorized';
import IClient from '../interfaces/IClient';
import { ApiFunctions } from '../apiRequester/ApiFunctions';
import AccessDenied from '../pages/AccessDenied';

Amplify.configure(config);

function AuthWrapper() {
  const [api, setApi] = useState<Api>();
  const [apiFunctions, setApiFunctions] = useState<ApiFunctions>();
  const [user, setUser] = useState<IUser>();
  const [client, setClient] = useState<IClient>();
  const [sessionAvailable, setSessionAvailable] = useState<boolean>(false);
  const [loadingSession, setLoadingSession] = useState<boolean>(true);
  const [accessDenied, setAccessDenied] = useState<boolean>(false);
  const authenticator = useAuthenticator((context) => [context.user, context.authStatus]);
  const { update } = useIntercom();

  useEffect(() => {
    (async () => {
      setLoadingSession(() => true);
      try {
        // Raises an error if there is no current user
        const session = await Auth.currentSession();
        setSessionAvailable(true);
        const token = session.getAccessToken().getJwtToken();
        const newApi = new Api(token);
        const newApiFunctions = new ApiFunctions(token);
        const newUser = await newApi.getUser();
        if (typeof newUser === 'string' && newUser.includes('Access denied')) {
          setAccessDenied(true);
        } else if (typeof newUser !== 'string') {
          const newClient = await newApi.getClient(newUser.connectableId);
          setClient(newClient);
          setApi(newApi);
          setApiFunctions(newApiFunctions);
          setUser(newUser);
          update({
            name: newUser.name,
            userId: `${newUser.id}`,
            email: newUser.email,
            customAttributes: {
              username: newUser.username,
              platform: 'clients',
              client: newClient.name,
            },
          });
        }
      } catch {
        setSessionAvailable(false);
      }
      setLoadingSession(() => false);
    })();
  }, [authenticator.user, authenticator.authStatus]);

  if (!loadingSession && !sessionAvailable) {
    return (
      <div className="App">
        <UnauthenticatedRoutes />
      </div>
    );
  }

  if (accessDenied) {
    return (
      <AccessDenied />
    );
  }

  if (!api || !user || loadingSession || !client || !apiFunctions) {
    return <LoadingPage full />;
  }

  if (user.role !== 'client' || !client.active) {
    return (
      <StoreWrapper
        api={api}
        apiFunctions={apiFunctions}
        user={user}
        client={client}
      >
        <Unauthorized
          showSignout
        />
      </StoreWrapper>
    );
  }

  return (
    <div className="App">
      <StoreWrapper
        api={api}
        apiFunctions={apiFunctions}
        user={user}
        client={client}
      >
        <AuthenticatedRoutes />
      </StoreWrapper>
    </div>
  );
}

export default AuthWrapper;
