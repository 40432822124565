export const getProductsVariables = ({
  filter,
}: {
  filter?: { [key: string]: string | number | number[] | boolean | string[] | { [key: string]: string | string[] } }
}) => {
  const {
    id, name, rawSku, rawSkus, isPack,
  } = filter || {};
  return {
    where: {
      _and: [
        {
          id: id ? {
            _in: id,
          } : {},
        },
        {
          rawSku: rawSkus ? {
            _in: rawSkus,
          } : {},
        },
        {
          isPack: isPack !== undefined ? {
            _eq: isPack,
          } : {},
        },
      ],
      _or: [
        {
          name: name ? {
            _ilike: `%${name}%`,
          } : {},
        },
        {
          rawSku: rawSku ? {
            _ilike: `%${rawSku}%`,
          } : {},
        },
      ],
    },
  };
};
