import { createContext } from 'react';
import { ContextInterface, IUser, StateInterface } from '../interfaces';
import { clientDefaultState } from './reducer';
import { Api } from '../services';
import IClient from '../interfaces/IClient';
import { ApiFunctions } from '../apiRequester/ApiFunctions';

interface DefaultContextProps {
  state: StateInterface;
  context: ContextInterface;
  api: Api;
  apiFunctions: ApiFunctions;
  user: IUser;
  client: IClient;
}

const defaultContext: DefaultContextProps = {
  state: clientDefaultState,
  context: null as unknown as ContextInterface,
  api: null as unknown as Api,
  apiFunctions: null as unknown as ApiFunctions,
  user: null as unknown as IUser,
  client: null as unknown as IClient,
};

export default createContext(defaultContext);
