import {
  useEffect, useState, useContext,
} from 'react';
import { useTranslate } from '../../hooks';
import { SelectInput } from '../../components/HomeComponents/SelectInput';
import { IOrder } from '../../interfaces/IOrder';
import { GlobalContext } from '../../store';
import Loading from '../../components/LoadingComponent';
import { IChargeRate } from '../../interfaces/IChargeRate';
import { IOrderType } from '../../interfaces';
import SwitchButton from '../../components/HomeComponents/SwitchButton';

interface Props {
  goNextPage: () => void;
  order: IOrder;
  setOrder: React.Dispatch<React.SetStateAction<IOrder>>;
  orderTypes: IOrderType[];
}

export default function Type(props: Props) {
  const {
    order, setOrder, goNextPage, orderTypes,
  } = props;
  const translate = useTranslate('Page.CreateOrder');
  const { api } = useContext(GlobalContext);
  const [disable, setDisable] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOrderType, setSelectedOrderType] = useState<IOrderType | null>(null);
  const [requiresNomadFleet, setRequiresNomadFleet] = useState<boolean>(!!order.CourierId);
  const [chargeRates, setChargeRates] = useState<IChargeRate[]>([]);
  const [filteredChargeRates, setFilteredChargeRates] = useState<IChargeRate[]>([]);
  const [selectedChargeRate, setSelectedChargeRate] = useState<IChargeRate | null>(null);

  function saveData() {
    if (selectedOrderType && selectedChargeRate) {
      setOrder({
        ...order,
        OrderTypeId: selectedOrderType.id,
        OrderType: selectedOrderType,
        ChargeRateId: selectedChargeRate.id,
        ChargeRate: selectedChargeRate,
        CourierId: selectedOrderType.name === 'B2B' && requiresNomadFleet ? 7 : null,
      });
      goNextPage();
    }
  }

  // Set types when selected OrderType
  useEffect(() => {
    if (chargeRates.length > 0 && selectedOrderType) {
      const usableData = chargeRates
        .filter((chargeRate) => chargeRate.OrderTypeId === selectedOrderType.id);
      setFilteredChargeRates(usableData);
      setSelectedChargeRate(usableData[0]);
    }
  }, [chargeRates, selectedOrderType]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      // set chargeRates
      const data = await api.getClientChargeRates(order.ClientId);
      setChargeRates(data);
      if (order.OrderTypeId) {
        const orderType = orderTypes.find((ot) => ot.id === order.OrderTypeId);
        if (orderType) {
          setSelectedOrderType(orderType);
        }
      }
      setLoading(false);
    })();
  }, [order.OrderTypeId]);

  // Verification required inputs
  useEffect(() => {
    if (!selectedOrderType || !selectedChargeRate) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [selectedOrderType, selectedChargeRate]);

  if (loading) {
    return (
      <>
        <div className="PageSubtitleContainer">
          {translate('TypeStep.subtitle')}
        </div>
        <Loading />
      </>
    );
  }

  return (
    <>
      <div className="PageSubtitleContainer">
        {translate('TypeStep.subtitle')}
      </div>
      <div className="DirectionStepContainer">
        <div className="DirectionLabel">{translate('TypeStep.businessModel')}</div>
        <div className="SelectWrapperLargeWidth">
          <SelectInput<IOrderType>
            data={orderTypes}
            keyValue="id"
            selectValue="id"
            displayValue="name"
            selected={selectedOrderType}
            setSelected={setSelectedOrderType}
          />
        </div>
        <div className="DirectionLabel">{translate('TypeStep.commission')}</div>
        <div className="SelectWrapperLargeWidth">
          <SelectInput<IChargeRate>
            data={filteredChargeRates}
            keyValue="id"
            selectValue="id"
            displayValue="type"
            selected={selectedChargeRate}
            setSelected={setSelectedChargeRate}
          />
        </div>
        {selectedOrderType?.name === 'B2B' && (
          <>
            <div className="DirectionLabel" />
            <div className="SelectWrapperLargeWidth">
              <SwitchButton
                text={translate('TypeStep.requiresNomadFleet')}
                switchButton={requiresNomadFleet}
                toggleSwitchBtn={() => setRequiresNomadFleet(!requiresNomadFleet)}
              />
            </div>
          </>
        )}
      </div>
      <div className="BottomRow">
        <div
          className={`Button Primary ${disable ? 'Disable' : ''}`}
          onClick={saveData}
        >
          {translate('TypeStep.goNextButton')}
        </div>
      </div>
    </>
  );
}
