import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import capitalizeFirstLetter from '../../helpers/capitalizeFirstLetter';
import { GlobalContext } from '../../store';
import IProduct from '../../interfaces/IProduct';
import NavigableTable from '../../NavigableTable/NavigableTable';
import { IColumnData, IRowData } from '../../interfaces/INavigableTableObjects';
import { IOrder, IOrderDetail } from '../../interfaces/IOrder';
import GoBackButton from '../../components/HomeComponents/GoBackButton';
import { useTranslate } from '../../hooks';
import LoadingPage from '../../components/LoadingPageComponent';
import textInputToNumber from '../../helpers/textInputToNumber';

export default function WithdrawalDetail() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { api } = useContext(GlobalContext);
  const [dataToShow, setDataToShow] = useState<IRowData[]>([]);
  const [withdrawalOrder, setWithdrawalOrder] = useState<IOrder>();
  const [columns, setColumns] = useState<IColumnData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const translate = useTranslate('Page.WithdrawalDetail');

  useEffect(() => {
    (async () => {
      const filter = { id: Number(orderId) };
      const withdrawalOrders = await api.getOrders({ filter });
      if (withdrawalOrders.orders[0] === null) {
        navigate('/home/withdrawals/');
      }
      setWithdrawalOrder(withdrawalOrders.orders[0]);
      if (dataToShow.length !== 0) {
        return;
      }
      const result = await api.getClientProducts({});
      const clientProducts = result.products;
      if (clientProducts.length > 0) {
        setLoading(true);
        const productsName: { [key: number]: string } = {};
        clientProducts.forEach((product: IProduct) => {
          productsName[product.id] = `${product.sku}-${product.name}`;
        });
        const list: IRowData[] = [];
        const defineColumns: IColumnData[] = [
          { title: translate('Columns.products'), type: 'text', editable: false },
          { title: translate('Columns.quantity'), type: 'text', editable: false },
        ];
        setColumns(defineColumns);
        withdrawalOrders.orders[0].OrderDetails?.forEach((orderDetail: IOrderDetail) => {
          const cellData = [
            { index: 0, value: productsName[orderDetail.ProductId], isDisable: false },
            { index: 1, value: `${orderDetail.amount}`, isDisable: false },
          ];
          const rowData: IRowData = { index: orderDetail.ProductId, row: cellData };
          list.push(rowData);
        });
        setDataToShow(list);
        setLoading(false);
      }
    })();
  }, []);

  if (!withdrawalOrder) {
    return <LoadingPage full />;
  }

  return (
    <div className="PageContainer">
      <div className="FormMainContainer">
        <GoBackButton
          goBackPath="/home/withdrawals"
        />
        <div className="FormTitle">
          {translate('title')}
        </div>
        <Divider />
        <div className="FormContainer">
          <div className="FormLabel">
            {translate('shippingType')}
          </div>
          <div className="FormLabel">{capitalizeFirstLetter(withdrawalOrder.shippingType)}</div>
        </div>
        <Divider />
        <div className="FormContainer">
          <div className="FormLabel">
            {translate('shippingCost')}
          </div>
          <div className="FormLabel">
            {withdrawalOrder.shippingCost
              ? `$${textInputToNumber(withdrawalOrder.shippingCost.toString(), true)}`
              : translate('Free')}
          </div>
        </div>
        <Divider />
        {(withdrawalOrder.shippingType !== 'pickup') && (
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('shippingAddress')}
            </div>
            <div className="FormLabel">{capitalizeFirstLetter(withdrawalOrder.shippingAddress.address1)}</div>
          </div>
        )}
        <Divider />
        <div className="FormContainer">
          <div className="FormLabel">
            {translate('local')}
          </div>
          <div className="FormLabel">{capitalizeFirstLetter(withdrawalOrder.Local?.name as string)}</div>
        </div>
        <Divider />
        {withdrawalOrder.date && (
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('date')}
            </div>
            <div className="FormLabel">
              {new Date(withdrawalOrder.date).getUTCDate()}
              /
              {new Date(withdrawalOrder.date).getUTCMonth() + 1}
              /
              {new Date(withdrawalOrder.date).getUTCFullYear()}
            </div>
          </div>
        )}
        <Divider />
        <div className="FormContainer">
          <div className="FormLabel">
            {translate('code')}
          </div>
          <div className="FormLabel">{capitalizeFirstLetter(withdrawalOrder.code)}</div>
        </div>
        <Divider />
        <div className="FormContainer">
          <div className="FormLabel">
            {translate('status')}
          </div>
          <div className="FormLabel">{capitalizeFirstLetter(withdrawalOrder.status)}</div>
        </div>
        <Divider />
        <div className="FormContainer">
          <div className="FormLabel">
            {translate('name')}
          </div>
          <div className="FormLabel">{capitalizeFirstLetter(withdrawalOrder.customerName)}</div>
        </div>
        <Divider />
        {withdrawalOrder.customerPhone && (
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('phone')}
            </div>
            <div className="FormLabel">{withdrawalOrder.customerPhone}</div>
          </div>
        )}
        <Divider />
        {withdrawalOrder.note && (
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('note')}
            </div>
            <div className="FormLabel">{withdrawalOrder.note}</div>
          </div>
        )}
        <div className="FormContainerProducts">
          <NavigableTable
            dataToShow={dataToShow}
            setDataToShow={setDataToShow}
            columns={columns}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
