function textInputToNumber(text: string, restrictZero: boolean) {
  let addPoint = (num: string) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  if (restrictZero) {
    addPoint = (num: string) => num.toString().replace(/^0/, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  const removeNonNumeric = (num: string) => num.toString().replace(/[^0-9-]/g, '');
  return addPoint(removeNonNumeric(text));
}

export default textInputToNumber;
