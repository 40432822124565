import capitalizeFirstLetter from '../../helpers/capitalizeFirstLetter';

interface Props {
  title: string;
  styleWidth?: string;
  array: unknown[];
  selectedOption: string;
  SetSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  lightStyle: boolean;
}

interface SelectOption {
  name: string
}

function SelectInput(props: Props) {
  const {
    title, array, selectedOption, SetSelectedOption, styleWidth, lightStyle,
  } = props;
  return (
    <select
      onChange={({ target: { value } }) => SetSelectedOption(value)}
      className={`${(lightStyle) ? 'InputLightStyle' : 'selectInput selectInputLeftMargin'}`}
      style={{ width: styleWidth }}
    >
      <option
        selected={selectedOption === ''}
        value=""
      >
        {title}
      </option>
      {array?.map((op: unknown) => (
        <option
          selected={selectedOption === (op as SelectOption).name}
          value={(op as SelectOption).name}
        >
          {capitalizeFirstLetter((op as SelectOption).name)}
        </option>
      ))}
    </select>
  );
}

export default SelectInput;
