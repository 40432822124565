import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Changes from '@ant-design/icons/FormOutlined';
import Home from '@ant-design/icons/HomeOutlined';
import Info from '@ant-design/icons/InfoCircleOutlined';
import Billing from '@ant-design/icons/DollarCircleOutlined';
import Reposition from '@ant-design/icons/CodeSandboxOutlined';
import Premises from '@ant-design/icons/EnvironmentOutlined';
import User from '@ant-design/icons/UserOutlined';
import Stock from '@ant-design/icons/FileDoneOutlined';
import Menu from '@ant-design/icons/MenuOutlined';
import CloseMenu from '@ant-design/icons/CloseOutlined';
import Sales from '@ant-design/icons/BarChartOutlined';
import OrderDetails from '@ant-design/icons/FileTextOutlined';
import Products from '@ant-design/icons/DropboxOutlined';
import ReceptionForm from '@ant-design/icons/ScheduleOutlined';
import HelpCenter from '@ant-design/icons/QuestionCircleOutlined';
import Reports from '@ant-design/icons/PieChartOutlined';
import HistoryOutlined from '@ant-design/icons/HistoryOutlined';
import BillingManual from '@ant-design/icons/BookOutlined';
import TagOutlined from '@ant-design/icons/TagOutlined';
import TagsOutlined from '@ant-design/icons/TagsOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import DollarOutlined from '@ant-design/icons/DollarOutlined';
import OrderIcon from '@ant-design/icons/ShoppingCartOutlined';
import Lots from '@ant-design/icons/ContainerOutlined';
import Withdrawal from '@ant-design/icons/InboxOutlined';
import { GlobalContext } from '../store';
import Accordion from '../components/SideNavBarComponents/Accordion';
import { useTranslate } from '../hooks';

export default function NavigationTool() {
  const [click, setClick] = useState<boolean>(false);
  const [anotherTabOpen, setAnotherTabOpen] = useState<string>('');
  const [toggleAnotherTab, setToggleAnotherTab] = useState<string>('');
  const [toggleFulfillmentReports, setToggleFulfillmentReports] = useState<boolean>(false);
  const [toggleHelpCenter, setToggleHelpCenter] = useState<boolean>(false);
  const [toggleForms, setToggleForms] = useState<boolean>(false);
  const [toggleProducts, setToggleProducts] = useState<boolean>(false);
  const [toggleDeliveryRates, setToggleDeliveryRates] = useState<boolean>(false);
  const [toggleCreateOrder, setToggleCreateOrder] = useState<boolean>(false);
  const [toggleWithdrawals, setToggleWithdrawals] = useState<boolean>(false);
  const [toggleFinances, setToggleFinances] = useState<boolean>(false);
  const { context, client } = useContext(GlobalContext);
  const { logOut } = context;
  const translate = useTranslate('Navigation.SideNavbar');
  const helpCenterTab = [
    {
      subTitle: translate('Help.repositions'),
      component: <Reposition className="IconFilled" />,
      link: '/home/repositions',
      toggleTo: 'Reposition',
    },
    {
      subTitle: translate('Help.billingAndPayments'),
      component: <Billing className="IconFilled" />,
      link: '/home/billingandpayments',
      toggleTo: 'BillingPayments',
    },
    {
      subTitle: translate('Help.stores'),
      component: <Premises className="IconFilled" />,
      link: '/home/premises',
      toggleTo: 'Premises',
    },
    {
      subTitle: translate('Help.returns'),
      component: <Changes className="IconFilled" />,
      link: '/home/changes',
      toggleTo: 'Changes',
    },
    {
      subTitle: translate('Help.informations'),
      component: <Info className="IconFilled" />,
      link: '/home/information',
      toggleTo: 'Information',
    },
    {
      subTitle: translate('Help.billingManual'),
      component: <BillingManual className="IconFilled" />,
      link: '/home/billingmanual',
      toggleTo: 'BillingManual',
    },
  ];
  const reportsFulfillmentTab = [
    {
      subTitle: translate('ReportsFulfillment.sales'),
      component: <Sales className="IconFilled" />,
      link: '/home/sales',
      toggleTo: 'SalesReport',
    },
    {
      subTitle: translate('ReportsFulfillment.stock'),
      component: <Stock className="IconFilled" />,
      link: '/home/stock',
      toggleTo: 'Stocks',
    },
    {
      subTitle: translate('ReportsFulfillment.orderDetails'),
      component: <OrderDetails className="IconFilled" />,
      link: '/home/orderdetails',
      toggleTo: 'OrderReport',
    },
    {
      subTitle: translate('ReportsFulfillment.productsPerOrder'),
      component: <Products className="IconFilled" />,
      link: '/home/products',
      toggleTo: 'Products',
    },
    {
      subTitle: translate('ReportsFulfillment.lots'),
      component: <Lots className="IconFilled" />,
      link: '/home/lots',
      toggleTo: 'Lots',
    },
  ];
  const formsTab = [
    {
      subTitle: translate('Forms.productsShipment'),
      component: <ReceptionForm className="IconFilled" />,
      link: '/home/reception',
      toggleTo: 'ReceptionForm',
    },
    {
      subTitle: translate('Forms.shipmentHistory'),
      component: <HistoryOutlined className="IconFilled" />,
      link: '/home/formshistory',
      toggleTo: 'ReceptionFormHistory',
    },
  ];
  const productsTab = [
    {
      subTitle: translate('Products.seeAndEdit'),
      component: <EditOutlined className="IconFilled" />,
      link: '/home/allproducts',
      toggleTo: 'AllProducts',
    },
    {
      subTitle: translate('Products.packs'),
      component: <TagOutlined className="IconFilled" />,
      link: '/home/productspack',
      toggleTo: 'ProductsPack',
    },
  ];
  const deliveryRatesTab = [
    {
      subTitle: translate('Rates.seeAndEdit'),
      component: <EditOutlined className="IconFilled" />,
      link: '/home/alldeliveryrates',
      toggleTo: 'AllDeliveryRates',
    },
  ];
  const financesTab = [
    {
      subTitle: translate('Finances.payments'),
      component: <DollarOutlined className="IconFilled" />,
      link: '/home/payments',
      toggleTo: 'Payments',
    },
    {
      subTitle: translate('Finances.paymentOrders'),
      component: <DollarOutlined className="IconFilled" />,
      link: '/home/paymentorders',
      toggleTo: 'AllPaymentOrders',
    },
  ];
  const withdrawalTab = [
    {
      subTitle: translate('Withdrawal.create'),
      component: <EditOutlined className="IconFilled" />,
      link: '/home/withdrawals/create',
      toggleTo: 'WithdrawalsCreate',
    },
    {
      subTitle: translate('Withdrawal.allTitle'),
      component: <Withdrawal className="IconFilled" />,
      link: '/home/withdrawals',
      toggleTo: 'WithdrawalsAll',
    },
  ];

  const handleClick = () => setClick(!click);

  useEffect(() => {
    if (toggleAnotherTab === translate('ReportsFulfillment.title')) {
      setToggleFulfillmentReports(!toggleFulfillmentReports);
    } else if (toggleAnotherTab === translate('Help.title')) {
      setToggleHelpCenter(!toggleHelpCenter);
    } else if (toggleAnotherTab === translate('Forms.title')) {
      setToggleForms(!toggleForms);
    } else if (toggleAnotherTab === translate('Products.title')) {
      setToggleProducts(!toggleProducts);
    } else if (toggleAnotherTab === translate('Rates.title')) {
      setToggleDeliveryRates(!toggleDeliveryRates);
    } else if (toggleAnotherTab === translate('Orders.title')) {
      setToggleCreateOrder(!toggleCreateOrder);
    } else if (toggleAnotherTab === translate('Finances.title')) {
      setToggleFinances(!toggleFinances);
    } else if (toggleAnotherTab === translate('Withdrawal.title')) {
      setToggleWithdrawals(!toggleWithdrawals);
    }
  }, [toggleAnotherTab]);

  return (
    <div className="NavigationContainer">
      <div className="LogoContainer">
        <div
          className="MobileMenu"
          onClick={handleClick}
        >
          {click ? (
            <CloseMenu className="menu-icon" />
          ) : (
            <Menu className="menu-icon" />
          )}
        </div>
        <img
          src="https://clients-platform-assets.s3.amazonaws.com/img/NomadLogoWhite.png"
          className="NomadLogo"
          alt="Nomad"
        />
      </div>
      <div className={click ? 'NavigationButtons active' : 'NavigationButtons'}>
        {/* Home */}
        <div className="TabHeader">
          <Link
            className="IconLink"
            onClick={handleClick}
            to="/home/news"
            style={{ textDecoration: 'none' }}
          >
            <Home className="IconFilled" />
            <p className="IconText">
              {translate('home')}
            </p>
          </Link>
        </div>
        {/* Reports Fulfillment */}
        <Accordion
          closeNavBar={setClick}
          iconComponent={<Reports className="IconFilled" />}
          title={translate('ReportsFulfillment.title')}
          componentData={reportsFulfillmentTab}
          anotherAccordionTabOpen={anotherTabOpen}
          setAnotherAccordionTabOpen={setAnotherTabOpen}
          toggleTab={toggleFulfillmentReports}
          toggleAnotherTab={toggleAnotherTab}
          setToggleAnotherTab={setToggleAnotherTab}
        />
        {/* Forms */}
        <Accordion
          closeNavBar={setClick}
          iconComponent={<OrderDetails className="IconFilled" />}
          title={translate('Forms.title')}
          componentData={formsTab}
          anotherAccordionTabOpen={anotherTabOpen}
          setAnotherAccordionTabOpen={setAnotherTabOpen}
          toggleTab={toggleForms}
          toggleAnotherTab={toggleAnotherTab}
          setToggleAnotherTab={setToggleAnotherTab}
        />
        {/* Products */}
        <Accordion
          closeNavBar={setClick}
          iconComponent={<TagsOutlined className="IconFilled" />}
          title={translate('Products.title')}
          componentData={productsTab}
          anotherAccordionTabOpen={anotherTabOpen}
          setAnotherAccordionTabOpen={setAnotherTabOpen}
          toggleTab={toggleProducts}
          toggleAnotherTab={toggleAnotherTab}
          setToggleAnotherTab={setToggleAnotherTab}
        />
        {/* Rates */}
        {(client.hasNomadFulfillment) && (
        <Accordion
          closeNavBar={setClick}
          iconComponent={<DollarOutlined className="IconFilled" />}
          title={translate('Rates.title')}
          componentData={deliveryRatesTab}
          anotherAccordionTabOpen={anotherTabOpen}
          setAnotherAccordionTabOpen={setAnotherTabOpen}
          toggleTab={toggleDeliveryRates}
          toggleAnotherTab={toggleAnotherTab}
          setToggleAnotherTab={setToggleAnotherTab}
        />
        )}
        {/* Finances */}
        <Accordion
          closeNavBar={setClick}
          iconComponent={<DollarOutlined className="IconFilled" />}
          title={translate('Finances.title')}
          componentData={financesTab}
          anotherAccordionTabOpen={anotherTabOpen}
          setAnotherAccordionTabOpen={setAnotherTabOpen}
          toggleTab={toggleFinances}
          toggleAnotherTab={toggleAnotherTab}
          setToggleAnotherTab={setToggleAnotherTab}
        />
        {/* Withdrawal */}
        <Accordion
          closeNavBar={setClick}
          iconComponent={<Withdrawal className="IconFilled" />}
          title={translate('Withdrawal.title')}
          componentData={withdrawalTab}
          anotherAccordionTabOpen={anotherTabOpen}
          setAnotherAccordionTabOpen={setAnotherTabOpen}
          toggleTab={toggleWithdrawals}
          toggleAnotherTab={toggleAnotherTab}
          setToggleAnotherTab={setToggleAnotherTab}
        />
        {/* Help Center */}
        <Accordion
          closeNavBar={setClick}
          iconComponent={<HelpCenter className="IconFilled" />}
          title={translate('Help.title')}
          componentData={helpCenterTab}
          anotherAccordionTabOpen={anotherTabOpen}
          setAnotherAccordionTabOpen={setAnotherTabOpen}
          toggleTab={toggleHelpCenter}
          toggleAnotherTab={toggleAnotherTab}
          setToggleAnotherTab={setToggleAnotherTab}
        />
        <div className="TabHeader">
          <Link
            className="IconLink"
            onClick={handleClick}
            to="/home/orders/create"
            style={{ textDecoration: 'none' }}
          >
            <OrderIcon className="IconFilled" />
            <p className="IconText">
              {translate('Orders.title')}
            </p>
          </Link>
        </div>
        {/* Logout */}
        <div className="TabHeader">
          <div
            className="IconLink"
            onClick={() => logOut()}
          >
            <User className="IconFilled" />
            <p className="IconText">
              {translate('signOut')}
            </p>
          </div>
        </div>
      </div>
      <div className="Footer">{translate('rights')}</div>
    </div>
  );
}
